/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {useState} from 'react';
import {Agent} from '../Types/types';

interface Props {
  agent: Agent;
  handlePublishAgent: (agentId: string) => void;
  handleDeleteAgent: (agentId: string) => void;
  handleCloneAgent: (agentId: string) => void;
}

export const ActionsMenu = ({
  agent,
  handleDeleteAgent,
  handlePublishAgent,
  handleCloneAgent,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <MenuItem onClick={() => handleDeleteAgent(agent.agentId)}>
          Delete Agent
        </MenuItem>
        <MenuItem
          onClick={() => handlePublishAgent(agent.agentId)}
          disabled={agent.published}
        >
          Publish Agent
        </MenuItem>
        <MenuItem onClick={() => handleCloneAgent(agent.agentId)}>
          Clone Agent
        </MenuItem>
      </Menu>
    </>
  );
};
