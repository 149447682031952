/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  TrendAnalyticsData,
  TrendInterval,
} from '@agent-assist/api-typescript-react-query-hooks';
import {Box, styled} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {LineChart} from '@mui/x-charts';
import {format} from 'date-fns';

interface Props {
  values: TrendAnalyticsData[];
  interval: TrendInterval;
  isExpandedModalOpen: boolean;
}

const formatDateLabel = (date: Date, interval: string): string => {
  switch (interval.toLowerCase()) {
    case '15min':
    case 'hour':
    case 'day':
      return format(date, 'haaa d/M');
    case 'month':
    case 'year':
      return format(date, 'MMM yy');
    default:
      return 'invalid';
  }
};

const StyledBox = styled(Box)(({theme}) => ({
  minWidth: '100%',
  height: '100%',
  display: 'flex',
  overflowX: 'auto',
  padding: theme.spacing(2),
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const LineChartVisualisation = ({
  values,
  interval,
  isExpandedModalOpen,
}: Props) => {
  const theme = useTheme();
  const formattedData = values
    .filter((item): item is TrendAnalyticsData & {key: string} => !!item.key)
    .map((item) => ({
      x: new Date(item.key),
      y: item.value,
    }));
  const margins = isExpandedModalOpen
    ? {left: 50, bottom: 70, right: 70, top: 20}
    : {left: 30, bottom: 50, right: 50, top: 5};
  const height = isExpandedModalOpen ? 400 : 150;
  return (
    <StyledBox>
      <LineChart
        dataset={formattedData}
        xAxis={[
          {
            dataKey: 'x',
            scaleType: 'time',
            tickLabelStyle: {
              angle: 45,
              textAnchor: 'start',
              fontSize: 10,
            },
            tickSize: 2,
            valueFormatter: (value: Date) => formatDateLabel(value, interval),
          },
        ]}
        yAxis={[
          {
            dataKey: 'y',
            tickLabelStyle: {
              fontSize: 10,
            },
            tickSize: 2,
          },
        ]}
        series={[
          {
            dataKey: 'y',
            color: theme.palette.primary.main,
            curve: 'linear',
          },
        ]}
        height={height}
        margin={margins}
        sx={{
          '.MuiLineElement-root': {
            strokeWidth: isExpandedModalOpen ? 2 : 1,
          },
          '.MuiMarkElement-root': {
            stroke: theme.palette.primary.main,
            scale: isExpandedModalOpen ? '1.2' : '1',
          },
        }}
      />
    </StyledBox>
  );
};
