/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import ManageToolParameters from './ToolParameters';
import {useTrusstedAgentState} from '../../../providers/TrusstedAgentProvider';
import TrusstGPTTextField from '../../MaterialUI/TextField';
import {AgentToolInput} from '../Types/types';

interface Props {
  toolIndex: number;
  onUpdateTool: (updatedTool: AgentToolInput) => void;
}

export const AgentToolSettingsForm = ({onUpdateTool, toolIndex}: Props) => {
  const {modifiedAgent} = useTrusstedAgentState();
  const currentTool = modifiedAgent?.tools?.[toolIndex];
  console.log('toolIndex', toolIndex);

  return (
    <>
      <Box sx={{marginBottom: '2rem'}}>
        <Typography variant="body1">Tool Settings</Typography>
        <Typography variant="caption">
          Provide the details for the agent tool to make this API Call
        </Typography>
      </Box>
      <TrusstGPTTextField
        label={'Name'}
        value={currentTool?.name || ''}
        variant={'outlined'}
        onChange={(event) => {
          onUpdateTool({
            toolId: currentTool?.toolId || '',
            description: currentTool?.description || '',
            name: event.target.value,
            parameters: currentTool?.parameters || [],
          });
        }}
        placeholder="Tool Name"
      />
      <TrusstGPTTextField
        label={'Description'}
        value={currentTool?.description || ''}
        onChange={(event) => {
          onUpdateTool({
            toolId: currentTool?.toolId || '',
            name: currentTool?.name || '',
            description: event.target.value,
            parameters: currentTool?.parameters || [],
          });
        }}
        rows={5}
        multiLine={true}
        placeholder="Description"
      />
      {/* Manage Parameters */}
      <ManageToolParameters tool={currentTool} onUpdateTool={onUpdateTool} />
    </>
  );
};
