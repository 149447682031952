type ColourThreeChannel = [a: number, b: number, c: number];

// ripped from: https://www.30secondsofcode.org/js/s/rgb-hex-hsl-hsb-color-format-conversion/
export const rgbToHsl = (
  r: number,
  g: number,
  b: number,
): ColourThreeChannel => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
        ? 2 + (b - r) / s
        : 4 + (r - g) / s
    : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
};

export const hexToRgb = (hex: string): ColourThreeChannel => {
  // this does not do alpha currently
  let alpha = false,
    h = hex.slice(hex.startsWith('#') ? 1 : 0);
  if (h.length === 3) {
    // handle colours like: #49a
    h = h
      .split('')
      .map((x) => x + x)
      .join('');
  } else if (h.length === 8) alpha = true;
  const n = parseInt(h, 16);

  return [
    n >>> (alpha ? 24 : 16), //eslint-disable-line no-bitwise
    (n & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8), //eslint-disable-line no-bitwise
    (n & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0), //eslint-disable-line no-bitwise
  ];
};

export const hexToHsl = (hex: string): ColourThreeChannel => {
  const [r, g, b] = hexToRgb(hex);
  return rgbToHsl(r, g, b);
};

// export const BRAND_COLOURS = [
//   {hex: '151515', rgb: '21, 21, 21', hsl: '0 0% 8%'},
//   {hex: '141834', rgb: '20, 24, 52', hsl: '233 44% 14%'},
//   {hex: '20285A', rgb: '32, 40, 90', hsl: '232 48% 24%'},
//   {hex: '2B3578', rgb: '43, 53, 120', hsl: '232 47% 32%'},
//   {hex: '344191', rgb: '52, 65, 145', hsl: '232 30% 39%'},
//   {hex: '494895', rgb: '73, 72, 149', hsl: '241 27% 43%'},
//   {hex: '5E5098', rgb: '94, 80, 152', hsl: '252 26% 45%'},
//   {hex: '885FA0', rgb: '136, 95, 160', hsl: '278 25% 50%'},
//   {hex: 'C775AB', rgb: '199, 117, 171', hsl: '320 42% 62%'},
//   {hex: 'FFE780', rgb: '255, 231, 128', hsl: '49 100% 75%'},
//   {hex: 'FFF', rgb: '255, 255, 255', hsl: '0 0% 100%'},
// ];

export const CHART_COLOURS = [
  // original colours:
  '#E58606',
  '#5D69B1',
  '#52BCA3',
  '#99C945',
  '#CC61B0',
  '#24796C',
  '#DAA51B',
  '#2F8AC4',
  '#764E9F',
  '#ED645A',
  '#CC3A8E',
  '#A5AA99',
  // permutations from chat gpt:
  '#F18A00',
  '#8C5E2C',
  '#3D9970',
  '#FFD700',
  '#C70039',
  '#736AFF',
  '#00FF00',
  '#FF69B4',
  '#FF7F50',
  '#6495ED',
  '#7FDBFF',
  '#FF851B',
  '#B10DC9',
  '#FFDC00',
  '#FF4136',
  '#01FF70',
  '#39CCCC',
  '#CFA700',
  '#FF6D00',
  '#58508D',
  '#FFD12A',
  '#FF6978',
  '#BAE3FF',
  '#FF5E00',
];
