/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  Report,
  SynopsisEvaluationRating,
} from '@agent-assist/api-typescript-react-query-hooks';
import Box from '@mui/material/Box';
import {ExternalLinkIcon} from 'lucide-react';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {usePromptState} from '../../providers/PromptProvider';
import {ContactRatingIndicator} from '../contact/contact-rating-indicator';

interface keyval {
  [key: string]: string;
}

interface Row {
  id: string;
  contactId: string;
  contactImportId: string;
  rating?: SynopsisEvaluationRating;
  username?: string;
  comment?: string;
}

const headers: keyval[] = [
  {key: 'contactId', value: 'Contact ID'},
  {
    key: 'rating',
    value: 'Rating',
  },
  {
    key: 'username',
    value: 'Username',
  },
  {
    key: 'comment',
    value: 'Comment',
  },
];
const columns: MUIDataTableColumn[] = headers.map((kv) => {
  return {
    name: kv.key,
    label: kv.value,
  };
});

const PromptEvaluationReportTable = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const {promptReports} = usePromptState();
  const [rows, setRows] = useState<
    {
      [key: string]: any;
    }[]
  >();
  const [realColumns, setRealColumns] = useState<MUIDataTableColumn[]>([]);

  const createRowsData = (reportRows: Report[]): Row[] => {
    const formattedRows: Row[] = [];

    reportRows.forEach((row) => {
      formattedRows.push({
        id: row.contactId,
        contactId: row.contactId,
        contactImportId: row.contactImportId,
        rating: row.rating,
        username: row.ratingUsername?.toString(),
        comment: row.comment,
      });
    });
    return formattedRows;
  };

  useEffect(() => {
    if (promptReports && promptReports.length > 0) {
      const newData = createRowsData(promptReports);
      setRows(newData);
      let rowId = '';
      let contactImportId = '';
      const newColumns = [...columns];
      // add a custom renderer to the first column to make it a clickable link:
      newColumns[0].options = {
        filter: true,
        customBodyRenderLite: (dataIndex: number) => {
          rowId = newData[dataIndex].contactId;
          contactImportId = newData[dataIndex].contactImportId;
          const contactLink = `/contacts/${contactImportId}/contact/${rowId}`;
          return (
            <Link
              className="flex gap-1 items-center"
              to={contactLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {rowId}
              <ExternalLinkIcon size={32} />
            </Link>
          );
        },
      };
      newColumns[1].options = {
        filter: true,
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <div className="flex ml-3">
              <ContactRatingIndicator rating={newData[dataIndex].rating} />
            </div>
          );
        },
      };
      newColumns[2].options = {
        filter: true,
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <div style={{textAlign: 'left'}}>{newData[dataIndex].username}</div>
          );
        },
      };
      setRealColumns(newColumns);
    } else {
      setRows([]);
    }
  }, [promptReports]);

  const options: MUIDataTableOptions = {
    filter: true,
    viewColumns: false,
    onFilterChange: (changedColumn: any, filterList: any) => {
      console.log(changedColumn, filterList);
    },
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'vertical',
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    page,
  };

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      {!promptReports ? (
        <p className="scroll-m-20 text-xs font-semibold tracking-tight m-10 w-full">
          No Prompt Report Is Available For This Prompt Revision.
        </p>
      ) : (
        <>
          <MUIDataTable
            title={''}
            data={rows ?? []}
            columns={realColumns}
            options={options}
          />
        </>
      )}
    </Box>
  );
};

export default PromptEvaluationReportTable;
