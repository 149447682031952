/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {TrusstGPTButton} from './Button';

const TrusstGPTDialog = ({
  open,
  handleClose,
  onConfirm,
  title,
  caption,
}: {
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  title: string;
  caption: string;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{padding: '1rem 1rem'}}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {caption}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TrusstGPTButton
          variant="contained"
          onClick={handleClose}
          buttonText="Cancel"
        />
        <TrusstGPTButton
          variant="contained"
          onClick={onConfirm}
          buttonText="Confirm"
        />
      </DialogActions>
    </Dialog>
  );
};

export default TrusstGPTDialog;
