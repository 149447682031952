/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import Button from '@mui/material/Button';
import {Trash2} from 'lucide-react';
import {useCallback, useState} from 'react';
import {ContactSummaryPropsBase} from './ContactCard';
import {DeleteAgentContactModal} from './DeleteContactModal';
import {AgentContact} from './ListAgentContacts';
import {cn} from '../../../lib/utils';

interface ContactSummaryProps extends ContactSummaryPropsBase {
  contact: Partial<AgentContact>;
  onDeleteContact(contactId: string): Promise<void>;
}

export const ContactSummary = ({
  contact,
  isExpanded,
  onSelectContact,
  onDeleteContact,
  refetch,
}: ContactSummaryProps) => {
  const {contactId} = contact;
  const btnClass = 'rounded-sm hover:bg-accent text-left px-2 py-0';
  const [showModal, setShowModal] = useState(false);

  const onDelete = useCallback(async () => {
    setShowModal(false);
    await onDeleteContact(contactId!);
  }, [showModal]);

  return (
    <>
      {showModal && (
        <DeleteAgentContactModal
          showModal={showModal}
          setShowModal={setShowModal}
          title={contact.title!}
          onDeleteContact={onDelete}
          refetch={refetch}
        />
      )}
      <button
        className={cn(btnClass, [!isExpanded && 'truncate'])}
        onClick={() => onSelectContact(contactId!)}
      >
        {contact.title}
      </button>
      {isExpanded && (
        <Button
          size="small"
          variant="outlined"
          onClick={() => setShowModal(true)}
        >
          <Trash2 size={16} />
        </Button>
      )}
    </>
  );
};
