/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ContactSynopsisElement} from '@agent-assist/api-typescript-react-query-hooks';
import {Plus, X} from 'lucide-react';
import React, {FC, useCallback, useMemo} from 'react';
import {Input} from '../ui/input';
import {Label} from '../ui/label';
import {LegacyButton} from '../ui/legacy-button';
import {Textarea} from '../ui/textarea';

export interface SynopsisEvaluationFormData {
  readonly synopsis: ContactSynopsisElement[];
}

interface SynopsisEvaluationFormProps {
  readonly data: SynopsisEvaluationFormData;
  readonly onChange: React.Dispatch<
    React.SetStateAction<SynopsisEvaluationFormData>
  >;
  readonly disabled?: boolean;
}

const MAX_SECTIONS = 20;

export const SynopsisEvaluationForm: FC<SynopsisEvaluationFormProps> = ({
  data,
  onChange,
  disabled,
}) => {
  const updateElement = useCallback(
    (i: number, property: keyof ContactSynopsisElement, value: string) => {
      onChange((prev) => ({
        ...prev,
        synopsis: [
          ...prev.synopsis.slice(0, i),
          {
            ...prev.synopsis[i]!,
            [property]: value,
          },
          ...prev.synopsis.slice(i + 1),
        ],
      }));
    },
    [onChange],
  );

  const canAddElement = useMemo(() => {
    if (data.synopsis.length >= MAX_SECTIONS) {
      return false;
    }
    if (
      data.synopsis.length > 0 &&
      (!data.synopsis[data.synopsis.length - 1]?.key ||
        (data.synopsis[data.synopsis.length - 1]?.response).toString() === '')
    ) {
      return false;
    }
    return true;
  }, [data]);

  return (
    <div className={'flex flex-col gap-y-4 pr-1'}>
      <div className={'grid grid-cols-12 gap-x-2 mr-1'}>
        <div className={'flex flex-col gap-y-2 col-span-3'}>
          <Label>Title</Label>
          <p className={'text-muted-foreground text-xs'}>
            Title of the prompt section
          </p>
        </div>
        <div className={'flex flex-col gap-y-2 col-span-8'}>
          <Label>Insights</Label>
          <p className={'text-muted-foreground text-xs'}>
            Response to the prompt
          </p>
        </div>
        <div className={'flex flex-col gap-y-2 col-span-1'} />
      </div>
      {data.synopsis.map((q, i) => (
        <div className={'grid grid-cols-12 gap-x-2 mr-1'} key={i}>
          <div className={'flex flex-col gap-y-2 col-span-3'}>
            <Input
              disabled={disabled}
              onChange={(e) => updateElement(i, 'key', e.target.value)}
              value={q.key}
            />
          </div>
          <div className={'flex flex-col gap-y-2 col-span-8'}>
            <Textarea
              disabled={disabled}
              onChange={(e) => updateElement(i, 'response', e.target.value)}
              value={q.response}
            />
          </div>
          <div className={'flex flex-col gap-y-2 col-span-1'}>
            <LegacyButton
              variant={'ghost'}
              size={'icon'}
              onClick={() =>
                onChange((prev) => ({
                  ...prev,
                  synopsis: [
                    ...prev.synopsis.slice(0, i),
                    ...prev.synopsis.slice(i + 1),
                  ],
                }))
              }
            >
              <X />
            </LegacyButton>
          </div>
        </div>
      ))}
      <LegacyButton
        disabled={disabled || !canAddElement}
        variant={'outline'}
        className={'w-36 gap-x-1 self-center mt-5'}
        size={'sm'}
        onClick={() =>
          onChange((prev) => ({
            ...prev,
            synopsis: [
              ...prev.synopsis,
              {
                key: '',
                response: '',
              },
            ],
          }))
        }
      >
        <Plus size={18} />
        <p>Add Section</p>
      </LegacyButton>
    </div>
  );
};
