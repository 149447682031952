/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import CloseIcon from '@mui/icons-material/Close';
import {DialogContent} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import {TransitionProps} from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {LoadingSpinner} from '../../components/LoadingSpinner';
import PromptEvaluationReportTable from '../../components/PromptEvaluationReport/promptEvaluationReport';
import {
  setPromptReports,
  setShowModalPromptReport,
  usePromptDispatch,
  usePromptState,
} from '../../providers/PromptProvider';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PromptEvaluationModal() {
  const dispatch = usePromptDispatch();
  const {showPromptReportModal, promptReports} = usePromptState();

  const handleClose = () => {
    setPromptReports(dispatch, undefined);
    setShowModalPromptReport(dispatch, false);
  };

  return (
    <Dialog
      fullScreen
      open={showPromptReportModal}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: 'hsl(var(--card))',
          opacity: '0.88',
        }}
      >
        <Toolbar>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            Prompt Evaluation Report
          </Typography>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {promptReports === undefined ? (
          <LoadingSpinner size={'lg'} />
        ) : promptReports.length === 0 ? (
          <p className="scroll-m-20 text-xs font-semibold tracking-tight m-10 w-full">
            There Is No Prompt Evaluation Report On This Prompt Question.
          </p>
        ) : (
          <div className={'flex flex-col gap-y-4'} style={{height: '100%'}}>
            <PromptEvaluationReportTable />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
