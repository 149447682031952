/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

// this is just to test the mui theme is working. it will be deleted.

import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {useState, MouseEvent} from 'react';
import {PageContainer} from '../../components/ui/page';

export const DevMUITheme = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleMenuClick = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) =>
    setSelectedTab(newValue);

  return (
    <PageContainer title="MUI Theme">
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">Test Theme</Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>

      {/* Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      </Menu>

      {/* Tabs */}
      <Paper>
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab label="Tab 1" />
          <Tab label="Tab 2" />
          <Tab label="Tab 3" />
        </Tabs>
      </Paper>

      <Grid container spacing={2} style={{margin: '20px 0'}}>
        {/* Buttons */}
        <Grid item xs={12}>
          <Button variant="contained" color="primary">
            Primary Contained Button
          </Button>
          <Button variant="outlined" color="primary">
            Primary Outlined Button
          </Button>
          <Button variant="text" color="primary">
            Primary Text Button
          </Button>

          <Button variant="contained" color="secondary">
            Secondary Contained Button
          </Button>
          <Button variant="outlined" color="secondary">
            Secondary Outlined Button
          </Button>
          <Button variant="text" color="secondary">
            Secondary Text Button
          </Button>

          <Button variant="text" disabled>
            Disabled Button
          </Button>
        </Grid>

        {/* TextField */}
        <Grid item xs={12}>
          <TextField label="Standard" variant="standard" fullWidth />
          <TextField
            label="Outlined"
            variant="outlined"
            fullWidth
            style={{marginTop: '10px'}}
          />
        </Grid>

        {/* Form Elements */}
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Checkbox"
          />
          <RadioGroup defaultValue="option1" row>
            <FormControlLabel
              value="option1"
              control={<Radio />}
              label="Option 1"
            />
            <FormControlLabel
              value="option2"
              control={<Radio />}
              label="Option 2"
            />
          </RadioGroup>
          <FormControlLabel
            control={<Switch defaultChecked />}
            label="Switch"
          />
        </Grid>

        {/* Slider */}
        <Grid item xs={12}>
          <Slider defaultValue={30} aria-labelledby="continuous-slider" />
        </Grid>

        {/* Select */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue=""
            >
              <MenuItem value={10}>Option 1</MenuItem>
              <MenuItem value={20}>Option 2</MenuItem>
              <MenuItem value={30}>Option 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Card Title
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This is a sample card to test the theme.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Action 1</Button>
              <Button size="small">Action 2</Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Progress */}
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>

      {/* Dialog */}
      <Button variant="outlined" color="primary" onClick={handleDialogOpen}>
        Open Dialog
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <DialogTitle>Test Dialog</DialogTitle>
          <DialogContentText>
            This is a dialog to test the theme.
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDialogClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </PageContainer>
  );
};
