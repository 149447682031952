/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import AddIcon from '@mui/icons-material/Add';
import {Box, MenuItem, MenuList, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useTrusstedAgentState} from '../../../providers/TrusstedAgentProvider';
import {TrusstGPTButton} from '../../MaterialUI/Button';
import {AgentToolInput} from '../Types/types';

interface Props {
  tools: AgentToolInput[];
  setToolIndex: (index: number) => void;
  toolIndex: number;
}

export const ToolsList = ({tools, setToolIndex, toolIndex}: Props) => {
  const {modifiedAgent} = useTrusstedAgentState();
  const hasTools = tools.length > 0;
  const theme = useTheme();
  const currentToolId = modifiedAgent?.tools?.[toolIndex]?.toolId;
  console.log('currentToolId', currentToolId);
  // on load, if currentToolId is undefined, set the first tool as the current tool

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <MenuList>
        {hasTools &&
          tools.map((tool, index) => {
            return (
              <MenuItem
                key={`${tool.name}-${index}`}
                onClick={() => {
                  setToolIndex(index);
                }}
                sx={{
                  margin: '.5rem',
                  backgroundColor:
                    index === toolIndex
                      ? theme.palette.primary.dark
                      : 'transparent',
                  '&:hover': {
                    backgroundColor:
                      index === toolIndex
                        ? theme.palette.primary.dark
                        : theme.palette.primary.dark,
                  },
                }}
              >
                <Typography variant="inherit" noWrap>
                  {tool.name}
                </Typography>
              </MenuItem>
            );
          })}
      </MenuList>
      <TrusstGPTButton
        disabled={!hasTools}
        variant="contained"
        buttonText="New Tool"
        onClick={() => {
          const index = tools.length;
          setToolIndex(index);
        }}
        endIcon={<AddIcon />}
      />
    </Box>
  );
};
