/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ContactSynopsisElement} from '@agent-assist/api-typescript-react-query-hooks';
import {FC} from 'react';

import LegacyHeading from '../Heading';

interface SynopsisElementsProps {
  readonly synopsis: ContactSynopsisElement[];
}

export const SynopsisElements: FC<SynopsisElementsProps> = ({synopsis}) => {
  return (
    <div className={'flex flex-col gap-y-4 pr-1'}>
      {synopsis!.map((element) => (
        <div key={element.key} className={'flex flex-col gap-y-2'}>
          <LegacyHeading>{element.key}</LegacyHeading>
          <p className={'text-muted-foreground'}>
            {element.response.toString()}
          </p>
        </div>
      ))}
    </div>
  );
};
