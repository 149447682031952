/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {useListAnalyticsWithFilters} from '@agent-assist/api-typescript-react-query-hooks/lib/apis/AgentAssistApiHooks';
import {useEffect, useState} from 'react';

import {AnalyticsGraphCard} from './AnalyticsGraphCard';
import {AnalyticHookProps} from './types';
import {
  AnalyticValue,
  parseListAnalytics,
} from '../../hooks/analytics/useListAnalytics2AndParse';
import {localTimeToDatabaseTime} from '../../lib/formatDate';
import {cleanseFilters} from '../../pages/contact-import/cleanseFilters';
import {HierBar} from '../HierBar/HierBar';

export const HierBarWithHook = ({
  contactImportId,
  //promptRevisionId, TODO
  filters,
}: AnalyticHookProps) => {
  const [data, setData] = useState<AnalyticValue[]>([]);
  const {isLoading, isSuccess, mutateAsync} = useListAnalyticsWithFilters();
  useEffect(() => {
    const {filters: cleansedFilters, from, to} = cleanseFilters(filters);
    const getData = async () => {
      const result = await mutateAsync({
        contactImportId,
        listAnalyticsWithFiltersRequestContent: {
          from: localTimeToDatabaseTime(from),
          to: localTimeToDatabaseTime(to),
          filters: cleansedFilters,
        },
      });
      const values = parseListAnalytics(result.analytics);
      setData(values);
    };
    void getData();
  }, [contactImportId, filters]);

  // console.log("data", data, isSuccess, isLoading);
  const noDataFound = isSuccess && data.length === 0;

  return (
    <AnalyticsGraphCard
      title="Categories"
      noDataFound={noDataFound}
      isLoading={isLoading}
    >
      <div className="w-full text-left">
        <HierBar dataId={contactImportId} data={data} />
      </div>
    </AnalyticsGraphCard>
  );
};
