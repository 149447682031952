/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {useCognitoAuthContext} from '@aws-northstar/ui';
import camelCase from 'lodash/camelCase';
import {type FC, useEffect} from 'react';
import {DashboardLayout} from './layout/dashboard-layout';
import {useUpdateContactImports} from '../hooks/useUpdateContactImports';
import {setUser, User, useTrusstGPTDispatch} from '../providers/AppProvider';
import {PromptProvider} from '../providers/PromptProvider';
import {TrusstedAgentProvider} from '../providers/TrusstedAgentProvider';
import Routes from '../Routes';

export const App: FC = () => {
  useUpdateContactImports();
  const {getAuthenticatedUser} = useCognitoAuthContext();
  const dispatch = useTrusstGPTDispatch();

  useEffect(() => {
    if (!getAuthenticatedUser) return;
    const authUser = getAuthenticatedUser();
    if (!authUser) throw new Error('no authUser');
    authUser.getSession(() =>
      authUser.getUserAttributes((_, attributes) => {
        if (!attributes) throw new Error('no attributes');
        const user = attributes?.reduce((acc, {Name, Value}) => {
          return {...acc, [camelCase(Name)]: Value};
        }, {} as User);

        user.username = authUser.getUsername();

        user.signOut = async () => {
          authUser.signOut();
          window.location.href = '/';
        };

        setUser(dispatch, user);
      }),
    );
  }, [getAuthenticatedUser]);

  return (
    <PromptProvider>
      <TrusstedAgentProvider>
        <DashboardLayout>
          <Routes />
        </DashboardLayout>
      </TrusstedAgentProvider>
    </PromptProvider>
  );
};
