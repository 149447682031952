/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {useGetConnectAccessUrl} from '@agent-assist/api-typescript-react-query-hooks';
import {PhoneCall} from 'lucide-react';
import {FC, useContext, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {RuntimeConfigContext} from '../../providers/RuntimeContextProvider';
import {LegacyButton} from '../ui/legacy-button';

export interface ContactConnectButtonProps {
  readonly contactId: string;
  readonly instanceId?: string;
}

export const ContactConnectButton: FC<ContactConnectButtonProps> = ({
  contactId,
  instanceId,
}) => {
  const {connectInstanceIdToBaseUrlMapping} = useContext(RuntimeConfigContext)!;
  const result = useGetConnectAccessUrl();
  const connectInstanceUrl = result.data?.url;

  const baseUrl = useMemo(() => {
    let url = '';
    if (connectInstanceUrl) {
      url = connectInstanceUrl;
    }
    if (connectInstanceIdToBaseUrlMapping) {
      url = connectInstanceIdToBaseUrlMapping[instanceId ?? '_'];
    }
    return url.endsWith('/') ? url.slice(0, -1) : url;
  }, [connectInstanceUrl, connectInstanceIdToBaseUrlMapping, instanceId]);
  const disabled = baseUrl === '' ? true : false;

  if (disabled) {
    return null;
  }

  return (
    <LegacyButton variant={'outline'} size={'sm'} asChild>
      <Link
        to={`${baseUrl}/contact-trace-records/details/${contactId}`}
        className={'flex gap-x-2'}
      >
        <PhoneCall size={16} />
        Listen in Amazon Connect
      </Link>
    </LegacyButton>
  );
};
