/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Card, CardContent} from '@mui/material';

// standard component for any list or page that typically has content, but is missing... eg. use for a table with empty rows
export const EmptyResult = ({text}: {text: string}) => {
  return (
    <Card>
      <CardContent>{text}</CardContent>
    </Card>
  );
};
