/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {ExpandIcon} from 'lucide-react';
import {useState, FC, useRef} from 'react';
import {usePromptState} from '../../providers/PromptProvider';

interface IProps {
  contactId: string;
  contactImportId: string;
}

const AudioPlayerAccordion: FC<IProps> = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const {audioURL} = usePromptState();
  const [isExpanded, setExpand] = useState(false);

  const handleAccordionChange = (expanded: boolean) => {
    if (!expanded && audioRef.current) {
      audioRef.current.pause();
    }
  };

  return (
    <Accordion
      defaultExpanded={isExpanded}
      onChange={() => {
        setExpand(!isExpanded);
        handleAccordionChange(!isExpanded);
      }}
      sx={{
        color: 'hsl(var(--foreground))',
      }}
    >
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
        expandIcon={<ExpandIcon />}
      >
        <Typography>Play Audio</Typography>
      </AccordionSummary>
      <AccordionDetails style={{display: 'flex', justifyContent: 'center'}}>
        <audio
          ref={audioRef}
          autoPlay={false}
          controls={true}
          preload="metadata"
          src={audioURL}
          title="{title}"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AudioPlayerAccordion;
