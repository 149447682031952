import {clsx, type ClassValue} from 'clsx';
import {twMerge} from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getInitials(firstName: string, lastName: string): string {
  const familyInitial = firstName.charAt(0);
  const givenInitial = lastName.charAt(0);
  return `${familyInitial}${givenInitial}`;
}
