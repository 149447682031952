/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  type Contact,
  type ContactDetailsFilter,
  type FilterContactResult,
  type ListContactMetadataColumnsResponseContent,
  type ListContactsByFiltersRequest,
  type ListContactsByFiltersResponseContent,
  type ResponseError,
  useListContactMetadataColumns,
  useListContactsByFilters,
} from '@agent-assist/api-typescript-react-query-hooks';

import type {UseMutateAsyncFunction} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {cleanseFilters} from './cleanseFilters';
import {columns} from './columns';
import {OnFilterParams} from './FilterOptions';
import {parseSearchParams, updateFilters} from './filters';
import {
  IMPORTED_CONTACTS_PAGE_SIZE,
  type ViewBodyProps,
} from './ViewContactImport';
import {ViewList} from './ViewList';
import Pagination from '../../components/Pagination/Pagination';
import {ScreenLoader} from '../../components/ScreenLoader';
import {localTimeToDatabaseTime} from '../../lib/formatDate';

interface ViewListCompleteMethods extends ViewBodyProps {
  isLoading: boolean;
  mutateAsync: UseMutateAsyncFunction<
    ListContactsByFiltersResponseContent,
    ResponseError,
    ListContactsByFiltersRequest,
    unknown
  >;
  columns: ListContactMetadataColumnsResponseContent;
}

export const ViewListComplete = (p: ViewBodyProps) => {
  // console.log("ViewListComplete");
  const columnsApi = useListContactMetadataColumns();
  const filterApi = useListContactsByFilters();

  if (!columnsApi.data) {
    return <ScreenLoader />;
  }

  return (
    <ViewListCompleteMethods {...p} {...filterApi} columns={columnsApi.data} />
  );
};

export const mapResults = (fcr: FilterContactResult[]) => {
  const contact = fcr.reduce((acc, item) => {
    const key = item.key as string;
    let value = item.value;
    try {
      value = item.isSuper && value ? JSON.parse(value) : value;
    } catch (err) {
      console.warn(err);
      console.warn(`value is: '${value}'`);
    }
    return {...acc, [key]: value};
  }, {});
  return contact as Contact;
};

const ViewListCompleteMethods = (props: ViewListCompleteMethods) => {
  const {contactImportId, isLoading, mutateAsync} = props;

  const [currentPage, setCurrentPage] = useState(0);
  const [items, setItems] = useState<Contact[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useSearchParams();
  const [filters, setFilters] = useState<ContactDetailsFilter[]>(
    parseSearchParams(search).array,
  );

  useEffect(() => {
    const filterResults = async () => {
      const {filters: cleansedFilters, from, to} = cleanseFilters(filters);
      const params: ListContactsByFiltersRequest = {
        nextToken: String(currentPage),
        pageSize: IMPORTED_CONTACTS_PAGE_SIZE,
        listContactsByFiltersRequestContent: {
          contactImportId,
          from: localTimeToDatabaseTime(from),
          to: localTimeToDatabaseTime(to),
          filters: cleansedFilters,
        },
      };
      const res = await mutateAsync(params);
      setItems(res.results?.map(mapResults) ?? []);
      setTotalPages(res.totalPages ?? 0);
    };

    filterResults().catch(console.error);
  }, [contactImportId, currentPage, filters]);

  const pagination = (
    <Pagination
      currentPage={currentPage + 1}
      loading={isLoading}
      hasNext={currentPage < totalPages - 1}
      hasPrevious={currentPage > 0}
      onNextPage={async () => setCurrentPage(currentPage + 1)}
      onPreviousPage={async () => setCurrentPage(currentPage - 1)}
      totalPages={totalPages}
    />
  );
  const listProps = {
    columns,
    items,
    pagination,
    tableIndex: 1 + currentPage * IMPORTED_CONTACTS_PAGE_SIZE,
    onFilter: (paramOrParams: OnFilterParams) => {
      const newFilters = updateFilters([search, setSearch], paramOrParams);
      setFilters(newFilters);
    },
  };

  return <ViewList {...props} {...listProps} activeFilters={filters} />;
};
