/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  useGetOrganisationDetails,
  useSetOrganisationDetails,
  GetOrganisationDetailsResponseContent,
} from '@agent-assist/api-typescript-react-query-hooks';

import {Stack} from '@mui/material';
import {useState} from 'react';

import {LoaderWithMessage} from './Settings';
import LegacyHeading from '../../components/Heading';
import {Input, InputItem} from '../../components/ui/input';
import {Label} from '../../components/ui/label';
import {LegacyButton} from '../../components/ui/legacy-button';
import {Textarea} from '../../components/ui/textarea';

export const OrganisationDetails = () => {
  const {data, isInitialLoading, isLoading, isError, refetch} =
    useGetOrganisationDetails();
  const [isEditing, setEditing] = useState(false);

  if (isInitialLoading || isLoading) return <LoaderWithMessage />;
  if (isError) return <div>Getting current method errored...</div>;
  if (isEditing) {
    return (
      <SetDetails
        organisationDetails={data}
        onCancel={() => setEditing(false)}
        onComplete={async () => {
          await refetch();
          setEditing(false);
        }}
      />
    );
  }
  return (
    <Stack direction="row" sx={{justifyContent: 'space-between'}}>
      <div>
        <LegacyHeading h3>
          {data.organisationName || 'No name specified'}
        </LegacyHeading>
        <p>{data.organisationDescription || 'No description'}</p>
      </div>
      <LegacyButton variant="outline" onClick={() => setEditing(true)}>
        Edit
      </LegacyButton>
    </Stack>
  );
};

const SetDetails = ({
  organisationDetails,
  onCancel,
  onComplete,
}: {
  organisationDetails: GetOrganisationDetailsResponseContent;
  onCancel: () => void;
  onComplete: () => void;
}) => {
  const {mutateAsync, isError, isLoading, isSuccess, data} =
    useSetOrganisationDetails();

  const {organisationName, organisationDescription} = organisationDetails;
  const [name, setName] = useState(organisationName ?? '');
  const [desc, setDesc] = useState(organisationDescription ?? '');

  if (isError) {
    return <div>Error saving details...</div>;
  }

  if (isSuccess) {
    return (
      <>
        <Stack direction="row" sx={{justifyContent: 'space-between'}}>
          {data.message}
          <LegacyButton variant="outline" onClick={() => onComplete()}>
            Done
          </LegacyButton>
        </Stack>
      </>
    );
  }

  if (isLoading) {
    return <LoaderWithMessage message="Saving Organisation details" />;
  }

  const onSave = async () => {
    const setOrganisationDetailsRequestContent = {
      organisationName: name,
      organisationDescription: desc,
    };
    await mutateAsync({setOrganisationDetailsRequestContent});
  };

  return (
    <div className="flex flex-col w-full gap-y-4 mt-4">
      <InputItem>
        <Label>Name</Label>
        <Input
          maxLength={100}
          placeholder="Enter your organisation's name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </InputItem>
      <InputItem>
        <Label>Description</Label>
        <Textarea
          maxLength={1000}
          placeholder="Provide a brief summary of your products and services."
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />
      </InputItem>
      <div className={'flex justify-end gap-y-2 gap-x-2'}>
        <LegacyButton variant="secondary" onClick={onCancel}>
          Cancel
        </LegacyButton>
        <LegacyButton
          variant="default"
          onClick={onSave}
          disabled={name.length === 0 || desc.length === 0}
        >
          Save
        </LegacyButton>
      </div>
    </div>
  );
};
