/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import ColorLensIcon from '@mui/icons-material/ColorLens';
import {Card, CardContent} from '@mui/material';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import * as React from 'react';
import {PageContainer} from '../../components/ui/page';

export const Dev: React.FC = () => {
  return (
    <PageContainer title="Developer">
      <Card>
        <CardHeader title={'Tools'} />
        <CardContent>
          <Button
            startIcon={<ColorLensIcon />}
            variant="outlined"
            href="/dev/mui"
          >
            MUI Theme
          </Button>
        </CardContent>
      </Card>
    </PageContainer>
  );
};
