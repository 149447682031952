/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ReactNode, useCallback, useState} from 'react';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import {LegacyButton} from '../../components/ui/legacy-button';

interface ActionConfirmationProps {
  readonly prompt: string;
  readonly confirmationButtonText: string;
  readonly onConfirm: () => Promise<void>;
  readonly onCancel?: () => Promise<void> | void;
}

interface ActionConfirmation {
  readonly modal: ReactNode;
  readonly show: () => void;
  readonly hide: () => void;
}

export const useActionConfirmation = ({
  onConfirm,
  onCancel,
  prompt,
  confirmationButtonText,
}: ActionConfirmationProps): ActionConfirmation => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const show = useCallback(
    () => setIsConfirmVisible(true),
    [setIsConfirmVisible],
  );
  const hide = useCallback(
    () => setIsConfirmVisible(false),
    [setIsConfirmVisible],
  );

  return {
    modal: (
      <Dialog
        open={isConfirmVisible}
        onOpenChange={async (open) => {
          if (open) return;
          hide();
          if (onCancel) {
            await onCancel();
          }
        }}
      >
        <DialogContent description="confirmation">
          <DialogHeader>
            <DialogTitle>Confirm</DialogTitle>
          </DialogHeader>
          <div className={'flex flex-col gap-y-6'}>{prompt}</div>
          <DialogFooter>
            <LegacyButton
              variant="ghost"
              onClick={async () => {
                hide();
                if (onCancel) await onCancel();
              }}
            >
              Cancel
            </LegacyButton>
            <LegacyButton
              onClick={async () => {
                setIsConfirming(true);
                await onConfirm();
                setIsConfirming(false);
                hide();
              }}
              isLoading={isConfirming}
            >
              {confirmationButtonText}
            </LegacyButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    ),
    show,
    hide,
  };
};
