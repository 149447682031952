/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {TrendInterval} from '@agent-assist/api-typescript-react-query-hooks';
import {
  useGetPromptAggregateAnalyticsWithFilters,
  useGetPromptTrendAnalyticsWithFilters,
} from '@agent-assist/api-typescript-react-query-hooks/lib/apis/AgentAssistApiHooks';
import {useCallback, useEffect, useState} from 'react';
import {BarGraph} from './BarGraphWithHook';
import DonutChart from './DonutChart';
import {ExpandedChartModal} from './ExpandedChartModal';
import {LineChartWithHook} from './LineChart';
import SentimentGraph from './SentimentGraph';
import {AnalyticHookProps, ColourType, ModalMode, Visualisation} from './types';
import {ClassValue} from '../../../../api/generated/runtime/typescript/lib/models/ClassValue';
import {TrendAnalyticsData} from '../../../../api/generated/runtime/typescript/lib/models/TrendAnalyticsData';
import {localTimeToDatabaseTime} from '../../lib/formatDate';
import {cleanseFilters} from '../../pages/contact-import/cleanseFilters';

interface GraphWithHookProps extends AnalyticHookProps {
  insight: Visualisation;
  refetch?: () => Promise<void>;
  colourType?: ColourType;
  isEditMode?: boolean;
  handleModal?: (action: ModalMode) => void;
  setCurrentInsight?: React.Dispatch<React.SetStateAction<Visualisation>>;
}

// generic fn for all kinds of graphs:
export const GraphWithHook = ({
  insight,
  refetch,
  contactImportId,
  colourType,
  filters,
  isEditMode,
  handleModal,
  setCurrentInsight,
}: GraphWithHookProps) => {
  const [data, setData] = useState<ClassValue[]>([]);
  const [trendData, setTrendData] = useState<TrendAnalyticsData[]>([]);
  const [interval, setInterval] = useState<TrendInterval>('Day');
  const {title, questionType: type, tooltip, questionId: dataColumn} = insight;
  const [isExpandedModalOpen, setIsExpandedModalOpen] = useState(false);
  const {isLoading, isSuccess, mutateAsync} =
    useGetPromptAggregateAnalyticsWithFilters();
  const {
    isLoading: isTrendLoading,
    isSuccess: isTrendSuccess,
    mutateAsync: mutateTrendAsync,
  } = useGetPromptTrendAnalyticsWithFilters();

  useEffect(() => {
    const {filters: cleansedFilters, from, to} = cleanseFilters(filters);
    const getAggregateData = async () => {
      const response = await mutateAsync({
        contactImportId,
        getPromptAggregateAnalyticsWithFiltersRequestContent: {
          questionId: dataColumn,
          from: localTimeToDatabaseTime(from),
          to: localTimeToDatabaseTime(to),
          filters: cleansedFilters,
        },
      });
      const results = response?.analytics;
      if (results) {
        setData(results);
      }
    };
    const getTrendData = async () => {
      const response = await mutateTrendAsync({
        contactImportId,
        getPromptTrendAnalyticsWithFiltersRequestContent: {
          questionId: dataColumn,
          interval,
          from: localTimeToDatabaseTime(from),
          to: localTimeToDatabaseTime(to),
          filters: cleansedFilters,
        },
      });
      const results = response?.analytics;
      if (results) {
        setTrendData(results);
      }
    };

    if (insight.visualisationType === 'Aggregate') {
      void getAggregateData();
    } else {
      void getTrendData();
    }
  }, [
    contactImportId,
    filters,
    interval,
    insight,
    dataColumn,
    mutateAsync,
    mutateTrendAsync,
  ]);

  // console.log("GraphWithHook", data, isLoading, isSuccess);
  const handleExpandModal = useCallback(() => {
    setIsExpandedModalOpen(true);
  }, [setIsExpandedModalOpen]);

  const handleCloseExpandModal = useCallback(() => {
    setIsExpandedModalOpen(false);
  }, [setIsExpandedModalOpen]);

  const noDataFound = isSuccess && data.length === 0;
  const commonProps = {
    isLoading,
    title,
    tooltip,
    insight,
    noDataFound,
    refetch,
    isEditMode,
    handleModal,
    setCurrentInsight,
  };

  const noTrendDataFound = isTrendSuccess && trendData.length === 0;
  const trendProps = {
    isLoading: isTrendLoading,
    title,
    tooltip,
    insight,
    noDataFound: noTrendDataFound,
    refetch,
    isEditMode,
    handleModal,
    handleExpandModal,
    isExpandedModalOpen,
    setCurrentInsight,
    interval,
    setInterval,
  };

  const renderLineChart = useCallback(
    () => <LineChartWithHook values={trendData} {...trendProps} />,
    [trendData, interval, setInterval, handleExpandModal, trendProps],
  );

  if (insight.visualisationType === 'Aggregate') {
    switch (type) {
      case 'extraction_rating':
        return <SentimentGraph data={data} {...commonProps} />;
      case 'extraction_binary':
        return (
          <DonutChart data={data} colourType={colourType} {...commonProps} />
        );
      case 'extraction_single_label':
        return (
          <BarGraph
            contactImportId={contactImportId}
            values={data}
            {...commonProps}
            isEditMode={isEditMode}
          />
        );
      default:
        return <div>Invalid chart type: {type}</div>;
    }
  } else {
    return (
      <>
        <LineChartWithHook values={trendData} {...trendProps} />
        <ExpandedChartModal
          isOpen={isExpandedModalOpen}
          onClose={handleCloseExpandModal}
        >
          {renderLineChart()}
        </ExpandedChartModal>
      </>
    );
  }
};
