/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import { S3Client, PutObjectCommand, GetObjectCommand, DeleteObjectCommand } from "@aws-sdk/client-s3"; // ES Modules import
import { AppEvent } from "../../bus/messagebus";

export abstract class AbstractEventStore {
  bucketName: string = "python-invoke-bucket-trusstgpt";

  public abstract put(event: AppEvent): Promise<string>; // puts an event and returns a unique generated key
  public abstract get(key: string): Promise<AppEvent>; // given the unique generated key, returns the event if it exists
  public getFullKey(key: string): string {
    return "s3://" + this.bucketName + "/" + key;
  }
  public abstract delete(key: string): Promise<string>;
}

export class FakeEventStore extends AbstractEventStore {
  events: { [key: string]: AppEvent } = {};

  public put = async (event: AppEvent): Promise<string> => {
    const uniqueId = String(Date.now());
    this.events[uniqueId] = event;
    return uniqueId;
  };

  public get = async (key: string): Promise<AppEvent> => {
    return this.events[key];
  };

  public delete = async (key: string): Promise<string> => {
    delete this.events.key;
    return key;
  };
}

export class EventStore extends AbstractEventStore {
  events: { [key: string]: AppEvent } = {};

  public put = async (event: AppEvent): Promise<string> => {
    const client = new S3Client();
    const uniqueId = String(Date.now());
    const input = {
      // PutObjectRequest
      Body: JSON.stringify(event),
      Bucket: this.bucketName,
      Key: uniqueId,
    };
    const command = new PutObjectCommand(input);
    await client.send(command);
    return uniqueId;
  };

  public get = async (key: string): Promise<AppEvent> => {
    const client = new S3Client();
    const input = {
      // GetObjectRequest
      Bucket: this.bucketName,
      Key: key,
    };
    const command = new GetObjectCommand(input);
    await client.send(command);
    //console.log(response.Body);
    return {
      name: "dummyEventName",
      payload: {
        first: "first field",
        second: "second field",
      },
    };
  };

  public delete = async (key: string): Promise<string> => {
    const input = {
      Bucket: this.bucketName,
      Key: key,
    };
    const client = new S3Client();
    const command = new DeleteObjectCommand(input);
    await client.send(command);
    return key;
  };
}
