/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

export const wrappedJson = (
  jsonString: string,
  key?: string | number,
): JSX.Element => {
  let formattedJson: string = jsonString;

  try {
    // invalid json will throw
    formattedJson = JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch (err) {
    console.warn(err);
  }

  return (
    <pre
      key={key}
      style={{
        color: '#fffb',
        background: '#0006',
        border: '1px solid #0004',
        padding: '4px',
        borderRadius: '4px',
        fontSize: '80%',
        margin: '4px 0',
        textWrap: 'wrap',
      }}
    >
      {formattedJson}
    </pre>
  );
};
