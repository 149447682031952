/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactImportStage } from './ContactImportStage';
import {
    ContactImportStageFromJSON,
    ContactImportStageFromJSONTyped,
    ContactImportStageToJSON,
} from './ContactImportStage';
import type { ContactSource } from './ContactSource';
import {
    ContactSourceFromJSON,
    ContactSourceFromJSONTyped,
    ContactSourceToJSON,
} from './ContactSource';
import type { ContactStatus } from './ContactStatus';
import {
    ContactStatusFromJSON,
    ContactStatusFromJSONTyped,
    ContactStatusToJSON,
} from './ContactStatus';
import type { FileS3Location } from './FileS3Location';
import {
    FileS3LocationFromJSON,
    FileS3LocationFromJSONTyped,
    FileS3LocationToJSON,
} from './FileS3Location';
import type { Summaries } from './Summaries';
import {
    SummariesFromJSON,
    SummariesFromJSONTyped,
    SummariesToJSON,
} from './Summaries';

/**
 * A contact which has been imported
 * @export
 * @interface ImportedContact
 */
export interface ImportedContact {
    /**
     * Amazon connect contact channel
     * @type {string}
     * @memberof ImportedContact
     */
    channel: string;
    /**
     * 
     * @type {ContactStatus}
     * @memberof ImportedContact
     */
    status: ContactStatus;
    /**
     * Time at which the contact started
     * @type {number}
     * @memberof ImportedContact
     */
    startedAt: number;
    /**
     * Time at which the contact finished (if in the completed status)
     * @type {number}
     * @memberof ImportedContact
     */
    completedAt?: number;
    /**
     * 
     * @type {Summaries}
     * @memberof ImportedContact
     */
    summary?: Summaries;
    /**
     * 
     * @type {ContactSource}
     * @memberof ImportedContact
     */
    source?: ContactSource;
    /**
     * ID of the job
     * @type {string}
     * @memberof ImportedContact
     */
    contactImportId: string;
    /**
     * The connect instance from which this contact was sourced
     * @type {string}
     * @memberof ImportedContact
     */
    connectInstanceId?: string;
    /**
     * Reason why contact was not imported
     * @type {string}
     * @memberof ImportedContact
     */
    reason?: string;
    /**
     * 
     * @type {FileS3Location}
     * @memberof ImportedContact
     */
    s3Location?: FileS3Location;
    /**
     * 
     * @type {string}
     * @memberof ImportedContact
     */
    audioS3Key?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedContact
     */
    jsonS3Key?: string;
    /**
     * 
     * @type {ContactImportStage}
     * @memberof ImportedContact
     */
    stage?: ContactImportStage;
    /**
     * Identifier for a prompt revision
     * @type {string}
     * @memberof ImportedContact
     */
    promptRevisionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedContact
     */
    qaPromptRevisionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedContact
     */
    language?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportedContact
     */
    maxSpeakers?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ImportedContact
     */
    translateToEnglish?: boolean;
    /**
     * The time at which the resource was created
     * @type {number}
     * @memberof ImportedContact
     */
    createdAt: number;
    /**
     * The time of the most recent update
     * @type {number}
     * @memberof ImportedContact
     */
    updatedAt: number;
    /**
     * ID of the contact imported
     * @type {string}
     * @memberof ImportedContact
     */
    contactId: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedContact
     */
    filePrefix?: string;
}


/**
 * Check if a given object implements the ImportedContact interface.
 */
export function instanceOfImportedContact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "channel" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "contactImportId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "contactId" in value;

    return isInstance;
}

export function ImportedContactFromJSON(json: any): ImportedContact {
    return ImportedContactFromJSONTyped(json, false);
}

export function ImportedContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportedContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel': json['channel'],
        'status': ContactStatusFromJSON(json['status']),
        'startedAt': json['startedAt'],
        'completedAt': !exists(json, 'completedAt') ? undefined : json['completedAt'],
        'summary': !exists(json, 'summary') ? undefined : SummariesFromJSON(json['summary']),
        'source': !exists(json, 'source') ? undefined : ContactSourceFromJSON(json['source']),
        'contactImportId': json['contactImportId'],
        'connectInstanceId': !exists(json, 'connectInstanceId') ? undefined : json['connectInstanceId'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        's3Location': !exists(json, 's3Location') ? undefined : FileS3LocationFromJSON(json['s3Location']),
        'audioS3Key': !exists(json, 'audioS3Key') ? undefined : json['audioS3Key'],
        'jsonS3Key': !exists(json, 'jsonS3Key') ? undefined : json['jsonS3Key'],
        'stage': !exists(json, 'stage') ? undefined : ContactImportStageFromJSON(json['stage']),
        'promptRevisionId': !exists(json, 'promptRevisionId') ? undefined : json['promptRevisionId'],
        'qaPromptRevisionId': !exists(json, 'qaPromptRevisionId') ? undefined : json['qaPromptRevisionId'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'maxSpeakers': !exists(json, 'maxSpeakers') ? undefined : json['maxSpeakers'],
        'translateToEnglish': !exists(json, 'translateToEnglish') ? undefined : json['translateToEnglish'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'contactId': json['contactId'],
        'filePrefix': !exists(json, 'filePrefix') ? undefined : json['filePrefix'],
    };
}

export function ImportedContactToJSON(value?: ImportedContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel': value.channel,
        'status': ContactStatusToJSON(value.status),
        'startedAt': value.startedAt,
        'completedAt': value.completedAt,
        'summary': SummariesToJSON(value.summary),
        'source': ContactSourceToJSON(value.source),
        'contactImportId': value.contactImportId,
        'connectInstanceId': value.connectInstanceId,
        'reason': value.reason,
        's3Location': FileS3LocationToJSON(value.s3Location),
        'audioS3Key': value.audioS3Key,
        'jsonS3Key': value.jsonS3Key,
        'stage': ContactImportStageToJSON(value.stage),
        'promptRevisionId': value.promptRevisionId,
        'qaPromptRevisionId': value.qaPromptRevisionId,
        'language': value.language,
        'maxSpeakers': value.maxSpeakers,
        'translateToEnglish': value.translateToEnglish,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'contactId': value.contactId,
        'filePrefix': value.filePrefix,
    };
}

