/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  AnalyticsData,
  ContactSynopsisElement,
  QAAnalyticsResults,
  SynopsisEvaluationRating,
  useGetContact,
  useUpdatePromptFeedback,
} from '@agent-assist/api-typescript-react-query-hooks';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  CornerDownRight,
  ExternalLinkIcon,
  MessageSquareText,
  ThumbsDown,
  ThumbsUp,
} from 'lucide-react';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {usePromptCommentModal} from '../../hooks/modal/prompt-comment-modal';
import {cn} from '../../lib/utils';
import {LoadingSpinner} from '../LoadingSpinner';
import {LegacyButton} from '../ui/legacy-button';

interface ExpandableRowTableProps {
  data?: QAAnalyticsResults;
  isLoading: boolean;
  isSuccess: boolean;
  contactImportId: string;
}

type KeyString = {[key: string]: string};
type KeyAny = {[key: string]: any};

export interface Row {
  id: string;
  contactId: string;
  intent: string;
  resolution: string;
  category: string;
  subCategory: string;
  subSubCategory: string;
  softSkillsTotalScore: number | string;
  complianceTotalScore: number | string;
  productTotalScore: number | string;
  processTotalScore: number | string;
  createdAt: string;
}

const headers: {key: string; value: string; redshiftKey: string}[] = [
  {key: 'contactId', value: 'Contact ID', redshiftKey: 'contactId'},
  {key: 'intent', value: 'Intent', redshiftKey: 'intentpd'},
  {key: 'resolution', value: 'Resolution', redshiftKey: 'resolutionpd'},
  {key: 'category', value: 'Category', redshiftKey: 'categorypd'},
  {key: 'subCategory', value: 'Sub Category', redshiftKey: 'sub-Categorypd'},
  {
    key: 'subSubCategory',
    value: 'Sub-Sub Category',
    redshiftKey: 'sub-Sub-Categorypd',
  },
  {
    key: 'softSkillsTotalScore',
    value: 'Achieved SoftSkills Score(%)',
    redshiftKey: 'softSkillsTotalScore',
  },
  {
    key: 'complianceTotalScore',
    value: 'Achieved Compliance Score(%)',
    redshiftKey: 'complianceTotalScore',
  },
  {
    key: 'productTotalScore',
    value: 'Achieved Product Score(%)',
    redshiftKey: 'productTotalScore',
  },
  {
    key: 'processTotalScore',
    value: 'Achieved Process Score(%)',
    redshiftKey: 'processTotalScore',
  },
  {key: 'createdAt', value: 'Contact Date', redshiftKey: 'createdAt'},
];
const columns: MUIDataTableColumn[] = headers.map((kv) => {
  return {
    name: kv.key,
    label: kv.value,
  };
});

const PromptRating = (props: {
  promptRating?: SynopsisEvaluationRating;
  promptComment?: string;
  onFeedback: (
    questionId: string,
    rating: SynopsisEvaluationRating,
  ) => Promise<void>;
  onSaveComment: (questionId: string, comment: string) => Promise<void>;
  questionId: string;
  contactImportId?: string;
}) => {
  const {questionId, promptComment, promptRating, onFeedback, onSaveComment} =
    props;
  const [localRating, setLocalRating] = useState(promptRating);
  const [localComment, setLocalComment] = useState(promptComment);

  const commentConfirmation = usePromptCommentModal({
    onConfirm: () => onSaveComment(questionId, localComment ?? ''),
    onChangeComment: setLocalComment,
    comment: localComment ?? '',
  });

  return (
    <div className={'flex'}>
      {commentConfirmation.modal}
      <LegacyButton
        variant={'ghost'}
        size={'icon'}
        onClick={() => {
          void onFeedback(questionId, 'GOOD');
          setLocalRating('GOOD');
        }}
      >
        <ThumbsUp
          className={cn(
            'cursor-pointer',
            localRating && localRating === 'GOOD'
              ? 'text-green-500'
              : 'text-muted-foreground',
          )}
          size={20}
        />
      </LegacyButton>
      <LegacyButton
        variant={'ghost'}
        size={'icon'}
        onClick={() => {
          void onFeedback(questionId, 'BAD');
          setLocalRating('BAD');
        }}
      >
        <ThumbsDown
          className={cn(
            'cursor-pointer',
            localRating && localRating === 'BAD'
              ? 'text-red-500'
              : 'text-muted-foreground',
          )}
          size={20}
        />
      </LegacyButton>
      <LegacyButton
        variant={'ghost'}
        size={'icon'}
        onClick={() => commentConfirmation.show()}
      >
        <Badge
          variant={'standard'}
          color={'info'}
          badgeContent={localComment ? 1 : 0}
        >
          <MessageSquareText
            className={cn('cursor-pointer', 'text-muted-foreground')}
            size={20}
          />
        </Badge>
      </LegacyButton>
    </div>
  );
};

interface RowProps {
  synopsis: ContactSynopsisElement[];
  onFeedback: (
    questionId: string,
    rating: SynopsisEvaluationRating,
  ) => Promise<void>;
  onSaveComment: (questionId: string, comment: string) => Promise<void>;
}

const RowComponent: FC<RowProps> = ({synopsis, onFeedback, onSaveComment}) => {
  const groupOrderSet = new Set();
  synopsis.map((elem) => {
    groupOrderSet.add(elem.promptGroupName);
  });
  const groupOrder = Array.from(groupOrderSet);
  const orderedSynopsis = synopsis.sort((a, b) => {
    if (a.promptGroupName !== b.promptGroupName) {
      return (
        groupOrder.indexOf(a.promptGroupName) -
        groupOrder.indexOf(b.promptGroupName)
      );
    }
    if (a.question?.key !== b.question?.key) {
      return a.question?.key.localeCompare(b.question?.key || '') || 0;
    }
    return (
      (b.question?.properties.isParent ? 1 : 0) -
      (a.question?.properties.isParent ? 1 : 0)
    );
  });
  return (
    <>
      {orderedSynopsis.map((row, i) => {
        if (row.question === undefined) {
          return <></>;
        } else {
          const {comment, rating, score, question} = row;
          const {isChild, isParent} = question.properties;
          const formattedScore =
            score === 'N/A' || score === undefined ? '' : score;

          return (
            <TableRow key={`${row.question}-i-${i}`}>
              <TableCell
                className={`questionAlign ${isChild ? 'childRow' : ''}`}
              >
                {isChild && (
                  <CornerDownRight
                    className={cn('cursor-pointer', 'text-muted-foreground')}
                    size={20}
                  />
                )}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`questionAlign ${isChild ? 'childRow' : ''}`}
              >
                {row.question?.question}
              </TableCell>
              <TableCell align="center" className={isChild ? 'childRow' : ''}>
                {row.response.toString()}
              </TableCell>
              {row.promptGroupName === 'compliance' ? (
                <>
                  <TableCell className={isChild ? 'childRow' : ''}></TableCell>
                  <TableCell
                    align="center"
                    className={isChild ? 'childRow' : ''}
                  >
                    {formattedScore}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell
                    align="center"
                    className={isChild ? 'childRow' : ''}
                  >
                    {formattedScore}
                  </TableCell>
                  <TableCell className={isChild ? 'childRow' : ''}></TableCell>
                </>
              )}
              <TableCell align="center" className={isChild ? 'childRow' : ''}>
                {!isParent && (
                  <PromptRating
                    promptRating={rating?.rating}
                    promptComment={comment?.comment}
                    onFeedback={onFeedback}
                    onSaveComment={onSaveComment}
                    questionId={row.question.id}
                  />
                )}
              </TableCell>
            </TableRow>
          );
        }
      })}
    </>
  );
};

const ExpandableRow = ({
  rows,
  rowMeta,
  contactImportId,
}: {
  rows: KeyString[];
  rowMeta: {dataIndex: number; rowIndex: number};
  contactImportId: string;
}) => {
  const [contactSynopsis, setContactSynopsis] =
    useState<ContactSynopsisElement[]>();
  const feedbackOnPrompt = useUpdatePromptFeedback();

  const id = rows[rowMeta.dataIndex].id;

  const contactImportID = contactImportId.startsWith('import')
    ? contactImportId
    : undefined;
  const {data, isLoading, isFetched} = useGetContact({
    contactId: id,
    contactImportId: contactImportID,
  });
  useEffect(() => {
    if (data?.summary?.qa) {
      setContactSynopsis(data.summary.qa.synopsis);
    }
  }, [data]);

  const onFeedback = useCallback(
    async (questionId: string, rating: SynopsisEvaluationRating) => {
      // Update prompt feedback
      await feedbackOnPrompt.mutateAsync({
        contactId: id,
        contactImportId: contactImportID,
        updatePromptFeedbackRequestContent: {
          rating,
          questionId,
        },
      });
    },
    [id, contactImportID],
  );
  const onSaveComment = useCallback(
    async (questionId: string, comment: string) => {
      // Update prompt comment
      if (comment === '') {
        return;
      }
      await feedbackOnPrompt.mutateAsync({
        contactId: id,
        contactImportId: contactImportID,
        updatePromptFeedbackRequestContent: {
          comment,
          questionId,
        },
      });
    },
    [id, contactImportID],
  );
  return (
    <>
      {isLoading && (
        <div className={'flex px-4 py-10'}>
          <LoadingSpinner />
        </div>
      )}
      {isFetched && (
        <React.Fragment>
          <tr style={{padding: '1rem'}}>
            <td colSpan={10}>
              <div
                style={{maxHeight: '400px', overflowX: 'auto', width: '100%'}}
              >
                <TableContainer component={Paper}>
                  <Table style={{minWidth: '650'}} aria-label="question table">
                    <TableHead className="expanded-header">
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Question</TableCell>
                        <TableCell align="center">Response</TableCell>
                        <TableCell align="center">Soft Skills Score</TableCell>
                        <TableCell align="center">Compliance Score</TableCell>
                        <TableCell align="center">Feedback</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contactSynopsis && (
                        <RowComponent
                          synopsis={contactSynopsis}
                          onFeedback={onFeedback}
                          onSaveComment={onSaveComment}
                        />
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </td>
          </tr>
        </React.Fragment>
      )}
    </>
  );
};

const createRowData = (analyticsRows: AnalyticsData[]): Row => {
  const getValue = (key: string): string => {
    const redshiftKey = headers.find(
      (header) => header.key === key,
    )?.redshiftKey;
    const result = analyticsRows.find((row) => row.key === redshiftKey);
    return result?.value?.toString() || '';
  };

  const parseScore = (key: string): string | number => {
    const score = Number(getValue(key));
    return isNaN(score) ? 'N/A' : score;
  };

  const createdAt = getValue('createdAt');
  const formattedDate = createdAt
    ? new Date(createdAt).toISOString().split('T')[0]
    : new Date().toISOString().split('T')[0];

  return {
    id: getValue('contactId'),
    contactId: getValue('contactId'),
    intent: getValue('intent'),
    resolution: getValue('resolution'),
    category: getValue('category'),
    subCategory: getValue('subCategory'),
    subSubCategory: getValue('subSubCategory'),
    softSkillsTotalScore: parseScore('softSkillsTotalScore'),
    complianceTotalScore: parseScore('complianceTotalScore'),
    productTotalScore: parseScore('productTotalScore'),
    processTotalScore: parseScore('processTotalScore'),

    createdAt: formattedDate,
  };
};

export const convertQaAnalyticsToArray = (obj: QAAnalyticsResults): Row[] => {
  return Object.values(obj).map(createRowData);
};

const ExpandableRowTable = ({
  data,
  isLoading,
  isSuccess,
  contactImportId,
}: ExpandableRowTableProps) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([]);

  const [rows, setRows] = useState<KeyAny[]>();
  const [realColumns, setRealColumns] = useState<MUIDataTableColumn[]>([]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const newRows = convertQaAnalyticsToArray(data);
      setRows(newRows);

      let rowId = '';
      const newColumns = [...columns];
      // add a custom renderer to the first column to make it a clickable link:
      newColumns[0].options = {
        filter: true,
        customBodyRenderLite: (dataIndex: number) => {
          rowId = newRows[dataIndex].contactId;
          const contactLink = `/contacts/${contactImportId}/contact/${rowId}`;
          return (
            <Link
              className="flex gap-1 items-center"
              to={contactLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {rowId}
              <ExternalLinkIcon size={32} />
            </Link>
          );
        },
      };
      setRealColumns(newColumns);
    } else {
      setRows([]);
    }
  }, [data]);

  const options: MUIDataTableOptions = {
    filter: true,
    onFilterChange: (changedColumn: any, filterList: any) => {
      console.log(changedColumn, filterList);
    },
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    onRowExpansionChange: (
      _currentRowsExpanded,
      allRowsExpanded: {index: number; dataIndex: number}[],
    ) => {
      // maintain currently expanded rows - otherwise when user clicks away and returns to this tab, the rows have collapsed.
      setRowsExpanded(allRowsExpanded.map((r) => r.dataIndex));
    },

    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'vertical',
    rowsExpanded,
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    expandableRows: true,

    renderExpandableRow: (
      _rowData: any,
      rowMeta: {dataIndex: number; rowIndex: number},
    ) => (
      <ExpandableRow
        rows={rows ?? []}
        rowMeta={rowMeta}
        contactImportId={contactImportId}
      />
    ),
    page,
  };

  return (
    <Box sx={{width: '100%'}}>
      <>
        <div className="relative">
          {isLoading && (
            <div
              className={
                'flex items-center justify-center absolute w-full p-2 z-50'
              }
            >
              <LoadingSpinner size={'lg'} />
            </div>
          )}
          {isSuccess && !data && (
            <p className="text-xs font-semibold my-10 w-full">
              No Analytics Data has been populated for this Contact Import Job
            </p>
          )}
          {!isLoading && isSuccess && !!data && (
            <MUIDataTable
              title={''}
              data={rows ?? []}
              columns={realColumns}
              options={options}
            />
          )}
        </div>
      </>
    </Box>
  );
};

export default ExpandableRowTable;
