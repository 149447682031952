import {config} from '../../config';
import type {Settings} from '../../types/settings';

export function applyDefaultSettings(settings: Partial<Settings>): Settings {
  return {
    primaryColor: config.site.primaryColor,
    direction: 'ltr',
    navColor: 'discrete',
    layout: 'vertical',
    ...settings,
  };
}
