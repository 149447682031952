/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import React from 'react';
import {cn} from '../../lib/utils';

interface HeadingProps extends React.InputHTMLAttributes<HTMLHeadingElement> {
  h2?: boolean;
  h3?: boolean;
}

const LegacyHeading = React.forwardRef<HTMLInputElement, HeadingProps>(
  ({children, className, h2, h3, ...props}, ref) => {
    if (h2 && h3) throw new Error('pick h2 or h3, not both!');
    if (h2) {
      return (
        <h2
          className={cn(
            'text-xl font-semibold text-muted-foreground',
            className,
          )}
          ref={ref}
          {...props}
        >
          {children}
        </h2>
      );
    }

    if (h3) {
      return (
        <h3
          className={cn('font-semibold text-muted-foreground', className)}
          ref={ref}
          {...props}
        >
          {children}
        </h3>
      );
    }

    return (
      <h1
        className={cn('text-2xl font-semibold w-full', className)}
        ref={ref}
        {...props}
      >
        {children}
      </h1>
    );
  },
);
LegacyHeading.displayName = 'Heading';

export default LegacyHeading;
