/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import type {Components} from '@mui/material/styles';
import {CaretLeft as CaretLeftIcon} from '@phosphor-icons/react/dist/ssr/CaretLeft';
import {CaretRight as CaretRightIcon} from '@phosphor-icons/react/dist/ssr/CaretRight';
import * as React from 'react';

import type {Theme} from '../types';

function PreviousButtonIcon(): React.JSX.Element {
  return <CaretLeftIcon fontSize="var(--fontSize-md)" />;
}

function NextButtonIcon(): React.JSX.Element {
  return <CaretRightIcon fontSize="var(--fontSize-md)" />;
}

export const MuiTablePagination = {
  defaultProps: {
    slotProps: {
      actions: {
        nextButtonIcon: {component: NextButtonIcon},
        previousButtonIcon: {component: PreviousButtonIcon},
      },
      select: {size: 'small', variant: 'outlined'},
    },
  },
  styleOverrides: {input: {marginRight: '16px', padding: 0}},
} satisfies Components<Theme>['MuiTablePagination'];
