/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import Box from '@mui/material/Box/Box';
import {
  GridColDef,
  GridPaginationModel,
  GridRowIdGetter,
} from '@mui/x-data-grid';
import {DataGrid} from '@mui/x-data-grid/DataGrid/DataGrid';

interface MUIDataGridProps {
  listLoading: boolean;
  rows: any[];
  columns: GridColDef[];
  paginationModel: {
    pageSize: number;
    page: number;
  };
  getRowId?: GridRowIdGetter<{
    agentId: string;
  }>;
  handlePaginationModelChange: (
    newPaginationModel: GridPaginationModel,
  ) => void;
  rowCount: number;
}

export const MuiDataGrid = ({
  listLoading,
  rowCount,
  rows,
  columns,
  paginationModel,
  handlePaginationModelChange,
}: MUIDataGridProps) => {
  return (
    <Box style={{height: 400, width: '100%'}}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        loading={listLoading}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        disableColumnFilter={true}
        getRowId={(row: {agentId: string}) => row.agentId} // TODO: Make it generic so that it gets the ids from higher component
      />
    </Box>
  );
};
