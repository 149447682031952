/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {AgentAssistApiClientProvider as DefaultApiClientProvider_} from '@agent-assist/api-typescript-react-query-hooks';
import {MutationCache, QueryClient} from '@tanstack/react-query';
import isArray from 'lodash/isArray';
import startCase from 'lodash/startCase';
import React, {useMemo} from 'react';
import {v4 as uuid} from 'uuid';
import {useNotificationContext} from './NotificationContextProvider';
import {
  errorTitle,
  isApiError,
  useDefaultApiClient,
} from '../hooks/useDefaultApiClient';

/**
 * Sets up the runtimeContext and Cognito auth.
 *
 * This assumes a runtime-config.json file is present at '/'. In order for Auth to be set up automatically,
 * the runtime-config.json must have the following properties configured: [region, userPoolId, userPoolWebClientId, identityPoolId].
 */
const DefaultApiClientProvider: React.FC<any> = ({children}) => {
  const client = useDefaultApiClient();
  const {setNotifications} = useNotificationContext();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        /*
        // until we get notifications back online, leaving this untouched:
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 1 * 60 * 1000,
          },
        },
        */
        mutationCache: new MutationCache({
          onError: (e: unknown, _variables, _context, mutation) => {
            console.log('onError', e, mutation);

            // When an error occurs for a mutation, extract its details and display a notification
            const apiError = isApiError(e) ? e : undefined;
            const header = apiError ? errorTitle(apiError.status) : 'Error';
            const content = apiError
              ? apiError.details.message
              : `An unexpected error occurred for operation ${
                  mutation.options.mutationKey &&
                  isArray(mutation.options.mutationKey)
                    ? startCase(mutation.options.mutationKey?.[0] as string)
                    : ''
                }`;

            const notificationId = uuid();
            setNotifications((prev) => {
              if (
                prev.some((n) => n.header === header && n.content === content)
              ) {
                return prev;
              }

              return [
                ...prev,
                {
                  id: notificationId,
                  type: 'error',
                  header,
                  content,
                },
              ];
            });
          },
        }),
      }),
    [setNotifications],
  );

  return client ? (
    <DefaultApiClientProvider_ apiClient={client} client={queryClient}>
      {children}
    </DefaultApiClientProvider_>
  ) : (
    <></>
  );
};

export default DefaultApiClientProvider;
