/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ResponseError} from '@agent-assist/api-typescript-react-query-hooks';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  TableProps,
} from '@cloudscape-design/components';
import {UseInfiniteQueryResult} from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import {ReactNode, useMemo, useState} from 'react';

import Pagination from '../../components/Pagination/Pagination';
import {type StorageKey, useLocalStorage} from '../useLocalStorage';

interface TablePreferences {
  readonly preferences: ReactNode;
  readonly visibleColumns: readonly string[] | undefined;
  readonly wrapLines?: boolean;
  readonly stripedRows?: boolean;
}

interface SavedTablePreferences
  extends Omit<CollectionPreferencesProps.Preferences<any>, 'visibleContent'> {
  readonly hiddenColumns: string[];
}

/**
 * Hook to manage table preferences, saved to localstorage
 */
export const useTablePreferences = (
  key: StorageKey,
  columnDefinitions: TableProps.ColumnDefinition<any>[],
): TablePreferences => {
  const [preferences, setPreferences] = useLocalStorage<SavedTablePreferences>(
    key,
    {
      pageSize: 30,
      hiddenColumns: [],
      wrapLines: false,
      stripedRows: false,
    },
  );
  const hiddenColumns = useMemo(
    () => new Set(preferences.hiddenColumns),
    [preferences.hiddenColumns],
  );
  const visibleContent = useMemo(
    () =>
      columnDefinitions
        .filter((c) => !hiddenColumns.has(c.id!))
        .map((c) => c.id!),
    [columnDefinitions, hiddenColumns],
  );

  return {
    preferences: (
      <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        preferences={{
          ...preferences,
          visibleContent,
        }}
        onConfirm={({detail}) => {
          const visibleColumns = new Set(detail.visibleContent ?? []);
          setPreferences({
            ...detail,
            hiddenColumns: columnDefinitions
              .filter((c) => !visibleColumns.has(c.id!))
              .map((c) => c.id!),
          });
        }}
        wrapLinesPreference={{
          label: 'Wrap lines',
          description: 'Check to see all the text and wrap the lines',
        }}
        stripedRowsPreference={{
          label: 'Striped rows',
          description: 'Check to add alternating shaded rows',
        }}
        visibleContentPreference={{
          title: 'Visible Columns',
          options: [
            {
              label: 'Columns',
              options: columnDefinitions.map((c) => ({
                label: c.header as string,
                id: c.id!,
              })),
            },
          ],
        }}
      />
    ),
    visibleColumns: visibleContent,
    wrapLines: preferences.wrapLines,
    stripedRows: preferences.stripedRows,
  };
};

interface PaginatedTableData<T> {
  readonly pagination: ReactNode;
  readonly loading?: boolean;
  readonly items: T[];
  readonly tableIndex: number;
  refetch(): Promise<void>;
  gotoStart(): void;
}

interface PaginatedTableDataOptions<T> {
  readonly pageSize?: number;
  readonly textFilterFunction?: (item: T) => boolean;
}

/**
 * Hook to manage table data sorting, filtering and pagination based on an infinite query
 */
export const usePaginatedTableData = <T, K extends string>(
  query: UseInfiniteQueryResult<Record<K, T[]>, ResponseError>,
  resultsKey: K,
  options?: PaginatedTableDataOptions<T>,
): PaginatedTableData<T> => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [tableIndex, setTableRowIndex] = useState(0);

  const pages = useMemo(() => {
    let results = (query?.data?.pages ?? []).flatMap((p) => p[resultsKey]);

    if (options?.textFilterFunction) {
      results = results.filter(options.textFilterFunction);
    }

    return chunk(results, options?.pageSize ?? 100);
  }, [query.data?.pages, options]);

  const totalPages = useMemo(() => {
    const firstPage = query?.data?.pages?.at(0);
    // every page in a bounded query (non-infinite) has `totalPages`... as it has `nextToken` as a pageIndex if needed!
    return (firstPage as any)?.totalPages;
  }, [query.data?.pages]);

  const onNextPage = async () => {
    const nextPageNumber = currentPageNumber + 1;
    const pageNumber = nextPageNumber - 1;
    const newStartIndex = options?.pageSize
      ? pageNumber * options.pageSize
      : pageNumber * 100;
    setTableRowIndex(newStartIndex);
    if (pages.length < nextPageNumber) {
      if (query.hasNextPage) {
        const {data} = await query.fetchNextPage();

        if (
          data &&
          data.pages.length === nextPageNumber &&
          data.pages[nextPageNumber - 1][resultsKey].length > 0
        ) {
          setCurrentPageNumber(nextPageNumber);
        }
      }
    } else {
      setCurrentPageNumber(nextPageNumber);
    }
  };

  const onPreviousPage = () => {
    if (currentPageNumber > 1) {
      setCurrentPageNumber((p) => p - 1);
      const pageNumber = currentPageNumber - 1;
      const newStartIndex = options?.pageSize
        ? (pageNumber - 1) * options.pageSize
        : (pageNumber - 1) * 100;
      setTableRowIndex(newStartIndex);
    }
  };

  const gotoStart = () => {
    if (tableIndex !== 0 || currentPageNumber !== 1) {
      setTableRowIndex(0);
      setCurrentPageNumber(1);
    } // else we are already at the start...
  };

  const onRefetch = async () => {
    await query.refetch();
    gotoStart();
  };

  return {
    pagination: (
      <Pagination
        currentPage={currentPageNumber}
        loading={
          query.isLoading || query.isFetchingNextPage || query.isRefetching
        }
        hasNext={pages.length > currentPageNumber || !!query.hasNextPage}
        hasPrevious={currentPageNumber > 1}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        totalPages={totalPages}
      />
    ),
    gotoStart,
    items: pages[currentPageNumber - 1] ?? [],
    loading: query.isLoading || query.isFetchingNextPage || query.isRefetching,
    refetch: onRefetch,
    tableIndex: tableIndex + 1,
  };
};
