/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useState} from 'react';
import {ToolsList} from './ListTools';
import ViewTool from './ToolsTabs';
import {useTrusstedAgentState} from '../../../providers/TrusstedAgentProvider';

interface Props {
  updateAgent: () => Promise<void>;
  updatingAgent: boolean;
  discardChanges: () => void;
}

const AgentTools = ({updateAgent, updatingAgent, discardChanges}: Props) => {
  const {modifiedAgent} = useTrusstedAgentState();
  const tools = modifiedAgent && modifiedAgent.tools ? modifiedAgent.tools : [];
  const [toolIndex, setToolIndex] = useState<number>(0);
  return (
    <Box sx={{flexGrow: 1}}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          {/* list the agent tools */}
          <ToolsList
            tools={tools}
            setToolIndex={setToolIndex}
            toolIndex={toolIndex}
          />
        </Grid>
        <Grid item xs={10}>
          {/* the tabs for tools */}
          <ViewTool
            updateAgent={updateAgent}
            updatingAgent={updatingAgent}
            discardChanges={discardChanges}
            toolIndex={toolIndex}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgentTools;
