/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';
import * as React from 'react';

import {SideNavDesktop} from './side-nav-desktop';
import {TopNav} from './top-nav';
import {layoutConfig} from '../../config';
import {useSettings} from '../../hooks/use-settings';

interface VerticalLayoutProps {
  children?: React.ReactNode;
}

export function DashboardLayout({
  children,
}: VerticalLayoutProps): React.JSX.Element {
  const {settings} = useSettings();

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{
          body: {
            '--MainNav-height': '56px',
            '--MainNav-zIndex': 1000,
            '--SideNav-width': '260px',
            '--SideNav-zIndex': 1100,
            '--MobileNav-width': '260px',
            '--MobileNav-zIndex': 1100,
          },
        }}
      />
      <Box
        sx={{
          bgcolor: 'var(--mui-palette-background-default)',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          minHeight: '100%',
        }}
      >
        <SideNavDesktop
          color={settings.navColor}
          mainItems={layoutConfig.navMainItems}
          secondaryItems={layoutConfig.navSecondaryItems}
        />
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            pl: {lg: 'var(--SideNav-width)'},
          }}
        >
          <TopNav
            mainItems={layoutConfig.navMainItems}
            secondaryItems={layoutConfig.navSecondaryItems}
          />
          <Box
            component="main"
            sx={{
              '--Content-margin': '0 auto',
              '--Content-maxWidth': 'var(--maxWidth-xl)',
              '--Content-paddingX': 'calc(4 * var(--mui-spacing))',
              '--Content-paddingY': {
                xs: 'calc(4 * var(--mui-spacing))',
                lg: '64px',
              },
              '--Content-padding':
                'var(--Content-paddingY) var(--Content-paddingX)',
              '--Content-width': '100%',
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                maxWidth: 'var(--Content-maxWidth)',
                m: 'var(--Content-margin)',
                p: 'var(--Content-padding)',
                width: 'var(--Content-width)',
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
