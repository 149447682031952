/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {TextField} from '@mui/material';
import Box from '@mui/material/Box/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography/Typography';
import {isEqual} from 'lodash';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  APIParams,
  METHODS,
  useTrusstedAgent,
} from '../../../hooks/trusstedAgent/useTrusstedAgent';
import {RuntimeConfigContext} from '../../../providers/RuntimeContextProvider';
import {
  setAgent,
  updateAgentSettings,
  useTrusstedAgentDispatch,
  useTrusstedAgentState,
} from '../../../providers/TrusstedAgentProvider';
import {supportedLanguages} from '../../CreateContactImportModal/supportedLanguages';
import {TrusstGPTButton} from '../../MaterialUI/Button';
import {StyledLoader} from '../../MaterialUI/StyledLoader';
import TrusstGPTTextField from '../../MaterialUI/TextField';

import {CommonActionBar} from '../ActionBars/CommonActions';
import {EVENT_NAMES} from '../ChatAgent/ChatTrusstedAgent';
import {AgentInput} from '../Types/types';

interface Props {
  updateAgent: () => Promise<void>;
  updatingAgent: boolean;
  discardChanges: () => void;
}

export const AgentConfigurationsForm = ({
  discardChanges,
  updateAgent,
  updatingAgent,
}: Props) => {
  const {modifiedAgent, originalAgent} = useTrusstedAgentState();
  const dispatch = useTrusstedAgentDispatch();
  const [publishingAgent, setPublishingAgent] = useState(false);
  const navigate = useNavigate();
  const runtimeContext = useContext(RuntimeConfigContext);
  const apiKey = runtimeContext?.trusstedAgentApiKey;
  const apiEndpoint = runtimeContext?.trusstedAgentApiEndpoint;
  const trusstedAgentAPIHook = (params: APIParams) =>
    useTrusstedAgent({
      apiEndpoint: apiEndpoint,
      apiKey: apiKey,
      ...params,
    });

  const onFieldChange = (updatedAgentSettings: AgentInput) => {
    updateAgentSettings(dispatch, updatedAgentSettings);
  };

  const deleteAgent = async () => {
    try {
      await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.DeleteAgent,
        methodName: METHODS.POST,
        params: {agentId: modifiedAgent?.agentId},
        queryParams: {},
      });
      setAgent(dispatch, undefined);
    } catch (error) {
      console.error('Error fetching agent:', error);
    } finally {
      navigate('/agents');
    }
  };

  const publishAgent = async () => {
    setPublishingAgent(true);
    try {
      const response = await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.PublishAgent,
        methodName: METHODS.POST,
        params: {agentId: modifiedAgent?.agentId},
        queryParams: {},
      });
      setAgent(dispatch, response.publishedAgent);
    } catch (error) {
      console.error('Error publishing the agent', error);
    } finally {
      setPublishingAgent(false);
    }
  };

  const nochangesIdentified = isEqual(modifiedAgent, originalAgent);
  const disableSave =
    modifiedAgent?.name === '' ||
    modifiedAgent?.description === '' ||
    updatingAgent ||
    nochangesIdentified ||
    publishingAgent;
  const showLoader = updatingAgent || publishingAgent;
  return (
    <div>
      <Box sx={{marginBottom: '2rem'}}>
        <Typography variant="body1">Agent Settings</Typography>
        <Typography variant="caption">Configure the agent settings</Typography>
      </Box>
      {showLoader && <StyledLoader />}
      {/* TODO: Add form validation */}
      <TrusstGPTTextField
        label={'Name'}
        value={modifiedAgent?.name || ''}
        variant={'outlined'}
        onChange={(event) => {
          onFieldChange({
            name: event.target.value,
            description: modifiedAgent?.description || '',
            greeting: modifiedAgent?.greeting || '',
            language: modifiedAgent?.language || '',
          });
        }}
        placeholder="Agent Name"
      />
      <TrusstGPTTextField
        label={'Description'}
        value={modifiedAgent?.description || ''}
        onChange={(event) => {
          onFieldChange({
            name: modifiedAgent?.name || '',
            description: event.target.value,
            greeting: modifiedAgent?.greeting || '',
            language: modifiedAgent?.language || '',
          });
        }}
        placeholder="Description"
        multiLine={true}
        rows={10}
      />
      <TrusstGPTTextField
        label={'Greeting'}
        value={modifiedAgent?.greeting || ''}
        variant={'outlined'}
        onChange={(event) => {
          onFieldChange({
            name: modifiedAgent?.name || '',
            description: modifiedAgent?.description || '',
            greeting: event.target.value,
            language: modifiedAgent?.language || '',
          });
        }}
      />
      <TextField
        value={modifiedAgent?.language || supportedLanguages[0].language}
        onChange={(event) => {
          onFieldChange({
            name: modifiedAgent?.name || '',
            description: modifiedAgent?.description || '',
            greeting: modifiedAgent?.greeting || '',
            language: event.target.value,
          });
        }}
        select
        label="Language"
        sx={{
          width: '100%',
          label: {color: 'inherit', backgroundColor: 'transparent'},
        }}
      >
        {supportedLanguages.map((lan) => (
          <MenuItem key={lan.code} value={lan.code}>
            {lan.language}
          </MenuItem>
        ))}
      </TextField>
      <CommonActionBar
        onSave={() => void updateAgent()}
        onDiscard={discardChanges}
        saveDisabled={disableSave}
        discardDisabled={nochangesIdentified}
      >
        <>
          <TrusstGPTButton
            variant="contained"
            disabled={modifiedAgent?.published || !modifiedAgent?.agentId}
            onClick={() => void publishAgent()}
            size="small"
            buttonText={'Publish Agent'}
          />
          <TrusstGPTButton
            variant="contained"
            disabled={
              !modifiedAgent?.agentId || modifiedAgent?.published === true
            }
            onClick={() => void deleteAgent()}
            size="small"
            buttonText={'Delete Agent'}
          />
        </>
      </CommonActionBar>
    </div>
  );
};
