/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import { GetObjectCommand, ListObjectsV2CommandOutput, PutObjectCommandInput, S3, _Object } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { PaginatedInput } from "../dynamodb";

export interface S3Location {
  readonly bucket: string;
  readonly key?: string;
}
const s3 = new S3();
/**
 * Parse an s3 path into its bucket and key
 * @param s3Path an s3 path eg s3://bucket/key
 */
export const parseS3Path = (s3Path: string): S3Location => {
  const s3StartPart = "s3://";

  if (!s3Path.startsWith(s3StartPart)) {
    throw new Error(`The provided location, ${s3Path}, does not start with ${s3StartPart}`);
  }

  const parts = s3Path.substring(s3StartPart.length).split("/");

  return {
    bucket: parts.shift()!,
    key: parts.join("/"),
  };
};

const batchListS3Files = async ({ bucket, key }: S3Location, paginationDetails: PaginatedInput) => {
  const result: ListObjectsV2CommandOutput = await s3.listObjectsV2({
    Bucket: bucket,
    Prefix: key,
    ContinuationToken: paginationDetails.nextToken,
    MaxKeys: paginationDetails.pageSize,
  });
  return result;
};

export const listAllFiles = async ({ bucket, key }: S3Location, pageSize: number) => {
  let nextToken: string | undefined = undefined;
  let result: _Object[] | undefined = [];
  // iterate and get all the files
  do {
    const files = await batchListS3Files({ bucket, key }, { nextToken, pageSize });
    result = files.Contents ? result.concat(files.Contents) : [];
    nextToken = files.NextContinuationToken;
  } while (nextToken);
  return result;
};

export const JsonFileHasAudio = async (location: S3Location, fileName: string): Promise<string> => {
  const { key } = location;
  const result: _Object[] = await listAllFiles(location, 1000);

  if (result && result.length > 0) {
    // check if file has a corresponding media file
    const audioExtensions = [".mp3", ".MP3", ".ogg", ".OGG"];
    // if it has a media file return true and return the media key in S3
    for (const extension of audioExtensions) {
      const audioKey = key ? `${removeTrailingSlash(key)}/${fileName}${extension}` : `${fileName}${extension}`;
      if (result.map((item) => item.Key).includes(audioKey)) {
        // Return the media key in S3 if found
        return audioKey;
      }
    }
  }
  return "";
};

export const removeTrailingSlash = (input: string): string => {
  return input.endsWith("/") ? input.slice(0, -1) : input;
};

export const generatePresignedUrl = async (bucket: string, key: string): Promise<string> => {
  const getObjectParams = {
    Bucket: bucket,
    Key: key,
  };

  const command = new GetObjectCommand(getObjectParams);
  try {
    const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
    return url;
  } catch (error) {
    const err = error as Error;
    console.error("Error generating pre-signed URL:", err.message);
    throw error;
  }
};

export const putObject = async (putCommand: PutObjectCommandInput) => {
  try {
    await s3.putObject(putCommand);
  } catch (error) {
    const err = error as Error;
    console.error("Error putting object in S3 bucket:", err.message);
    throw error;
  }
};
