/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import type {Components} from '@mui/material/styles';

import type {Theme} from '../types';

export const MuiSwitch = {
  defaultProps: {color: 'primary', disableRipple: true},
  styleOverrides: {
    root: {marginLeft: '-12px', marginRight: '-12px'},
    switchBase: {color: 'var(--mui-palette-neutral-500)'},
    track: {backgroundColor: 'var(--mui-palette-neutral-400)', opacity: 1},
  },
} satisfies Components<Theme>['MuiSwitch'];
