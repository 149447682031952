/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Box, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

type PageContainerProps = {
  title?: string;
  titleComponent?: React.ReactNode;
  subtitle?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
  stack?: boolean;
};
export const PageContainer: React.FC<PageContainerProps> = ({
  title,
  titleComponent,
  subtitle,
  children,
  actions,
  stack = true,
}) => {
  return (
    <Stack spacing={4}>
      <Stack
        direction={{xs: 'column', sm: 'row'}}
        spacing={3}
        sx={{alignItems: 'center'}}
      >
        <Stack sx={{flex: '1 1 auto'}} spacing={0.5}>
          {titleComponent ? (
            titleComponent
          ) : (
            <Typography
              variant="h4"
              sx={{'&::first-letter': {textTransform: 'capitalize'}}}
            >
              {title}
            </Typography>
          )}
          {!!subtitle && (
            <Typography color="text.secondary" variant="subtitle2">
              {subtitle}
            </Typography>
          )}
        </Stack>
        {actions && (
          <Stack direction="row" gap={2}>
            {actions}
          </Stack>
        )}
      </Stack>
      {stack ? (
        <Stack direction="column" spacing={4}>
          {children}
        </Stack>
      ) : (
        <Box>{children}</Box>
      )}
    </Stack>
  );
};
