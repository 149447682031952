import lowerCase from 'lodash/lowerCase';

// we get all sorts of junk from the LLM even though we tell it to:
//    "Ensure the label is in plain text, comprehensible, representative of the topic, and free from any punctuation or unnecessary formatting."

// this attempts to prettify labels:
export const formatKeyLabel = (str: string): string => {
  // if there are undesirable symbols use lodash on first pass, otherwise retain some desired capitals (eg NBN)
  const lowerCased = /[-_\.]/.test(str) ? lowerCase(str) : str;
  // then capitalise first chr.
  const titleCased =
    lowerCased.charAt(0).toUpperCase() + lowerCased.substring(1);
  return titleCased;
};
