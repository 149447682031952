/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ContactImport} from '@agent-assist/api-typescript-react-query-hooks';
import {create} from 'zustand';

interface ContactImportsState {
  contactImports: ContactImport[];
  setContactImports: (imports: ContactImport[]) => void;
}

export const useContactImportsStore = create<ContactImportsState>((set) => ({
  contactImports: [],
  setContactImports: (imports) => set(() => ({contactImports: imports})),
}));
