/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {useListPromptVisualisations} from '@agent-assist/api-typescript-react-query-hooks';
import {useCallback, useEffect, useState} from 'react';

import {AIInsightsActionBar} from './AIInsightsActionBar';
import {DEFAULT_VISUALISATIONS} from './constants';
import {GraphWithHook} from './GraphWithHook';
import {AnalyticHookProps, ModalMode, Visualisation} from './types';
import {useManageAIInsightVisualisationModal} from '../../hooks/modal/manage-visualisations-modal';
import {DEFAULT_PROMPT_ID} from '../../lib/constants';

import './AIInsightVisualisations.css';
import {LoadingSpinner} from '../LoadingSpinner';

interface Props {
  commonProps: AnalyticHookProps;
}

export const AIInsightVisualisation = ({commonProps}: Props) => {
  const [promptVisualisations, setPromptVisualisations] = useState(
    DEFAULT_VISUALISATIONS,
  );
  const [isEditMode, setEditMode] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const {data, isLoading, isFetched, refetch} = useListPromptVisualisations({
    promptRevisionId: commonProps.promptRevisionId,
  });

  const disableCreateVisualisation =
    commonProps.promptRevisionId === DEFAULT_PROMPT_ID;
  const [currentInsight, setCurrentInsight] = useState<Visualisation>({
    title: '',
    questionId: '',
    questionType: 'extraction_binary',
    tooltip: '',
    visualisationType: 'Aggregate',
  });
  const [modalMode, setModalMode] = useState<ModalMode>('CREATE');
  useEffect(() => {
    setEditMode(false);
    setPromptVisualisations(DEFAULT_VISUALISATIONS);
  }, [commonProps.contactImportId]);

  const updateVisualisations = useCallback(() => {
    if (data && data.visualisations) {
      const customVisualisations = [...data.visualisations];
      customVisualisations.sort((a, b) => a.title.localeCompare(b.title));
      setPromptVisualisations([
        ...DEFAULT_VISUALISATIONS,
        ...customVisualisations,
      ]);
      setDisableEdit(customVisualisations.length === 0);
    } else {
      setPromptVisualisations(DEFAULT_VISUALISATIONS);
      setDisableEdit(true);
    }
  }, [data]);

  useEffect(() => {
    if (isFetched) {
      updateVisualisations();
    }
  }, [isFetched, updateVisualisations]);

  const handleRefetch = useCallback(async () => {
    await refetch();
    updateVisualisations();
  }, [refetch, updateVisualisations]);

  const createAIInsight = useManageAIInsightVisualisationModal({
    promptRevisionId: commonProps.promptRevisionId,
    insight: currentInsight,
    modalMode,
    promptVisualisations,
    refetch: handleRefetch,
  });

  const handleModal = useCallback(
    (newModalMode: ModalMode) => {
      createAIInsight.show();
      setModalMode(newModalMode);
    },
    [setModalMode, createAIInsight],
  );

  const handleEditList = useCallback(() => {
    setEditMode(!isEditMode);
  }, [isEditMode]);

  return (
    <div className="w-full">
      {createAIInsight.modal}
      <AIInsightsActionBar
        disableEdit={disableEdit}
        isDefaultPrompt={disableCreateVisualisation}
        isEditMode={isEditMode}
        handleEdit={handleEditList}
        handleModal={handleModal}
        setCurrentInsight={setCurrentInsight}
      />
      {isLoading ? (
        <div
          className={
            'flex items-center justify-center absolute w-full p-2 z-50'
          }
        >
          <LoadingSpinner size={'sm'} />
        </div>
      ) : (
        <div className="ai-insight-visualisations grid gap-4">
          {promptVisualisations.map((insight) => (
            <GraphWithHook
              key={insight.questionId}
              insight={insight}
              colourType={insight.colourType}
              isEditMode={isEditMode}
              refetch={handleRefetch}
              handleModal={handleModal}
              setCurrentInsight={setCurrentInsight}
              {...commonProps}
            />
          ))}
        </div>
      )}
    </div>
  );
};
