/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {PromptGroupType} from '@agent-assist/api-typescript-react-query-hooks';
import {Plus} from 'lucide-react';
import React, {useCallback, useMemo, useRef} from 'react';

import {QuestionListItem} from './QuestionListItem';
import {
  usePromptDispatch,
  updatePromptGroupQuestion,
  deletePromptGroupQuestion,
  addPromptGroupQuestion,
  type PromptQuestionUnique,
  addPromptGroupChildQuestion,
  updatePromptGroupQuestions,
  UpdatedPromptQuestion,
} from '../../providers/PromptProvider';
import {DraggableList, sortItemsFromId} from '../DraggableList/DraggableList';
import {EmptyResult} from '../EmptyResult';
import {Label} from '../ui/label';
import {LegacyButton} from '../ui/legacy-button';

import './QuestionListGridContainer.css';

export const MAX_QUESTIONS = 40;

export interface QuestionListProps {
  isValid: boolean;
  promptGroupName: string;
  prompts: PromptQuestionUnique[];
  promptGroupType: PromptGroupType;
}
export const QuestionList = ({
  isValid,
  promptGroupName,
  prompts,
  promptGroupType,
}: QuestionListProps) => {
  const promptsContainerRef = useRef<HTMLDivElement>(null);
  const isSynopsisSection = promptGroupType === 'synopsis';
  const dispatch = usePromptDispatch();

  const questionCount = prompts.length;
  const canAddQuestion = useMemo(() => {
    // if (questionCount === 0) return true; // TODO adding new group.
    if (questionCount >= MAX_QUESTIONS) {
      return false;
    }
    if (!isValid) {
      return false;
    }
    return true;
  }, [questionCount, isValid]);

  const scrollToItem = (index?: number) => {
    setTimeout(() => {
      if (promptsContainerRef.current) {
        if (index !== undefined) {
          const item = promptsContainerRef.current.children[index];
          if (item) {
            item.scrollIntoView({behavior: 'smooth', block: 'end'});
          }
        } else {
          promptsContainerRef.current.scrollTop =
            promptsContainerRef.current.scrollHeight;
        }
      }
    }, 50);
  };

  const updatePrompt = React.useCallback(
    (index: number, updatedPrompt: UpdatedPromptQuestion) =>
      updatePromptGroupQuestion(
        dispatch,
        index,
        updatedPrompt,
        promptGroupName,
      ),
    [updatePromptGroupQuestion, dispatch],
  );
  const deleteQuestion = useCallback(
    (index: number) =>
      deletePromptGroupQuestion(dispatch, index, promptGroupName),
    [deletePromptGroupQuestion, dispatch],
  );
  const addQuestion = useCallback(() => {
    addPromptGroupQuestion(dispatch, promptGroupName, {
      key: '',
      id: '',
      question: '',
      promptType: 'extraction_binary',
      properties: {
        weight: 0,
        autoFail: false,
        conditionalQA: false,
        positiveResult: true,
        isChild: false,
        isParent: false,
      },
    });
    scrollToItem(); // Scroll to the bottom after adding the question
  }, [addPromptGroupQuestion, dispatch]);

  const orderUpdated = useCallback(
    (sortedIds: string[]) => {
      const sortedPrompts = sortItemsFromId(sortedIds, prompts);
      updatePromptGroupQuestions(dispatch, sortedPrompts, promptGroupName);
    },
    [prompts, dispatch],
  );

  const addChildQuestion = useCallback(
    (index: number, questionKey: string) => {
      // add child question if there is no other sub question
      const hasChild = prompts.filter(
        (p) => p.key === questionKey && p.properties.isChild === true,
      );
      if (hasChild.length === 0) {
        addPromptGroupChildQuestion(dispatch, promptGroupName, index, {
          key: questionKey,
          id: '',
          question: '',
          promptType: 'extraction_binary',
          properties: {
            weight: 0,
            autoFail: false,
            conditionalQA: false,
            positiveResult: true,
            isChild: true,
            isParent: false,
          },
        });
        scrollToItem(index);
      }
    },
    [addPromptGroupChildQuestion, dispatch],
  );

  const methods = {
    addChildQuestion,
    deleteQuestion,
    updatePrompt: (i: number, payload: UpdatedPromptQuestion) => {
      updatePrompt(i, {...prompts[i], ...payload});
    },
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="question-list-grid-container">
        <div id="sub-header" className="grid gap-3 items-start mr-1">
          <div className="flex flex-col">
            <Label>Title</Label>
            <p className="text-muted-foreground text-xs">
              Title of the prompt section
            </p>
          </div>
          <div className="flex flex-col column-prompt-insights">
            <Label>Insights</Label>
            <p className="text-muted-foreground text-xs">
              Response to the prompt
            </p>
          </div>
          <div className="flex flex-col column-prompt-options">
            <Label>Options</Label>
            <p className="text-muted-foreground text-xs"></p>
          </div>
          <div
            className={`flex flex-col column-prompt-delete ${isSynopsisSection ? 'ml-4' : ''}`}
          >
            <Label>Delete</Label>
            <p className="text-muted-foreground text-xs" />
          </div>
        </div>
      </div>

      <div id="form-fields" ref={promptsContainerRef}>
        {prompts.length > 0 ? (
          <DraggableList
            onOrderUpdated={orderUpdated}
            disabled={true}
            items={prompts.map((question, index) => {
              return {
                id: question.id,
                content: (
                  <QuestionListItem
                    question={question}
                    index={index}
                    key={question.id}
                    methods={methods}
                    promptGroupName={promptGroupName}
                    isSynopsisSection={isSynopsisSection}
                  />
                ),
                isParent: question.properties.isParent,
                childId: prompts.find(
                  (prompt) =>
                    prompt.key === question.key && prompt.properties.isChild,
                )?.id,
              };
            })}
          />
        ) : (
          <EmptyResult text="No prompt questions to display" />
        )}
      </div>

      <LegacyButton
        disabled={!canAddQuestion}
        variant={'outline'}
        className={'w-36 gap-x-1 self-center my-5'}
        size={'sm'}
        onClick={() => addQuestion()}
        aria-label={`add question to ${promptGroupName}`}
      >
        <Plus size={18} />
        <p>Add Question</p>
      </LegacyButton>
    </div>
  );
};
