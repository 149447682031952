/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Edit, Plus, X} from 'lucide-react';
import {ModalMode, Visualisation} from './types';
import {LegacyButton} from '../ui/legacy-button';

interface Props {
  disableEdit: boolean;
  isEditMode: boolean;
  isDefaultPrompt: boolean;
  handleEdit: () => void;
  handleModal: (action: ModalMode) => void;
  setCurrentInsight: React.Dispatch<React.SetStateAction<Visualisation>>;
}

export const AIInsightsActionBar = ({
  disableEdit,
  isDefaultPrompt,
  isEditMode,
  handleEdit,
  handleModal,
  setCurrentInsight,
}: Props) => {
  return (
    <div className="flex justify-end gap-y-5">
      <LegacyButton
        data-testid="create-ai-visualisation"
        variant={'ghost'}
        size="sm"
        onClick={() => {
          handleModal('CREATE');
          setCurrentInsight({
            title: '',
            questionId: '',
            questionType: 'extraction_binary',
            tooltip: '',
            visualisationType: 'Aggregate',
          });
        }}
        disabled={isEditMode || isDefaultPrompt}
      >
        <Plus />
      </LegacyButton>
      {isEditMode ? (
        <LegacyButton
          data-testid="cancel-edit-ai-visualisations"
          variant={'ghost'}
          size="sm"
          onClick={handleEdit}
        >
          <X />
        </LegacyButton>
      ) : (
        <LegacyButton
          disabled={disableEdit}
          data-testid="edit-ai-visualisations"
          variant={'ghost'}
          size="sm"
          onClick={handleEdit}
        >
          <Edit />
        </LegacyButton>
      )}
    </div>
  );
};
