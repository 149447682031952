/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {GridPaginationModel} from '@mui/x-data-grid/models/gridPaginationProps';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {TrusstGPTButton} from '../../components/MaterialUI/Button';
import {StyledLoader} from '../../components/MaterialUI/StyledLoader';
import {EVENT_NAMES} from '../../components/TrusstedAgent/ChatAgent/ChatTrusstedAgent';
import {AgentsGridTable} from '../../components/TrusstedAgent/ListAgents/agentsTable';
import {Agent} from '../../components/TrusstedAgent/Types/types';
import {PageContainer} from '../../components/ui/page';
import {
  APIParams,
  METHODS,
  useTrusstedAgent,
} from '../../hooks/trusstedAgent/useTrusstedAgent';
import {RuntimeConfigContext} from '../../providers/RuntimeContextProvider';
import {
  setAgent,
  useTrusstedAgentDispatch,
} from '../../providers/TrusstedAgentProvider';

const ListAgents = () => {
  const dispatch = useTrusstedAgentDispatch();
  const navigate = useNavigate();
  const [listAgentsLoading, setListAgentsLoading] = useState(false);
  const [publishingAgent, setPublishingAgent] = useState(false);
  const [deletingAgent, setDeletingAgent] = useState(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [nextToken, setNextToken] = useState<{agentId: string} | undefined>();
  const [totalRows, setTotalRows] = useState<number>();
  const [publishedAgent, setPublishedAgent] = useState<Agent | undefined>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [actionTrigger, setActionTrigger] = useState(0);
  const runtimeContext = useContext(RuntimeConfigContext);
  const apiKey = runtimeContext?.trusstedAgentApiKey;
  const apiEndpoint = runtimeContext?.trusstedAgentApiEndpoint;
  const trusstedAgentAPIHook = (params: APIParams) =>
    useTrusstedAgent({
      apiEndpoint: apiEndpoint,
      apiKey: apiKey,
      ...params,
    });

  const rowCountRef = useRef(totalRows || 0);
  const rowCount = useMemo(() => {
    if (totalRows !== undefined) {
      rowCountRef.current = totalRows;
    }
    return rowCountRef.current;
  }, [totalRows]);

  const getPublishedAgent = async () => {
    const result = await trusstedAgentAPIHook({
      eventName: EVENT_NAMES.GetPublishedAgent,
      methodName: METHODS.GET,
      params: {},
      queryParams: {},
    });
    setPublishedAgent(result);
  };
  useEffect(() => {
    void getPublishedAgent();
  }, []);

  const publishAgent = async (agentId: string) => {
    // call publish agent
    setPublishingAgent(true);
    try {
      await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.PublishAgent,
        methodName: METHODS.POST,
        params: {agentId},
        queryParams: {},
      });
      setNextToken(undefined);
      setActionTrigger((prev) => prev + 1);
    } catch (error) {
      console.error('Error publishing the agent', error);
    } finally {
      setPublishingAgent(false);
    }
  };

  const deleteAgent = async (agentId: string) => {
    setDeletingAgent(true);
    try {
      await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.DeleteAgent,
        methodName: METHODS.POST,
        params: {agentId},
        queryParams: {},
      });
      setNextToken(undefined);
      setActionTrigger((prev) => prev + 1);
    } catch (error) {
      console.error('Error deleting the agent', error);
    } finally {
      setDeletingAgent(false);
    }
  };

  const cloneAgent = async (agentId: string) => {
    try {
      await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.CloneAgent,
        methodName: METHODS.POST,
        params: {agentId},
        queryParams: {},
      });
      setNextToken(undefined);
      setActionTrigger((prev) => prev + 1);
    } catch (error) {
      console.error('Error cloning the agent', error);
    }
  };

  const listAgents = async () => {
    setListAgentsLoading(true);
    try {
      const queryParams: any = {
        next_token: nextToken?.agentId,
        page_size: paginationModel.pageSize,
        page: paginationModel.page,
      };
      const result = await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.ListAgents,
        methodName: METHODS.GET,
        params: {},
        queryParams,
      });
      setAgents(result.agents);
      setTotalRows(result.totalCount);
      // if totalcount is equal to currentTotalRows, set nextToken to undefined
      const totalFetchedRows =
        paginationModel.pageSize * (paginationModel.page + 1);
      if (result.totalCount === totalFetchedRows) {
        setNextToken(undefined);
      } else {
        setNextToken(result.nextToken);
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
    } finally {
      setListAgentsLoading(false);
    }
  };
  useEffect(() => {
    void listAgents();
  }, [paginationModel, actionTrigger]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel,
  ) => {
    if (newPaginationModel.pageSize !== paginationModel.pageSize) {
      setNextToken(undefined);
    }
    setPaginationModel(newPaginationModel);
  };

  const actions = (
    <>
      <TrusstGPTButton
        variant="outlined"
        color={'primary'}
        disabled={!publishedAgent}
        buttonText="View Published Agent"
        onClick={() => navigate(`/agents/edit/${publishedAgent?.agentId}`)}
      />
      <TrusstGPTButton
        variant="contained"
        color={'primary'}
        buttonText="New Agent"
        onClick={() => {
          setAgent(dispatch, undefined);
          navigate(`/agents/create`);
        }}
      />
    </>
  );

  return (
    <PageContainer title="Agents" actions={actions}>
      {(deletingAgent || publishingAgent) && <StyledLoader />}
      <AgentsGridTable
        agents={agents}
        listAgentsLoading={listAgentsLoading}
        paginationModel={paginationModel}
        setPaginationModel={handlePaginationModelChange}
        rowCount={rowCount}
        handleDeleteAgent={(agentId: string) => void deleteAgent(agentId)}
        handlePublishAgent={(agentId: string) => void publishAgent(agentId)}
        handleCloneAgent={(agentId: string) => void cloneAgent(agentId)}
      />
    </PageContainer>
  );
};
export default ListAgents;
