/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  useCreatePromptRevision,
  useGetPromptRevision,
  useGetPublishedPromptRevision,
  useUpdatePromptRevision,
} from '@agent-assist/api-typescript-react-query-hooks';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {PromptForm} from '../../../components/QualityAssurance/PromptForm';
import {LegacyButton} from '../../../components/ui/legacy-button';
import {PageContainer} from '../../../components/ui/page';
import {usePromptCreateModal} from '../../../hooks/modal/prompt-create-modal';
import {shortenUuid} from '../../../lib/formatString';
import {
  setPromptEditMode,
  setPromptRevision,
  setUpdatedPromptRevision,
  usePromptDispatch,
  usePromptState,
} from '../../../providers/PromptProvider';

export const CreateQAPrompts = () => {
  const {updatedPromptRevision, isUpdatedPromptRevisionGroupsValid} =
    usePromptState();
  const dispatch = usePromptDispatch();
  const [totalQuestions, setTotalQuestions] = useState<number>();
  const navigate = useNavigate();
  const [promptName, setPromptName] = useState<string>(
    updatedPromptRevision.name,
  );
  const sections = Object.keys(updatedPromptRevision.promptGroups);
  const createQAPrompt = useCreatePromptRevision();
  const updatePrompt = useUpdatePromptRevision();
  const {promptRevisionId} = useParams<{promptRevisionId: string}>();
  const publishPrompt = useGetPublishedPromptRevision();

  const promptRevision = useGetPromptRevision(
    {promptRevisionId: promptRevisionId!},
    {enabled: Boolean(promptRevisionId)},
  );

  const [isReady, setIsReady] = useState(promptRevisionId ? false : true);

  useEffect(() => {
    const totalCount: number = Object.values(
      updatedPromptRevision.promptGroups,
    ).reduce((previousValue, currentValue) => {
      return previousValue + currentValue.questions.length;
    }, 0);
    setTotalQuestions(totalCount);
  }, [updatedPromptRevision]);

  useEffect(() => {
    if (
      promptRevision &&
      promptRevision.data &&
      promptRevision?.data &&
      promptRevisionId === promptRevision.data?.promptRevisionId
    ) {
      setPromptEditMode(dispatch, true);
      setPromptRevision(dispatch, promptRevision.data);
      setUpdatedPromptRevision(dispatch, promptRevision.data);
      setIsReady(true);
      // without this if condition, the create prompt test fails
      if (promptRevision.data.name) setPromptName(promptRevision.data.name);
    }
  }, [promptRevisionId, promptRevision?.data?.promptRevisionId]);

  const onSave = useCallback(async () => {
    const payload = {
      ...updatedPromptRevision,
      name: promptName,
    };
    if (promptRevisionId) {
      let updatedPromptDetails;
      try {
        updatedPromptDetails = await updatePrompt.mutateAsync({
          promptRevisionId,
          updatePromptRevisionRequestContent: payload,
        });
      } catch (e) {
        console.log('caught', e);
      }
      if (updatedPromptDetails)
        navigate(`/prompts/${updatedPromptDetails.promptRevisionId}`);
    } else {
      const newPrompt = await createQAPrompt.mutateAsync({
        createPromptRevisionRequestContent: payload,
      });
      navigate(`/prompts/${newPrompt.promptRevisionId}`);
    }
  }, [updatedPromptRevision, navigate, promptName]);

  const publishConfirmation = usePromptCreateModal({
    onConfirm: onSave,
    onChangeName: setPromptName,
    name: promptName,
  });

  if (!isReady) return null;

  const allItemsValid =
    Object.values(isUpdatedPromptRevisionGroupsValid).every(Boolean) &&
    totalQuestions !== 0;
  const disableButtons =
    !allItemsValid || createQAPrompt.isLoading || publishPrompt.isLoading;

  const action = (
    <>
      <LegacyButton
        onClick={() => {
          promptRevisionId
            ? navigate(`/prompts/${promptRevisionId}`)
            : navigate(`/prompts`);
        }}
        variant={'secondary'}
      >
        Cancel
      </LegacyButton>
      <LegacyButton
        disabled={disableButtons}
        onClick={() => {
          publishConfirmation.show();
        }}
        variant={'default'}
      >
        Save
      </LegacyButton>
    </>
  );

  return (
    <PageContainer
      title={
        promptRevisionId
          ? `Editing ${shortenUuid(promptRevisionId)}`
          : 'Create Prompt'
      }
      actions={action}
    >
      {publishConfirmation.modal}
      <PromptForm sections={sections} />
      {/* // POC to add a new group:
        <Button
          onClick={() => {
            const data = { ...updatedPromptRevision };
            const newGroup = { promptGroupType: "qa", questions: [], totalWeight: 0 };
            data.promptGroups = {
              ...data.promptGroups,
              NEWGROUPNAME: newGroup,
            };
            setUpdatedPromptRevision(dispatch, data);
          }}
          variant={"default"}
        >
          Add new Section
        </Button>
      */}
    </PageContainer>
  );
};

export default CreateQAPrompts;
