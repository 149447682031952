/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  Checkbox,
} from '@mui/material';
import {AgentToolInput} from '../Types/types';

interface Props {
  tool?: AgentToolInput;
  onHandleDelete: (updatedTool: AgentToolInput) => void;
}
const ToolParameterTable = ({tool, onHandleDelete}: Props) => {
  const parameters = tool?.parameters.map((p, i) => ({id: i, ...p})) || [];
  const handleDeleteParameter = (id: number) => {
    const updatedParams = tool?.parameters.filter((_p, i) => i !== id);
    const updatedTool = {
      ...tool,
      name: tool?.name || '',
      description: tool?.description || '',
      parameters: updatedParams ?? [],
    };
    onHandleDelete(updatedTool);
  };
  return (
    <Table>
      <TableBody>
        {parameters.map((row) => (
          <TableRow key={row.id}>
            <TableCell style={{width: '30%'}}>
              <Typography variant="body2">{row.name}</Typography>
            </TableCell>
            <TableCell style={{width: '30%'}}>
              <Typography variant="body2">{row.description}</Typography>
            </TableCell>
            <TableCell style={{width: '30%'}}>
              <Typography variant="body2">{row.type}</Typography>
            </TableCell>
            <TableCell style={{width: '10%'}}>
              <Checkbox checked={row.required || false} disabled />
            </TableCell>
            <TableCell style={{width: '10%'}}>
              <IconButton onClick={() => handleDeleteParameter(row.id)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ToolParameterTable;
