/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  AcceptedPromptTypes,
  PromptType,
  PromptVisualisation,
} from '@agent-assist/api-typescript-react-query-hooks';
import {ContactDetailsFilter} from '@agent-assist/api-typescript-react-query-hooks/lib/models/ContactDetailsFilter';

export type ColourTrueFalse = {Yes: string; No: string};

export type ColourType =
  | 'TRUE_NEGATIVE' // use this to display true values as bad, as in undesirable for users/customers/etc
  | 'TRUE_POSITIVE'; // otherwise use this to display true values as good!
export type ModalMode = 'CREATE' | 'DELETE';
export type AnalyticHookProps = {
  contactImportId: string;
  promptRevisionId: string;
  filters?: ContactDetailsFilter[];
};

export interface Visualisation extends PromptVisualisation {
  colourType?: ColourType;
}

// these are actually all AcceptedPromptTypes, being a subset of PromptType
export const acceptedPromptTypes: PromptType[] = [
  'extraction_binary',
  'extraction_single_label',
  'extraction_rating',
];

export interface PromptOptions {
  label: string;
  value: string;
  question: string;
  questionId: string;
  questionType: AcceptedPromptTypes;
  hasTrend: boolean;
  hasAggregate: boolean;
}
