/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {VisualisationType} from '@agent-assist/api-typescript-react-query-hooks/lib/models/VisualisationType';
import {Checkbox} from '@mui/material';
import {useEffect} from 'react';
import {acceptedPromptTypes} from './types';
import {PromptQuestionUnique} from '../../providers/PromptProvider';
import {PromptQuestionItem} from '../QualityAssurance/QuestionListItem';
import {Label} from '../ui/label';

interface VisualisationProps {
  question: PromptQuestionUnique;
  index: number;
  updatePrompt: (index: number, updatedPromptItem: PromptQuestionItem) => void;
}

export const PromptVisualisationConfig = ({
  question,
  index,
  updatePrompt,
}: VisualisationProps) => {
  const canHaveVisualisations = acceptedPromptTypes.includes(
    question.promptType,
  );
  const visualisationTypes: VisualisationType[] = ['Trend', 'Aggregate'];

  useEffect(() => {
    if (!canHaveVisualisations) {
      // only wipe the array if it was populated:
      if (question.properties.promptVisualisations?.length) {
        updatePrompt(index, {
          properties: {
            ...question.properties,
            promptVisualisations: [],
          },
        });
      }
    }
  }, [canHaveVisualisations, question, index, updatePrompt]);

  const handleVisualisationTypeChange = (
    type: VisualisationType,
    checked: boolean,
  ) => {
    // console.log(type, checked);
    const currentTypes = question.properties.promptVisualisations || [];
    const updatedTypes = currentTypes.map((vis) =>
      vis.visualisationType === type ? {...vis, enabled: checked} : vis,
    );
    if (!currentTypes.some((vis) => vis.visualisationType === type)) {
      updatedTypes.push({enabled: checked, visualisationType: type});
    }
    updatePrompt(index, {
      properties: {
        ...question.properties,
        promptVisualisations: updatedTypes,
      },
    });
  };

  return (
    <>
      <div className="flex mt-3">
        <Label className="text-left">Visualisation Type</Label>
      </div>

      <div>
        {visualisationTypes.map((type) => {
          const visualisation = question.properties.promptVisualisations?.find(
            (vis) => vis.visualisationType === type,
          );
          return (
            <div
              key={`${question.id}-${type}`}
              className="flex items-center space-x-2"
            >
              <Checkbox
                disabled={!canHaveVisualisations}
                id={`${type.toLowerCase()}-${index}`}
                checked={visualisation?.enabled ?? false}
                onChange={(event) =>
                  handleVisualisationTypeChange(type, event.target.checked)
                }
              />
              <Label htmlFor={`${question.id}-${type}-${index}`}>{type}</Label>
            </div>
          );
        })}
      </div>
    </>
  );
};
