/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {LicenseInfo as MUILicense} from '@mui/x-license/utils/licenseInfo';
import * as React from 'react';

import {useSettings} from '../hooks/use-settings';
import {createTheme} from '../styles/theme/create-theme';

MUILicense.setLicenseKey(
  'd4d0215f69c4f6e93830a6d77fbd2da4Tz05ODA3NCxFPTE3NTc4MjYyNTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);

interface ThemeProviderProps {
  children: React.ReactNode;
}

function CustomThemeProvider({
  children,
}: ThemeProviderProps): React.JSX.Element {
  const {settings} = useSettings();

  const theme = createTheme({
    primaryColor: settings.primaryColor,
  });

  return (
    <ThemeProvider disableTransitionOnChange theme={theme} defaultMode="dark">
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export {CustomThemeProvider as ThemeProvider};
