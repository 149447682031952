export const wordToTitleCase = (word: string) =>
  word && word.length > 0
    ? `${word[0].toUpperCase()}${word.substring(1)}`
    : word;

// renders a short uuid version of any string which contains a uuid, useful for displaying on screen whilst preserving urls etc.
const uuidRegex =
  /\b([0-9a-f]{8})-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/gi;
export const shortenUuid = (str: string): string =>
  str.replace(uuidRegex, (_match, s) => s);
