/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {SynopsisEvaluationRating} from '@agent-assist/api-typescript-react-query-hooks';
import {ThumbsDown, ThumbsUp} from 'lucide-react';
import {FC} from 'react';

interface ContactRatingIndicatorProps {
  rating?: SynopsisEvaluationRating;
}

export const ContactRatingIndicator: FC<ContactRatingIndicatorProps> = ({
  rating,
}) => {
  switch (rating) {
    case 'GOOD':
      return <ThumbsUp className={'text-green-500'} />;
    case 'BAD':
      return <ThumbsDown className={'text-red-500'} />;
    default:
      return null;
  }
};
