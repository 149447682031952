/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {PieChart, Pie, Cell} from 'recharts';

import {
  AnalyticsGraphCard,
  type AnalyticsGraphCardProps,
} from './AnalyticsGraphCard';
import {renderPieChartLabel} from './renderPieChartLabel';
import {ColourTrueFalse, ColourType} from './types';

const COLOUR_BAD = `hsl(0 45% 50%)`, // red
  COLOUR_GOOD = `hsl(120 45% 50%)`; // green

const COLOURS_DONUT = new Map<ColourType, ColourTrueFalse>([
  ['TRUE_NEGATIVE', {Yes: COLOUR_BAD, No: COLOUR_GOOD}],
  ['TRUE_POSITIVE', {Yes: COLOUR_GOOD, No: COLOUR_BAD}],
]);

interface DonutChartProps extends AnalyticsGraphCardProps {
  data: {name: string; value: number}[];
  colourType?: ColourType;
}

const DonutChart: React.FC<DonutChartProps> = ({
  colourType,
  data: rawData,
  ...props
}) => {
  // this component is now custom built for data of array.length 2, with one item having name of 'false' and the other 'true'
  const data = rawData
    // remove invalid props, eg. `{name: undefined, value: 0.77}`
    .filter((item) => ['false', 'true'].includes(item.name))
    .map((item) => {
      return {
        name: item.name === 'true' ? 'Yes' : 'No',
        value: item.value,
      };
    });

  const colours = colourType
    ? COLOURS_DONUT.get(colourType)
    : COLOURS_DONUT.get('TRUE_POSITIVE');
  if (!colours)
    throw new Error(`could not find colours for colourType: ${colourType}`);

  return (
    <AnalyticsGraphCard {...props} minHeight="min-h-[200px]">
      <div data-testid={`donut-graph-${props.title}`}>
        <PieChart height={160} width={300}>
          <Pie
            data={data}
            dataKey={'value'}
            innerRadius={25}
            outerRadius={35}
            cx={'50%'}
            cy={'50%'}
            paddingAngle={data.length > 1 ? 5 : 0}
            label={renderPieChartLabel}
            minAngle={5}
            // setting startAngle + endAngle renders the split between the true/false sectors at the top centre of the donut:
            startAngle={-270}
            endAngle={90}
          >
            {data.map((d, index) => (
              <Cell
                key={index}
                fill={colours[d.name as 'Yes' | 'No']}
                stroke="none"
              />
            ))}
          </Pie>
        </PieChart>
      </div>
    </AnalyticsGraphCard>
  );
};

export default DonutChart;
