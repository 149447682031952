/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import type {Components} from '@mui/material/styles';

import type {Theme} from '../types';

export const MuiIconButton = {
  defaultProps: {color: 'secondary', disableRipple: true},
  styleOverrides: {
    root: {
      borderRadius: '8px',
      height: 'var(--IconButton-size)',
      width: 'var(--IconButton-size)',
      '&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.04)'},
      '&:focus-visible': {outline: '2px solid var(--mui-palette-primary-main)'},
    },
    colorPrimary: {
      color: 'var(--mui-palette-primary-main)',
      '&:hover': {backgroundColor: 'var(--mui-palette-primary-hovered)'},
      '&:active': {backgroundColor: 'var(--mui-palette-primary-activated)'},
    },
    colorSecondary: {
      color: 'var(--mui-palette-secondary-main)',
      '&:hover': {backgroundColor: 'var(--mui-palette-secondary-hovered)'},
      '&:active': {backgroundColor: 'var(--mui-palette-secondary-activated)'},
    },
    colorInfo: {
      color: 'var(--mui-palette-info-main)',
      '&:hover': {backgroundColor: 'var(--mui-palette-info-hovered)'},
      '&:active': {backgroundColor: 'var(--mui-palette-info-activated)'},
    },
    colorSuccess: {
      color: 'var(--mui-palette-success-main)',
      '&:hover': {backgroundColor: 'var(--mui-palette-success-hovered)'},
      '&:active': {backgroundColor: 'var(--mui-palette-success-activated)'},
    },
    colorWarning: {
      color: 'var(--mui-palette-warning-main)',
      '&:hover': {backgroundColor: 'var(--mui-palette-warning-hovered)'},
      '&:active': {backgroundColor: 'var(--mui-palette-warning-activated)'},
    },
    colorError: {
      color: 'var(--mui-palette-error-main)',
      '&:hover': {backgroundColor: 'var(--mui-palette-error-hovered)'},
      '&:active': {backgroundColor: 'var(--mui-palette-error-activated)'},
    },
    sizeSmall: {'--IconButton-size': '32px'},
    sizeMedium: {'--IconButton-size': '40px'},
    sizeLarge: {'--IconButton-size': '48px'},
  },
} satisfies Components<Theme>['MuiIconButton'];
