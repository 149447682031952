/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {createTheme} from '@mui/material/styles';

import {colorSchemes} from './color-schemes';
import {components} from './components/components';
import {shadows} from './shadows';
import type {PrimaryColor, Theme} from './types';
import {typography} from './typography';

interface Config {
  primaryColor: PrimaryColor;
}

function customCreateTheme(config: Config): Theme {
  return createTheme({
    breakpoints: {values: {xs: 0, sm: 600, md: 900, lg: 1200, xl: 1440}},
    colorSchemes: colorSchemes({primaryColor: config.primaryColor}),
    components,
    cssVariables: {
      colorSchemeSelector: 'class',
    },
    shadows,
    shape: {borderRadius: 8},
    typography,
  });
}

export {customCreateTheme as createTheme};
