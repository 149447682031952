/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {isEqual} from 'lodash';
import {AgentToolSettingsForm} from './ToolsSettings';
import {
  defaultTool,
  updateAgentTool,
  useTrusstedAgentDispatch,
  useTrusstedAgentState,
} from '../../../providers/TrusstedAgentProvider';
import {TrusstGPTButton} from '../../MaterialUI/Button';
import {TabItem, TrusstGPTTabs} from '../../MaterialUI/Tabs';

import {CommonActionBar} from '../ActionBars/CommonActions';
import {AgentToolInput} from '../Types/types';
interface Props {
  updateAgent: () => Promise<void>;
  updatingAgent: boolean;
  discardChanges: () => void;
  toolIndex: number;
}

const ViewTool = ({
  updateAgent,
  updatingAgent,
  discardChanges,
  toolIndex,
}: Props) => {
  const {modifiedAgent, originalAgent} = useTrusstedAgentState();
  const currentTool = modifiedAgent?.tools?.[toolIndex] || defaultTool;
  const dispatch = useTrusstedAgentDispatch();

  const onUpdateTool = (updatedTool: AgentToolInput) => {
    updateAgentTool(dispatch, toolIndex, updatedTool);
  };
  const tabs: TabItem[] = [
    {
      label: 'Settings',
      value: '1',
      content: (
        <AgentToolSettingsForm
          toolIndex={toolIndex}
          onUpdateTool={onUpdateTool}
        />
      ),
    },
    {
      label: 'Authentication',
      value: '2',
      content: <div>To be implemented</div>,
    },
    {label: 'Method & Body', value: '3', content: <div>To be implemented</div>},
    {
      label: 'Response & Test',
      value: '4',
      content: <div>To be implemented</div>,
    },
  ];

  const disableSave =
    updatingAgent ||
    currentTool?.name === '' ||
    currentTool?.description === '';
  const nochangesIdentified = isEqual(originalAgent, modifiedAgent);

  return (
    <div>
      <TrusstGPTTabs tabs={tabs} />
      <CommonActionBar
        onSave={() => void updateAgent()}
        onDiscard={discardChanges}
        saveDisabled={disableSave}
        discardDisabled={nochangesIdentified}
      >
        <TrusstGPTButton
          variant="contained"
          disabled={false}
          onClick={() => console.log('Test Action has not been implemented.')}
          size="small"
          buttonText={'Test Action'}
        />
      </CommonActionBar>
    </div>
  );
};
export default ViewTool;
