/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ContactSummary} from './ContactSummary';
import {AgentContact, ContactSummaryMethods} from './ListAgentContacts';

export interface ContactSummaryPropsBase extends ContactSummaryMethods {
  isExpanded: boolean;
}

interface ContactCardProps extends ContactSummaryPropsBase {
  contact: Partial<AgentContact>;
  isEmptyMessage?: string;
}

export const ContactCard = ({
  isExpanded,
  contact,
  onSelectContact,
  onDeleteContact,
  refetch,
}: ContactCardProps) => {
  // add on click card that shows the contacts summary chat
  return (
    <div
      className="grid"
      style={{gridTemplateColumns: isExpanded ? '1fr 40px' : '1fr'}}
    >
      <ContactSummary
        onDeleteContact={onDeleteContact}
        isExpanded={isExpanded}
        contact={contact}
        refetch={refetch}
        onSelectContact={onSelectContact}
      />
    </div>
  );
};
