/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  DataDialogPrompt,
  useUpdateDataDialogPrompt,
} from '@agent-assist/api-typescript-react-query-hooks';
import {FolderOpen, FolderX, Trash2} from 'lucide-react';
import {Dispatch, ReactNode, SetStateAction, useEffect, useState} from 'react';

import {LoadingSpinner} from '../../components/LoadingSpinner';
import {LegacyButton} from '../../components/ui/legacy-button';
import {useActionConfirmation} from '../../hooks/modal/action-confirmation';
import {cn} from '../../lib/utils';

interface PromptSummaryMethods {
  onSelectPrompt(str: string): void;
  refetch(): Promise<void>;
}
interface PromptSummaryPropsBase extends PromptSummaryMethods {
  isExpanded: boolean;
  isOwner: boolean;
}

const ConfirmDelete = ({
  promptToDelete,
  clearPromptToDelete,
  refetch,
}: {
  promptToDelete?: {
    promptText: string;
    dataDialogPromptId: string;
  };
  clearPromptToDelete: Dispatch<SetStateAction<DataDialogPrompt | undefined>>;
  refetch: () => Promise<void>;
}) => {
  // console.log("ConfirmDelete", promptToDelete);

  const update = useUpdateDataDialogPrompt();

  const onConfirm = async () => {
    if (!promptToDelete) return;
    const {dataDialogPromptId, promptText} = promptToDelete;
    await update.mutateAsync({
      dataDialogPromptId,
      updateDataDialogPromptRequestContent: {
        promptText, // we may want to let users re-word an existing prompt... this param is currently ignored in the API!
        promptStatus: 'deleted',
      },
    });
    clearPromptToDelete(undefined);
    await refetch();
  };

  const onCancel = () => clearPromptToDelete(undefined);

  const deleteConfirmation = useActionConfirmation({
    prompt: `Are you sure you want to delete this prompt: '${promptToDelete?.promptText}'`,
    confirmationButtonText: 'Delete',
    onConfirm,
    onCancel,
  });

  useEffect(() => {
    if (promptToDelete) {
      deleteConfirmation.show();
    } else {
      deleteConfirmation.hide();
    }
  }, [promptToDelete]);

  return <div>{deleteConfirmation.modal}</div>;
};

interface PromptSummaryProps extends PromptSummaryPropsBase {
  dataDialogPrompt: DataDialogPrompt;
  onDeletePrompt(): void;
}

const PromptSummary = ({
  dataDialogPrompt,
  isExpanded,
  isOwner,
  onSelectPrompt,
  onDeletePrompt,
}: PromptSummaryProps) => {
  const {promptText} = dataDialogPrompt;

  const btnClass = 'rounded-sm hover:bg-accent text-left px-2 py-0';

  if (isExpanded && isOwner) {
    return (
      <>
        <button
          className={cn(btnClass)}
          onClick={() => onSelectPrompt(promptText)}
        >
          {promptText}
        </button>
        <LegacyButton size="icon" variant="ghost" onClick={onDeletePrompt}>
          <Trash2 size={16} />
        </LegacyButton>
      </>
    );
    // coming soon: ability to like/dislike prompts.
  }

  return (
    <>
      <button
        className={cn(btnClass, [!isExpanded && 'truncate'])}
        onClick={() => onSelectPrompt(promptText)}
      >
        {promptText}
      </button>
    </>
  );
};

interface PromptSetProps extends PromptSummaryPropsBase {
  emptyMessage: string;
  prompts: DataDialogPrompt[];
  title: string;
}

const PromptSet = ({
  isExpanded,
  isOwner,
  prompts,
  emptyMessage,
  title,
  onSelectPrompt,
  refetch,
}: PromptSetProps) => {
  const [promptToDelete, setPromptToDelete] = useState<
    DataDialogPrompt | undefined
  >(undefined);

  return (
    <>
      <h2 className="px-2 font-semibold">{title}</h2>

      {promptToDelete && (
        <ConfirmDelete
          refetch={refetch}
          promptToDelete={promptToDelete}
          clearPromptToDelete={setPromptToDelete}
        />
      )}

      <div
        className="grid"
        style={{
          gridTemplateColumns: isExpanded && isOwner ? '1fr 40px' : '1fr',
        }}
      >
        {prompts.length ? (
          prompts.map((ddp, index) => {
            return (
              <PromptSummary
                onDeletePrompt={() => setPromptToDelete(ddp)}
                key={`${ddp.dataDialogPromptId}-${ddp.status}-${index}`}
                isExpanded={isExpanded}
                isOwner={isOwner}
                dataDialogPrompt={ddp}
                refetch={refetch}
                onSelectPrompt={onSelectPrompt}
              />
            );
          })
        ) : (
          <div className="px-2">{emptyMessage}</div>
        )}
      </div>
    </>
  );
};

interface ListPromptsProps extends PromptSummaryMethods {
  isFetching: boolean;
  prompts?: DataDialogPrompt[];
}

type FilteredPrompts = {
  myPrompts: DataDialogPrompt[];
  othersPrompts: DataDialogPrompt[];
};

export const ListPrompts = ({
  isFetching,
  prompts,
  onSelectPrompt,
  refetch,
}: ListPromptsProps) => {
  const [isExpanded, setExpanded] = useState(false);
  const [filteredPrompts, setFilteredPrompts] = useState<FilteredPrompts>({
    myPrompts: [],
    othersPrompts: [],
  });

  useEffect(() => {
    if (!prompts) return;
    const filtered = prompts.reduce(
      (acc, p) => {
        // not showing deleted prompts:
        if (p.status === 'active') {
          if (p.isOwner) {
            acc.myPrompts.push(p);
          } else {
            acc.othersPrompts.push(p);
          }
        }
        return acc;
      },
      {myPrompts: [], othersPrompts: []} as FilteredPrompts,
    );
    setFilteredPrompts(filtered);
  }, [prompts?.map((p) => `${p.dataDialogPromptId}:${p.status}`).join(',')]);

  if (!prompts)
    return (
      <ListPromptsContainer
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        isFetching={true}
      />
    );

  const {myPrompts, othersPrompts} = filteredPrompts;

  return (
    <ListPromptsContainer
      isExpanded={isExpanded}
      setExpanded={setExpanded}
      isFetching={isFetching}
    >
      <div className="border rounded-lg p-2">
        <PromptSet
          title="Mine"
          isExpanded={isExpanded}
          isOwner={true}
          onSelectPrompt={onSelectPrompt}
          prompts={myPrompts}
          refetch={refetch}
          emptyMessage="You haven't saved any prompts yet. If you ask a question that works well for you, hit the save button next to the question"
        />
      </div>
      <div className="border rounded-lg mt-4 p-2">
        <PromptSet
          title="Other's"
          isExpanded={isExpanded}
          isOwner={false}
          onSelectPrompt={onSelectPrompt}
          prompts={othersPrompts}
          refetch={refetch}
          emptyMessage="None of your team mates have saved any prompts yet."
        />
      </div>
    </ListPromptsContainer>
  );
};

interface ListPromptsContainerProps {
  isExpanded: boolean;
  isFetching: boolean;
  children?: ReactNode;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

const ListPromptsContainer = ({
  isExpanded,
  isFetching,
  setExpanded,
  children,
}: ListPromptsContainerProps) => {
  return (
    <div
      className="absolute top-0 right-0 rounded-lg bg-background shadow-md h-full"
      style={{width: isExpanded ? '400px' : '200px'}}
    >
      <div className={cn('h-full', isExpanded ? 'px-4 py-2' : 'p-2')}>
        <div
          className="flex justify-between items-center mb-2"
          style={{height: '40px'}}
        >
          <div className="font-semibold">Team prompts</div>
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            <LegacyButton
              variant={'ghost'}
              onClick={() => setExpanded(!isExpanded)}
            >
              {isExpanded ? <FolderX /> : <FolderOpen />}
            </LegacyButton>
          )}
        </div>
        <div
          className="overflow-y-auto"
          style={{
            height: 'calc(100% - 48px)', // 48px = 40px header + 0.5rem padding
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
