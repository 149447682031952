/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {Box, Card, CardContent} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {Trash2} from 'lucide-react';
import {useCallback} from 'react';
import {DEFAULT_VISUALISATIONS} from './constants';
import {ModalMode, Visualisation} from './types';
import {formatVisualisationTitle} from './utils';
import {cn} from '../../lib/utils';
import {LoadingSpinner} from '../LoadingSpinner';
import {LegacyButton} from '../ui/legacy-button';
import {LegacyCardTitle} from '../ui/legacy-card';

export interface AnalyticsGraphCardProps {
  title: string;
  noDataFound: boolean;
  isLoading?: boolean;
  tooltip?: string;
  isEditMode?: boolean;
  insight?: Visualisation;
  refetch?: () => Promise<void>;
  handleModal?: (action: ModalMode) => void;
  handleExpandModal?: (content: React.ReactNode) => void;
  isExpandedModalOpen?: boolean;
  setCurrentInsight?: React.Dispatch<React.SetStateAction<Visualisation>>;
}

interface AnalyticsGraphContentProps extends AnalyticsGraphCardProps {
  children: React.ReactElement;
  minHeight?: string;
}

const GraphFooter: React.FC<AnalyticsGraphContentProps> = (cardProps) => {
  const {
    isEditMode,
    refetch,
    handleModal,
    handleExpandModal,
    setCurrentInsight,
    children,
    ...props
  } = cardProps;
  const {insight} = props;
  const showDelete =
    isEditMode &&
    !DEFAULT_VISUALISATIONS.map((q) => q.title).includes(props.title);
  const handleActions = useCallback(
    (action: ModalMode) => {
      setCurrentInsight?.(insight!);
      handleModal?.(action);
    },
    [insight],
  );
  const showExpand = handleExpandModal && !isEditMode;
  if (!showDelete && showExpand)
    return (
      <div className="flex justify-end w-full">
        <LegacyButton
          data-testid={`expand-visualisation-${props.title}`}
          variant={'ghost'}
          size="sm"
          onClick={() => handleExpandModal(children)}
        >
          <OpenInFullIcon />
        </LegacyButton>
      </div>
    );

  return (
    <div className="flex justify-end w-full">
      {showDelete && (
        <LegacyButton
          data-testid={`delete-visualisation-${props.title}`}
          variant={'ghost'}
          size="sm"
          onClick={() => handleActions('DELETE')}
        >
          <Trash2 />
        </LegacyButton>
      )}
      {showExpand && (
        <LegacyButton
          data-testid={`expand-visualisation-${props.title}`}
          variant={'ghost'}
          size="sm"
          onClick={() => handleExpandModal(children)}
        >
          <OpenInFullIcon />
        </LegacyButton>
      )}
    </div>
  );
};

export const AnalyticsGraphCard: React.FC<AnalyticsGraphContentProps> = (
  cardProps,
) => {
  return (
    <Card>
      <CardContent>
        <AnalyticsGraphContent {...cardProps} />
        <GraphFooter {...cardProps} />
      </CardContent>
    </Card>
  );
};

const AnalyticsGraphContent: React.FC<AnalyticsGraphContentProps> = ({
  children,
  minHeight,
  noDataFound,
  isLoading,
  title,
  tooltip,
}) => {
  return (
    <div className="text-center w-full">
      <LegacyCardTitle className="flex align-items text-base justify-center p-2 w-full">
        {formatVisualisationTitle(title)}
        {tooltip && (
          <Tooltip title={tooltip}>
            <HelpOutlineIcon sx={{ml: 1, fontSize: 18}} />
          </Tooltip>
        )}
      </LegacyCardTitle>
      <Box className={cn('p-1 flex justify-center', minHeight)}>
        {noDataFound ? (
          <p className="scroll-m-20 text-xs font-semibold tracking-tight my-10 w-full">
            No Data Found. Try a different filter.
          </p>
        ) : isLoading ? (
          <div className={'flex flex-1 items-center justify-center'}>
            <LoadingSpinner size={'sm'} />
          </div>
        ) : (
          children
        )}
      </Box>
    </div>
  );
};
