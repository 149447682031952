/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Button} from '@mui/material';
import {FolderOpen, FolderX} from 'lucide-react';
import {Dispatch, SetStateAction, ReactNode} from 'react';
import {cn} from '../../../lib/utils';
import {LoadingSpinner} from '../../LoadingSpinner';

interface AgentContactsListContainerProps {
  isExpanded: boolean;
  isFetching: boolean;
  children?: ReactNode;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

export const AgentContactsListContainer = ({
  isExpanded,
  isFetching,
  setExpanded,
  children,
}: AgentContactsListContainerProps) => {
  return (
    <div
      className="absolute top-0 right-0 rounded-lg bg-background shadow-md h-full"
      style={{width: isExpanded ? '400px' : '200px'}}
    >
      <div className={cn('h-full', isExpanded ? 'px-4 py-2' : 'p-2')}>
        <div
          className="flex justify-between items-center mb-2"
          style={{height: '40px', marginBottom: '1rem'}}
        >
          <div className="font-semibold">Agent Contacts</div>
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            <Button
              variant={'outlined'}
              onClick={() => setExpanded(!isExpanded)}
            >
              {isExpanded ? <FolderX /> : <FolderOpen />}
            </Button>
          )}
        </div>
        <div
          className="flex flex-col overflow-y-auto gap-y-4"
          style={{
            height: 'calc(100% - 48px)', // 48px = 40px header + 0.5rem padding
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
