/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import type {ContactDetailsFilter} from '@agent-assist/api-typescript-react-query-hooks';

export const cleanseFilters = (
  filters?: ContactDetailsFilter[],
): {filters: ContactDetailsFilter[]; from?: string; to?: string} => {
  if (!filters) {
    return {filters: [], from: undefined, to: undefined};
  }
  const from = filters.find(({key}) => key === 'startTime')?.values?.at(0);
  const to = filters.find(({key}) => key === 'endTime')?.values?.at(0);

  // TODO ^ from/to already exist as suitable params in ContactDetailsFilter as startTime/endTime
  const cleansedFilters = filters.filter(({key}) => {
    return !['startTime', 'endTime'].includes(key);
  });
  // console.log("cleansedFilters", cleansedFilters, { from, to });
  return {filters: cleansedFilters, from, to};
};
