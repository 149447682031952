/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ContactSynopsisElement,
  GetContactResponseContent,
  ResponseError,
  SynopsisEvaluationRating,
  useUpdateContactSummaryEvaluation,
} from '@agent-assist/api-typescript-react-query-hooks';
import {Card, CardContent, Stack} from '@mui/material';
import {QueryObserverSuccessResult} from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import {AlertCircle, Copy, Edit, ThumbsDown, ThumbsUp} from 'lucide-react';
import {FC, useCallback, useMemo, useState} from 'react';
import {SummaryChangesView} from './summary-changes-view';
import {
  SynopsisEvaluationForm,
  SynopsisEvaluationFormData,
} from './synopsis-evaluation-form';
import {cn} from '../../lib/utils';
import CopyButton from '../CopyButton';
import LegacyHeading from '../Heading';
import {LoadingSpinner} from '../LoadingSpinner';
import {Alert, AlertDescription, AlertTitle} from '../ui/alert';
import {Dialog, DialogContent, DialogHeader, DialogTitle} from '../ui/dialog';
import {LegacyButton} from '../ui/legacy-button';

interface ContactSummaryContainerProps {
  readonly contact: QueryObserverSuccessResult<
    GetContactResponseContent,
    ResponseError
  >;
  readonly regenerateIsLoading?: boolean;
}

function convertBooleanToString(
  data: ContactSynopsisElement[],
): ContactSynopsisElement[] {
  let result: ContactSynopsisElement[] = [];
  data.map((item) => {
    if (
      typeof item.response === 'boolean' ||
      typeof item.response === 'number'
    ) {
      item.response = `${item.response}`;
      result.push(item);
    } else {
      result.push(item);
    }
    return result;
  })[0];
  return result;
}
export const ContactSummaryContainer: FC<ContactSummaryContainerProps> = ({
  contact,
  regenerateIsLoading,
}) => {
  const [isEditingSummary, setIsEditingSummary] = useState<boolean>(false);
  const [isViewingChanges, setIsViewingChanges] = useState<boolean>(false);
  const productionSummary = contact.data.summary?.production;
  const [rating, setRating] = useState<SynopsisEvaluationRating | undefined>(
    contact.data.summary?.production?.synopsisEvaluation?.rating,
  );
  const [evaluationSummary, setEvaluationSummary] =
    useState<SynopsisEvaluationFormData>({
      synopsis:
        productionSummary?.synopsisEvaluation?.synopsis ??
        productionSummary?.synopsis ??
        [],
    });

  const hasEdits = useMemo(
    () =>
      productionSummary?.synopsisEvaluation?.synopsis &&
      !isEqual(
        productionSummary?.synopsis,
        productionSummary?.synopsisEvaluation?.synopsis,
      ),
    [
      productionSummary?.synopsis,
      productionSummary?.synopsisEvaluation?.synopsis,
    ],
  );

  const updateEvaluation = useUpdateContactSummaryEvaluation();

  const onSave = useCallback(
    async (newRating?: SynopsisEvaluationRating) => {
      await updateEvaluation.mutateAsync({
        contactId: contact.data.contactId!,
        contactImportId: contact.data.contactImportId,
        updateContactSummaryEvaluationRequestContent: {
          evaluation: {
            synopsis: convertBooleanToString(evaluationSummary.synopsis),
            rating:
              newRating ??
              rating ??
              productionSummary?.synopsisEvaluation?.rating,
          },
        },
      });
      void contact.refetch();
      setIsEditingSummary(false);
    },
    [
      evaluationSummary,
      contact.data.contactId,
      rating,
      productionSummary?.synopsisEvaluation?.rating,
      contact.data.contactImportId,
    ],
  );

  const summaryCopyValue = useMemo(
    () =>
      productionSummary?.synopsis
        ? productionSummary.synopsis
            .map((s) => `${s.key}\n${s.response}`)
            .join('\n\n')
        : '',
    [productionSummary?.synopsis],
  );

  return (
    <>
      <Stack spacing={2}>
        <div className={'flex justify-between gap-x-2'}>
          <div className={'flex gap-x-2 items-center'}>
            <LegacyHeading h2>Insights</LegacyHeading>
            <div className={'flex'}>
              <LegacyButton
                variant={'ghost'}
                size={'icon'}
                onClick={() => {
                  setRating('GOOD');
                  void onSave('GOOD');
                }}
              >
                <ThumbsUp
                  className={cn(
                    'cursor-pointer',
                    rating && rating === 'GOOD'
                      ? 'text-green-500'
                      : 'text-muted-foreground',
                  )}
                  size={20}
                />
              </LegacyButton>
              <LegacyButton
                variant={'ghost'}
                size={'icon'}
                onClick={() => {
                  setRating('BAD');
                  void onSave('BAD');
                }}
              >
                <ThumbsDown
                  className={cn(
                    'cursor-pointer',
                    rating && rating === 'BAD'
                      ? 'text-red-500'
                      : 'text-muted-foreground',
                  )}
                  size={20}
                />
              </LegacyButton>
            </div>
          </div>
          <div className={'flex gap-x-1 items-center'}>
            {productionSummary?.promptRevisionId &&
            productionSummary?.status === 'SUCCESS' ? (
              isEditingSummary ? (
                <>
                  <LegacyButton
                    variant="secondary"
                    size={'sm'}
                    disabled={updateEvaluation.isLoading}
                    onClick={() => setIsEditingSummary(false)}
                  >
                    Cancel
                  </LegacyButton>
                  <LegacyButton
                    size={'sm'}
                    disabled={updateEvaluation.isLoading}
                    onClick={() => onSave()}
                    className={'w-14'}
                  >
                    {updateEvaluation.isLoading ? (
                      <LoadingSpinner size={'sm'} />
                    ) : (
                      'Save'
                    )}
                  </LegacyButton>
                </>
              ) : (
                <>
                  {hasEdits ? (
                    <LegacyButton
                      onClick={() => setIsViewingChanges(true)}
                      className={'mr-1'}
                      size={'sm'}
                    >
                      View Changes
                    </LegacyButton>
                  ) : null}
                  <LegacyButton
                    variant={'ghost'}
                    size={'icon'}
                    disabled={updateEvaluation.isLoading}
                    onClick={() => setIsEditingSummary(true)}
                  >
                    <Edit size={20} />
                  </LegacyButton>
                  <CopyButton
                    variant="ghost"
                    size={'icon'}
                    value={summaryCopyValue}
                  >
                    <Copy size={20} />
                  </CopyButton>
                </>
              )
            ) : undefined}
          </div>
        </div>
        <Stack spacing={2}>
          {regenerateIsLoading ? (
            <LoadingSpinner size={'lg'} />
          ) : isEditingSummary ? (
            <SynopsisEvaluationForm
              data={evaluationSummary}
              onChange={setEvaluationSummary}
              disabled={updateEvaluation.isLoading}
            />
          ) : productionSummary ? (
            productionSummary.status === 'SUCCESS' ? (
              <div className={'flex flex-col gap-y-4 pr-1'}>
                {productionSummary.synopsis!.map((element) => (
                  <Card key={element.key}>
                    <CardContent>
                      <Stack spacing={1}>
                        <LegacyHeading h3 className={'capitalize'}>
                          {element.key}
                        </LegacyHeading>
                        <p className={'text-muted-foreground'} dir="auto">
                          {element.response.toString()}
                        </p>
                      </Stack>
                    </CardContent>
                  </Card>
                ))}
              </div>
            ) : (
              <Alert variant={'destructive'}>
                <AlertCircle className={'h-4 w-4'} />
                <AlertTitle>Insights Failed</AlertTitle>
                <AlertDescription>
                  {productionSummary?.statusDetails ??
                    'An unexpected error occurred'}
                </AlertDescription>
              </Alert>
            )
          ) : null}
        </Stack>
      </Stack>

      <Dialog
        open={isViewingChanges}
        onOpenChange={(open) => !open && setIsViewingChanges(false)}
      >
        <DialogContent className={'max-w-lg'}>
          <DialogHeader>
            <DialogTitle>Summary of Changes</DialogTitle>
          </DialogHeader>
          <SummaryChangesView
            edited={productionSummary?.synopsisEvaluation?.synopsis ?? []}
            original={productionSummary?.synopsis ?? []}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
