/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {type ContactDetailsFilter} from '@agent-assist/api-typescript-react-query-hooks';

import memoize from 'lodash/memoize';
import {type SetURLSearchParams} from 'react-router-dom';

import {OnFilterParams} from './FilterOptions';
import {columns} from '../contact-import/columns';

// these functions get and set url search params.
// additionally - using the key functions - they convert whitelisted longer keys into short keys for the URL
// & convert the short keys back into long keys for internal state & API requests

const getLong = (shortKey: string): string =>
  columns.find((col) => col.shortKey === shortKey)?.key ?? shortKey;
const getShort = (key: string): string =>
  columns.find((col) => col.key === key)?.shortKey ?? key;
const getLongKey = memoize(getLong);
const getShortKey = memoize(getShort);

export const parseSearchParams = (
  searchParams: URLSearchParams,
): {array: ContactDetailsFilter[]; object: {[key: string]: string}} => {
  const array: ContactDetailsFilter[] = [];
  const object: {[key: string]: string} = {};
  searchParams.forEach((value, key) => {
    if (value !== '') {
      const longKey = getLongKey(key);
      array.push({key: longKey, values: value.split(',')});
      object[key] = value;
    }
  });

  return {array, object};
};

export const updateFilters = (
  [searchParams, setSearchParams]: [URLSearchParams, SetURLSearchParams],
  params: OnFilterParams,
): ContactDetailsFilter[] => {
  if (params.every(({key}) => !key)) {
    // all keys are blank, wipe filters:
    setSearchParams({});
    return [];
  }

  params.forEach(({type, key, value}) => {
    // console.log("updateFilters. param:", { type, key, value });

    if (!key) throw new Error('key is required');
    if (!type) throw new Error('type is required');

    // find if this key has a shorter key for use in the URL search params:
    const shortKey = getShortKey(key);

    if (value) {
      // set new filters:
      const existing = searchParams.get(shortKey);
      if (type === 'ColumnSettingDate') {
        // this type can only have 1 value
        searchParams.set(shortKey, value);
      } else {
        // these types can have multiple CSVs
        if (existing) {
          const values = existing.split(','),
            indexOfExisting = values.indexOf(value);
          if (indexOfExisting === -1) {
            values.push(value);
          } else {
            values.splice(indexOfExisting, 1);
          }
          searchParams.set(shortKey, values.join(','));
        } else {
          searchParams.set(shortKey, value);
        }
      }
    } else {
      searchParams.delete(shortKey);
    }
  });

  const {array, object} = parseSearchParams(searchParams);

  setSearchParams(object);
  return array;
};
