/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ContactSummary as ContactSummaryType} from '@agent-assist/api-typescript-react-query-hooks';
import {XCircle} from 'lucide-react';
import {FC} from 'react';
import {SynopsisElements} from './synopsis-elements';
import {Alert, AlertDescription, AlertTitle} from '../ui/alert';

interface ContactSummaryProps {
  readonly summary: ContactSummaryType;
}

export const ContactSummary: FC<ContactSummaryProps> = ({summary}) => {
  return summary.status === 'SUCCESS' ? (
    <SynopsisElements synopsis={summary.synopsis!} />
  ) : (
    <Alert variant="destructive" className={'w-full'}>
      <XCircle />
      <div className={'mx-4'}>
        <AlertTitle>Summary Failed</AlertTitle>
        <AlertDescription>
          {summary?.statusDetails ?? 'An unexpected error occurred'}
        </AlertDescription>
      </div>
    </Alert>
  );
};
