/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {useEffect} from 'react';
import {useActionConfirmation} from '../../../hooks/modal/action-confirmation';

export const DeleteAgentContactModal = ({
  showModal,
  setShowModal,
  title,
  onDeleteContact,
  refetch,
}: {
  title: string;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onDeleteContact: () => void;
  refetch: () => Promise<void>;
}) => {
  const onConfirm = async () => {
    if (!showModal) return;
    onDeleteContact();
    setShowModal(false);
    await refetch();
  };

  const deleteConfirmation = useActionConfirmation({
    prompt: `Are you sure you want to delete this contact: '${title}'`,
    confirmationButtonText: 'Delete',
    onConfirm,
    onCancel: () => {
      deleteConfirmation.hide();
      setShowModal(false);
    },
  });

  useEffect(() => {
    if (showModal) {
      deleteConfirmation.show();
    } else {
      deleteConfirmation.hide();
    }
  }, [showModal]);

  return <>{deleteConfirmation.modal}</>;
};
