/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ListPromptRevisionsResponseContent,
  PromptRevision,
  ResponseError,
} from '@agent-assist/api-typescript-react-query-hooks';
import FormControl from '@mui/material/FormControl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {UseInfiniteQueryResult} from '@tanstack/react-query';
import React, {useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import {cn} from '../../lib/utils';
import {LoadingSpinner} from '../LoadingSpinner';

export type Option = {
  label: string;
  value: string;
  description?: string;
};

// TODO - fix the typing, we can make this infinite select generic
interface PromptRevisionInfiniteSelectProps {
  itemsKey: string;
  toOption: (item: PromptRevision) => Option;
  placeholder?: string;
  query: UseInfiniteQueryResult<
    ListPromptRevisionsResponseContent,
    ResponseError
  >;
  disabled?: boolean;
  emptyLabel?: string;
  onChange: (option: Option) => void;
}

const PromptRevisionInfiniteSelect = ({
  query,
  itemsKey,
  placeholder = 'None Selected',
  toOption,
  emptyLabel = 'No records',
  onChange,
  disabled,
}: PromptRevisionInfiniteSelectProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [currentOption, setCurrentOption] = useState<Option>();
  const options = React.useMemo(() => {
    return (query.data?.pages?.flatMap?.((p: any) => p[itemsKey]) ?? []).map(
      toOption,
    );
  }, [query, itemsKey, toOption]);

  const handleChange = (option: Option) => {
    setCurrentOption(option);
    onChange(option);
    handleClose();
  };
  const handleOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <FormControl fullWidth disabled={disabled}>
      <div
        onClick={handleOpen}
        // copying radix ui input:
        className={cn(
          'flex h-10 w-full rounded-md border border-primary bg-background px-3 py-2 text-sm ring-offset-background cursor-pointer',
          !currentOption && 'text-muted-foreground/40', // replicate placeholder
          open && 'outline-none ring-2 ring-ring ring-offset-2', // replicate focus-visible
        )}
      >
        {currentOption?.label || placeholder}
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!query.isFetchingNextPage) {
              void query.fetchNextPage();
            }
          }}
          hasMore={query.hasNextPage}
          useWindow={false}
          threshold={100}
          loader={
            <div className="flex w-full items-center justify-center my-2">
              <LoadingSpinner size="sm" />
            </div>
          }
        >
          {options.length === 0 ? (
            <MenuItem>
              <Typography>{emptyLabel}</Typography>
            </MenuItem>
          ) : (
            options.map((option) => (
              <MenuItem key={option.value} onClick={() => handleChange(option)}>
                <Typography>{option.label}</Typography>
              </MenuItem>
            ))
          )}
        </InfiniteScroll>
      </Menu>
    </FormControl>
  );
};

export default PromptRevisionInfiniteSelect;
