/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ContactDetailsFilter,
  useGetBatchContactImport,
} from '@agent-assist/api-typescript-react-query-hooks';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {Box, Button, Card, CardContent, Stack} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useEffect, useState} from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {AIInsightClassifications} from '../../components/Analytics/AIInsightClassifications';
import {AIInsightVisualisation} from '../../components/Analytics/AIInsightVisualisations';
import {availableFilters} from '../../components/Analytics/constants';
import {LoadingSpinner} from '../../components/LoadingSpinner';
import {PromptRevisionScores} from '../../components/QAAnalytics/PromptRevisionScores';
import {
  ContactImportIds,
  SelectContactImport,
} from '../../components/SelectContactImport/SelectContactImport';
import StatusIndicator, {
  StatusTypeEnum,
} from '../../components/StatusIndicator';
import {PageContainer} from '../../components/ui/page';
import {DEFAULT_PROMPT_ID} from '../../lib/constants';
import {useContactImportsStore} from '../../stores/contactImportsStore';
import {
  FilterOptionsComplete,
  type OnFilterParams,
} from '../contact-import/FilterOptions';
import {parseSearchParams, updateFilters} from '../contact-import/filters';

export const AnalyticsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const paramContactImportId = params.contactImportId;
  const paramPromptRevisionId = params.promptRevisionId ?? DEFAULT_PROMPT_ID;
  const [contactImportId, setContactImportId] = useState<string>();
  const contactImports = useContactImportsStore(
    (state) => state.contactImports,
  );

  useEffect(() => {
    if (paramContactImportId) {
      setContactImportId(paramContactImportId);
    }
  }, [paramContactImportId]);

  useEffect(() => {
    if (!paramContactImportId && !contactImportId && contactImports.length) {
      setContactImportId(contactImports[0].contactImportId);
    }
  }, [paramContactImportId, contactImports, contactImportId]);

  const [search, setSearch] = useSearchParams();
  const [filters, setFilters] = useState<ContactDetailsFilter[]>(
    parseSearchParams(search).array,
  );
  const onFilter = (paramOrParams: OnFilterParams) => {
    const newFilters = updateFilters([search, setSearch], paramOrParams);
    setFilters(newFilters);
  };

  if (!contactImportId) {
    return (
      <Box
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  const commonProps = {
    contactImportId,
    promptRevisionId: paramPromptRevisionId,
    filters,
  };

  const pageActions = (
    <>
      <Button
        color="secondary"
        startIcon={<CloudUploadIcon />}
        variant="outlined"
        component={RouterLink}
        to={`/contacts/${contactImportId}`}
      >
        Go to Import
      </Button>
      <div className={'flex flex-row gap-5'}>
        <CustomStatusIndicator contactImportId={contactImportId} />
        <SelectContactImport
          handleContactImportIdChanged={(contactImport: ContactImportIds) =>
            navigate(
              `/analytics/${contactImport.contactImportId}${
                // omit promptRevisionId from URL if it is the default:
                contactImport.promptRevisionId === DEFAULT_PROMPT_ID
                  ? ''
                  : `/${contactImport.promptRevisionId}`
              }`,
            )
          }
          initialValue={contactImportId}
        />
      </div>
    </>
  );

  return (
    <PageContainer title="Insights and Analytics" actions={pageActions}>
      <Card>
        <CardContent>
          <FilterOptionsComplete
            activeFilters={filters}
            availableFilters={availableFilters}
            contactImportId={contactImportId}
            onFilter={onFilter}
          />
        </CardContent>
      </Card>
      <Grid container spacing={4} columns={2}>
        <Grid size={1}>
          <Box>
            <AIInsightClassifications commonProps={commonProps} />
          </Box>
        </Grid>
        <Grid size={1}>
          <Stack spacing={4}>
            <Box>
              <PromptRevisionScores
                contactImportId={contactImportId}
                filters={filters}
              />
            </Box>
            <Box>
              <AIInsightVisualisation commonProps={commonProps} />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

const CustomStatusIndicator = ({
  contactImportId,
}: {
  contactImportId: string;
}) => {
  const contactImportDetails = useGetBatchContactImport({contactImportId});
  const [contactImportStatus, setContactImportStatus] =
    useState<StatusTypeEnum>();
  useEffect(() => {
    setContactImportStatus(contactImportDetails.data?.status as StatusTypeEnum);
  }, [contactImportDetails.data?.status]);

  if (contactImportStatus === StatusTypeEnum.PARTIAL_SUCCESS) {
    return <StatusIndicator status={contactImportStatus} />;
  } else {
    return null;
  }
};
