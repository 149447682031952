/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  useGetPromptRatingAggregation,
  useGetPromptRevision,
  useGetPublishedPromptRevision,
  usePublishPromptRevision,
} from '@agent-assist/api-typescript-react-query-hooks';

import {Button} from '@mui/material';
import {FC, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {PromptRevisionHeader} from './PromptRevisionHeader';
import {SectionPromptQuestions} from './SectionPromptQuestions';
import {LoadingSpinner} from '../../../components/LoadingSpinner';
import {PageContainer} from '../../../components/ui/page';
import {useActionConfirmation} from '../../../hooks/modal/action-confirmation';
import PromptEvaluationModal from '../../../hooks/modal/prompt-report-fullscreen-modal';
import {DEFAULT_PROMPT_ID} from '../../../lib/constants';
import {useNotificationContext} from '../../../providers/NotificationContextProvider';
import {ErrorPage} from '../../error/error-page';

interface ViewPromptProps {}

export const ViewQAPrompt: FC<ViewPromptProps> = () => {
  const {setNotifications} = useNotificationContext();
  const {promptRevisionId} = useParams<{promptRevisionId: string}>();

  if (!promptRevisionId)
    throw new Error('this view requires a promptRevisionId');

  const isDefaultPrompt = promptRevisionId === DEFAULT_PROMPT_ID;
  const promptRevision = useGetPromptRevision({promptRevisionId});
  const publishedRevision = useGetPublishedPromptRevision();
  const publishPrompt = usePublishPromptRevision();
  const navigate = useNavigate();
  const promptRating = useGetPromptRatingAggregation({promptRevisionId});

  const onPublish = useCallback(async () => {
    // Publish the prompt
    await publishPrompt.mutateAsync({promptRevisionId});
    void promptRevision.refetch();
    void publishedRevision.refetch();
    setNotifications((notifications) => [
      ...notifications,
      {
        id: `published-${promptRevisionId}`,
        header: 'Published',
        content:
          'Prompt revision has been published and will be used for future call summarization.',
        type: 'success',
      },
    ]);
  }, [promptRevisionId, setNotifications]);

  const publishConfirmation = useActionConfirmation({
    prompt: `Are you sure you wish to publish prompt ${promptRevision.data?.name}? All future contacts will be summarized using these prompts.`,
    confirmationButtonText: 'Publish',
    onConfirm: onPublish,
  });
  console.log('promptRevisionId', promptRevision.data?.promptRevisionId);
  const isPublished =
    publishedRevision.data?.promptRevisionId ===
    promptRevision.data?.promptRevisionId;

  if (promptRevision.isError || publishedRevision.isError) {
    return (
      <ErrorPage errors={[promptRevision.error, publishedRevision.error]} />
    );
  }

  if (promptRevision.isLoading || publishedRevision.isLoading) {
    return (
      <div className={'flex flex-1 items-center justify-center'}>
        <LoadingSpinner size={'lg'} />
      </div>
    );
  }

  const action = (
    <>
      {!isDefaultPrompt && (
        <Button
          onClick={() => {
            navigate(`/prompts/edit/${promptRevision.data?.promptRevisionId}`);
          }}
        >
          Edit
        </Button>
      )}
      <Button
        variant={'outlined'}
        disabled={isPublished}
        onClick={publishConfirmation.show}
      >
        Publish
      </Button>
    </>
  );

  return (
    <PageContainer
      title={promptRevision.data.name}
      subtitle={isDefaultPrompt ? 'Default prompt' : promptRevisionId}
      actions={action}
      stack
    >
      {publishConfirmation.modal}
      <PromptRevisionHeader
        isPublished={isPublished}
        promptRevision={promptRevision.data}
      />
      {Object.entries(promptRevision.data.promptGroups)
        .sort(([a], [b]) => {
          return b.localeCompare(a);
        })
        .map(([promptGroupName, promptGroup]) => {
          return (
            <SectionPromptQuestions
              key={promptGroupName}
              promptGroupName={promptGroupName}
              totalWeight={promptGroup.totalWeight}
              questions={promptGroup.questions}
              promptRevisionId={promptRevisionId}
              promptRating={promptRating.data?.feedback}
              promptGroupType={promptGroup.promptGroupType}
            />
          );
        })}
      <PromptEvaluationModal />
    </PageContainer>
  );
};

export default ViewQAPrompt;
