/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import type {Components} from '@mui/material/styles';
import {CaretDown as CaretDownIcon} from '@phosphor-icons/react/dist/ssr/CaretDown';
import * as React from 'react';

import type {Theme} from '../types';

function IconComponent(): React.JSX.Element {
  return (
    <CaretDownIcon
      fontSize="1em"
      style={{
        flex: '0 0 auto',
        pointerEvents: 'none',
        position: 'absolute',
        right: '7px',
        top: 'calc(50% - .5em)',
      }}
    />
  );
}

export const MuiSelect = {
  defaultProps: {
    displayEmpty: true,
    IconComponent,
    MenuProps: {sx: {mt: '4px'}},
  },
  styleOverrides: {
    select: {height: 'auto', minHeight: 'auto'},
    root: {
      '& .MuiInputLabel-root': {
        color: 'inherit',
      },
    },
  },
} satisfies Components<Theme>['MuiSelect'];
