/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {CategoryMap} from '@agent-assist/api-typescript-react-query-hooks';

export interface AnalyticValue {
  name: string;
  percentage: number;
  numcontacts: number;
  children?: AnalyticValue[];
  isTruncated: boolean;
}

export const parseListAnalytics = (
  categoryMap?: CategoryMap,
  maxCategories: number = 20,
  currentDepth: number = 0,
  maxDepth: number = 1,
): AnalyticValue[] => {
  if (!categoryMap) return [];
  const values: any[] = <any[]>Object.values(<{[s: string]: any}>categoryMap);
  return values
    .map((v) => {
      return {
        ...v,
        isTruncated: values.length > maxCategories, // should probably be a root param rather than all the way through the tree, and then one could also truncate before mapping.
        children:
          currentDepth < maxDepth
            ? parseListAnalytics(
                v.children,
                currentDepth === 0 ? Infinity : maxCategories,
                currentDepth + 1,
                maxDepth,
              )
            : [],
      } satisfies AnalyticValue;
    })
    .slice(0, maxCategories); // truncate to display on treemap;
};
