/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import type {Components} from '@mui/material/styles';
import {User as UserIcon} from '@phosphor-icons/react/dist/ssr/User';

import type {Theme} from '../types';

export const MuiAvatar = {
  defaultProps: {children: <UserIcon fontSize="var(--Icon-fontSize)" />},
  styleOverrides: {
    root: {
      '--Icon-fontSize': 'var(--icon-fontSize-md)',
      fontSize: '0.875rem',
      fontWeight: 500,
      height: 'var(--Avatar-size, 40px)',
      letterSpacing: 0,
      width: 'var(--Avatar-size, 40px)',
    },
  },
} satisfies Components<Theme>['MuiAvatar'];
