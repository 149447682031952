/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ContactSynopsisElement} from '@agent-assist/api-typescript-react-query-hooks';
import isEqual from 'lodash/isEqual';
import {FC, useMemo} from 'react';
import {DiffMethod, StringDiff, StringDiffProps} from 'react-string-diff';

export interface SynopsisDiffProps {
  readonly edited: ContactSynopsisElement[];
  readonly original: ContactSynopsisElement[];
}

interface DiffSection {
  readonly edited?: ContactSynopsisElement;
  readonly original?: ContactSynopsisElement;
}

const diffStyles: StringDiffProps['styles'] = {
  default: {},
  added: {
    backgroundColor: 'rgba(0,255,0,0.1)',
    border: '1px solid lightgreen',
  },
  removed: {
    backgroundColor: 'rgba(255,0,0,0.1)',
    border: '1px solid #f1a5a5',
  },
};

export const SynopsisDiff: FC<SynopsisDiffProps> = ({original, edited}) => {
  const differingSections = useMemo(() => {
    const diffSections: DiffSection[] = [];
    const maxLength = Math.max(original.length, edited.length);
    for (let i = 0; i < maxLength; i++) {
      if (!isEqual(edited[i], original[i])) {
        diffSections.push({original: original[i], edited: edited[i]});
      }
    }
    return diffSections;
  }, [original, edited]);

  return (
    <div className={'flex flex-col gap-y-4'}>
      {differingSections.map((diff, index) => (
        <div
          className={'flex flex-col gap-y-2'}
          key={diff.original?.key ?? index}
        >
          <StringDiff
            method={DiffMethod.Words}
            styles={diffStyles}
            oldValue={diff.original?.key ?? ''}
            newValue={diff.edited?.key ?? ''}
            className={'font-semibold'}
          />
          <span style={{whiteSpace: 'pre-wrap'}}>
            <StringDiff
              method={DiffMethod.Sentences}
              styles={diffStyles}
              oldValue={diff.original?.response ?? ''}
              newValue={diff.edited?.response ?? ''}
              className={'text-muted-foreground'}
            />
          </span>
        </div>
      ))}
    </div>
  );
};
