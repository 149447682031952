/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Stack} from '@mui/material';
import {ReactNode, useCallback, useState} from 'react';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import {LegacyButton} from '../../components/ui/legacy-button';
import {Switch} from '../../components/ui/switch';
import {type ColumnSetting} from '../../pages/contact-import/columns';

interface TableEditColumnsModalProps {
  readonly columns: ColumnSetting[];
  readonly onConfirm: (columns: ColumnSetting[]) => void;
}

interface TableEditColumnsModal {
  readonly modal: ReactNode;
  readonly show: () => void;
  readonly hide: () => void;
}

export const useTableEditColumnsModal = ({
  onConfirm,
  columns: initialColumns,
}: TableEditColumnsModalProps): TableEditColumnsModal => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [columns, setColumns] = useState(initialColumns);
  const show = useCallback(
    () => setIsConfirmVisible(true),
    [setIsConfirmVisible],
  );
  const hide = useCallback(
    () => setIsConfirmVisible(false),
    [setIsConfirmVisible],
  );

  const disabled = columns.every(({isVisible}) => !isVisible); // cannot apply with no columns visible!

  return {
    modal: (
      <Dialog open={isConfirmVisible} onOpenChange={(open) => !open && hide()}>
        <DialogContent description="Edit table columns">
          <DialogHeader>
            <DialogTitle>Edit table columns</DialogTitle>
          </DialogHeader>
          <Stack direction="column" alignItems="flex-start">
            {columns.map(({key, label, isVisible}) => {
              return (
                <Switch
                  ariaLabel={`Toggle ${label}`}
                  labelOn={label}
                  key={`column ${key}`}
                  defaultChecked={isVisible}
                  onCheckedChange={(isChecked) => {
                    setColumns(
                      columns.map((c) =>
                        c.key === key
                          ? {...c, label, key, isVisible: isChecked}
                          : c,
                      ),
                    );
                  }}
                />
              );
            })}
          </Stack>
          <DialogFooter>
            <LegacyButton variant="ghost" onClick={() => hide()}>
              Cancel
            </LegacyButton>
            <LegacyButton
              disabled={disabled}
              onClick={() => {
                onConfirm(columns);
                hide();
              }}
            >
              Save
            </LegacyButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    ),
    show,
    hide,
  };
};
