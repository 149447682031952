/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Participant} from '@agent-assist/api-typescript-react-query-hooks';
import {PartialTranscript} from '@agent-assist/common';
import {FC} from 'react';
import {ChatBubble} from '../../../components/ChatBubble/ChatBubble';

interface TranscriptBubbleProps {
  readonly startedAt: number;
  readonly prev?: PartialTranscript;
  readonly transcript: PartialTranscript;
  readonly next?: PartialTranscript;
}

type ExtendedParticipant = Participant | 'spk_0' | 'spk_1' | 'spk_2';

export const TranscriptBubble: FC<TranscriptBubbleProps> = ({
  startedAt,
  transcript,
  prev,
  next,
}) => {
  const isBeginning = prev?.participant !== transcript.participant; // may want to promote this begin/end logic.
  const isEnd = next?.participant !== transcript.participant;
  const isAgent = transcript.participant === 'AGENT';
  var isLeft = isAgent;
  var userTitle = isAgent ? 'Agent' : 'Customer'; // could pretty much just be `transcript.participant`

  const participantValue =
    transcript.participant.toString() as ExtendedParticipant;

  switch (participantValue) {
    case 'spk_0':
      isLeft = true;
      userTitle = 'Speaker 1';
      break;
    case 'spk_1':
      isLeft = true;
      userTitle = 'Speaker 2';
      break;
    case 'spk_2':
      isLeft = true;
      userTitle = 'Speaker 3';
      break;
  }

  return (
    <ChatBubble
      {...{
        isBeginning,
        isEnd,
        isLeft,
        content: transcript.content,
        userTitle,
        timeStamp: startedAt + transcript.endOffset,
      }}
    />
  );
};
