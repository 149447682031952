/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  TrendAnalyticsData,
  TrendInterval,
} from '@agent-assist/api-typescript-react-query-hooks';
import {Box} from '@mui/material';
import {IntervalSelect} from './IntervalSelect';
import {LineChartVisualisation} from './LineChart';
import {
  AnalyticsGraphCard,
  AnalyticsGraphCardProps,
} from '../AnalyticsGraphCard';

interface Props extends AnalyticsGraphCardProps {
  values: TrendAnalyticsData[];
  interval: TrendInterval;
  setInterval: (interval: TrendInterval) => void;
}

export const LineChartWithHook = ({
  values,
  setInterval,
  interval,
  isExpandedModalOpen,
  ...props
}: Props) => {
  const content = (
    <>
      <IntervalSelect
        interval={interval}
        setInterval={setInterval}
        isExpandedModalOpen={isExpandedModalOpen!}
      />
      <LineChartVisualisation
        values={values}
        interval={interval}
        isExpandedModalOpen={isExpandedModalOpen!}
      />
    </>
  );
  if (isExpandedModalOpen) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          padding: '20px',
        }}
      >
        {content}
      </Box>
    );
  }
  return (
    <AnalyticsGraphCard {...props} minHeight="min-h-[200px]">
      <Box
        data-testid={`line-graph-${props.title}`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        {content}
      </Box>
    </AnalyticsGraphCard>
  );
};
