/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import * as React from 'react';
import type {LinkProps} from 'react-router-dom';
import {Link} from 'react-router-dom';

interface RouterLinkProps extends Omit<LinkProps, 'to'> {
  href: string;
}

/**
 * This is an adapted for `react-router-dom/link` component.
 * We use this to help us maintain consistency between React-Router and Next.js Router
 */
export const RouterLink = React.forwardRef(function RouterLink(
  props: RouterLinkProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const {href, ...other} = props;

  return <Link ref={ref} to={href} {...other} />;
});
