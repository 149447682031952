/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBedrockRegionResponseContent
 */
export interface GetBedrockRegionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof GetBedrockRegionResponseContent
     */
    region?: string;
}


/**
 * Check if a given object implements the GetBedrockRegionResponseContent interface.
 */
export function instanceOfGetBedrockRegionResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetBedrockRegionResponseContentFromJSON(json: any): GetBedrockRegionResponseContent {
    return GetBedrockRegionResponseContentFromJSONTyped(json, false);
}

export function GetBedrockRegionResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBedrockRegionResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': !exists(json, 'region') ? undefined : json['region'],
    };
}

export function GetBedrockRegionResponseContentToJSON(value?: GetBedrockRegionResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': value.region,
    };
}

