/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {TrendInterval} from '@agent-assist/api-typescript-react-query-hooks';

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  SxProps,
} from '@mui/material';
import {Settings} from 'lucide-react';
import {useState} from 'react';
interface Props {
  interval: TrendInterval;
  setInterval: (interval: TrendInterval) => void;
  isExpandedModalOpen: boolean;
}

const getSx = (isExpanded: boolean): SxProps => ({
  position: 'absolute',
  top: isExpanded ? 64 : -34,
  right: isExpanded ? 64 : 8,
  zIndex: 10,
});

const intervalOptions: TrendInterval[] = [
  '15Min',
  'Hour',
  'Day',
  'Month',
  'Year',
];

export const IntervalSelect = ({
  interval,
  setInterval,
  isExpandedModalOpen,
}: Props) => {
  const [active, setActive] = useState(false);
  if (!active) {
    return (
      <Box sx={getSx(isExpandedModalOpen)}>
        <button onClick={() => setActive(true)}>
          <Settings size="16" />
        </button>
      </Box>
    );
  }
  return (
    <Box sx={getSx(isExpandedModalOpen)}>
      <FormControl sx={{minWidth: 120}} size="small">
        <InputLabel id="interval-select-label">Interval</InputLabel>
        <Select
          labelId="interval-select-label"
          id="interval-select"
          defaultValue={interval}
          value={interval}
          label="Interval"
          onChange={(e) => setInterval(e.target.value as TrendInterval)}
        >
          {intervalOptions.map((option, index) => (
            <MenuItem value={option} key={index}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
