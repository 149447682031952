/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

export const supportedLanguages = [
  {language: 'Abkhaz', code: 'ab-GE'},
  {language: 'Afrikaans', code: 'af-ZA'},
  {language: 'Arabic, Gulf', code: 'ar-AE'},
  {language: 'Arabic, Modern Standard', code: 'ar-SA'},
  {language: 'Armenian', code: 'hy-AM'},
  {language: 'Asturian', code: 'ast-ES'},
  {language: 'Azerbaijani', code: 'az-AZ'},
  {language: 'Bashkir', code: 'ba-RU'},
  {language: 'Basque', code: 'eu-ES'},
  {language: 'Belarusian', code: 'be-BY'},
  {language: 'Bengali', code: 'bn-IN'},
  {language: 'Bosnian', code: 'bs-BA'},
  {language: 'Bulgarian', code: 'bg-BG'},
  {language: 'Catalan', code: 'ca-ES'},
  {language: 'Central Kurdish, Iran', code: 'ckb-IR'},
  {language: 'Central Kurdish, Iraq', code: 'ckb-IQ'},
  {language: 'Chinese, Simplified', code: 'zh-CN'},
  {language: 'Chinese, Traditional', code: 'zh-TW'},
  {language: 'Croatian', code: 'hr-HR'},
  {language: 'Czech', code: 'cs-CZ'},
  {language: 'Danish', code: 'da-DK'},
  {language: 'Dutch', code: 'nl-NL'},
  {language: 'English, Australian', code: 'en-AU'},
  {language: 'English, British', code: 'en-GB'},
  {language: 'English, Indian', code: 'en-IN'},
  {language: 'English, Irish', code: 'en-IE'},
  {language: 'English, New Zealand', code: 'en-NZ'},
  {language: 'English, Scottish', code: 'en-AB'},
  {language: 'English, South African', code: 'en-ZA'},
  {language: 'English, US', code: 'en-US'},
  {language: 'English, Welsh', code: 'en-WL'},
  {language: 'Estonian', code: 'et-ET'},
  {language: 'Farsi', code: 'fa-IR'},
  {language: 'Finnish', code: 'fi-FI'},
  {language: 'French', code: 'fr-FR'},
  {language: 'French, Canadian', code: 'fr-CA'},
  {language: 'Galician', code: 'gl-ES'},
  {language: 'Georgian', code: 'ka-GE'},
  {language: 'German', code: 'de-DE'},
  {language: 'German, Swiss', code: 'de-CH'},
  {language: 'Greek', code: 'el-GR'},
  {language: 'Gujarati', code: 'gu-IN'},
  {language: 'Hausa', code: 'ha-NG'},
  {language: 'Hebrew', code: 'he-IL'},
  {language: 'Hindi, Indian', code: 'hi-IN'},
  {language: 'Hungarian', code: 'hu-HU'},
  {language: 'Icelandic', code: 'is-IS'},
  {language: 'Indonesian', code: 'id-ID'},
  {language: 'Italian', code: 'it-IT'},
  {language: 'Japanese', code: 'ja-JP'},
  {language: 'Kabyle', code: 'kab-DZ'},
  {language: 'Kannada', code: 'kn-IN'},
  {language: 'Kazakh', code: 'kk-KZ'},
  {language: 'Kinyarwanda', code: 'rw-RW'},
  {language: 'Korean', code: 'ko-KR'},
  {language: 'Kyrgyz', code: 'ky-KG'},
  {language: 'Latvian', code: 'lv-LV'},
  {language: 'Lithuanian', code: 'lt-LT'},
  {language: 'Luganda', code: 'lg-IN'},
  {language: 'Macedonian', code: 'mk-MK'},
  {language: 'Malay', code: 'ms-MY'},
  {language: 'Malayalam', code: 'ml-IN'},
  {language: 'Maltese', code: 'mt-MT'},
  {language: 'Marathi', code: 'mr-IN'},
  {language: 'Meadow Mari', code: 'mhr-RU'},
  {language: 'Mongolian', code: 'mn-MN'},
  {language: 'Norwegian Bokmål', code: 'no-NO'},
  {language: 'Odia/Oriya', code: 'or-IN'},
  {language: 'Pashto', code: 'ps-AF'},
  {language: 'Polish', code: 'pl-PL'},
  {language: 'Portuguese', code: 'pt-PT'},
  {language: 'Portuguese, Brazilian', code: 'pt-BR'},
  {language: 'Punjabi', code: 'pa-IN'},
  {language: 'Romanian', code: 'ro-RO'},
  {language: 'Russian', code: 'ru-RU'},
  {language: 'Serbian', code: 'sr-RS'},
  {language: 'Sinhala', code: 'si-LK'},
  {language: 'Slovak', code: 'sk-SK'},
  {language: 'Slovenian', code: 'sl-SI'},
  {language: 'Somali', code: 'so-SO'},
  {language: 'Spanish', code: 'es-ES'},
  {language: 'Spanish, US', code: 'es-US'},
  {language: 'Sundanese', code: 'su-ID'},
  {language: 'Swahili, Kenya', code: 'sw-KE'},
  {language: 'Swahili, Burundi', code: 'sw-BI'},
  {language: 'Swahili, Rwanda', code: 'sw-RW'},
  {language: 'Swahili, Tanzania', code: 'sw-TZ'},
  {language: 'Swahili, Uganda', code: 'sw-UG'},
  {language: 'Swedish', code: 'sv-SE'},
  {language: 'Tagalog/Filipino', code: 'tl-PH'},
  {language: 'Tamil', code: 'ta-IN'},
  {language: 'Tatar', code: 'tt-RU'},
  {language: 'Telugu', code: 'te-IN'},
  {language: 'Thai', code: 'th-TH'},
  {language: 'Turkish', code: 'tr-TR'},
  {language: 'Ukrainian', code: 'uk-UA'},
  {language: 'Uyghur', code: 'ug-CN'},
  {language: 'Uzbek', code: 'uz-UZ'},
  {language: 'Vietnamese', code: 'vi-VN'},
  {language: 'Welsh', code: 'cy-WL'},
  {language: 'Wolof', code: 'wo-SN'},
  {language: 'Zulu', code: 'zu-ZA'},
];
