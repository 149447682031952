/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import Button from '@mui/material/Button';

interface ButtonProps {
  variant: 'text' | 'outlined' | 'contained';
  buttonText: string;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary';
  onClick?: () => void;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
}

export const TrusstGPTButton = ({
  variant,
  size,
  color,
  buttonText,
  onClick,
  startIcon,
  endIcon,
  disabled,
}: ButtonProps) => {
  return (
    <Button
      disabled={disabled}
      variant={variant}
      size={size}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {buttonText}
    </Button>
  );
};
