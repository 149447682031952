/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import type {Shadows} from '@mui/material/styles/shadows';

export const shadows = [
  'none',
  '0px 1px 2px var(--mui-palette-shadow)',
  '0px 1px 5px var(--mui-palette-shadow)',
  '0px 1px 8px var(--mui-palette-shadow)',
  '0px 1px 10px var(--mui-palette-shadow)',
  '0px 1px 14px var(--mui-palette-shadow)',
  '0px 1px 18px var(--mui-palette-shadow)',
  '0px 2px 16px var(--mui-palette-shadow)',
  '0px 3px 14px var(--mui-palette-shadow)',
  '0px 3px 16px var(--mui-palette-shadow)',
  '0px 4px 18px var(--mui-palette-shadow)',
  '0px 4px 20px var(--mui-palette-shadow)',
  '0px 5px 22px var(--mui-palette-shadow)',
  '0px 5px 24px var(--mui-palette-shadow)',
  '0px 5px 26px var(--mui-palette-shadow)',
  '0px 6px 28px var(--mui-palette-shadow)',
  '0px 6px 30px var(--mui-palette-shadow)',
  '0px 6px 32px var(--mui-palette-shadow)',
  '0px 7px 34px var(--mui-palette-shadow)',
  '0px 7px 36px var(--mui-palette-shadow)',
  '0px 8px 38px var(--mui-palette-shadow)',
  '0px 8px 40px var(--mui-palette-shadow)',
  '0px 8px 42px var(--mui-palette-shadow)',
  '0px 9px 44px var(--mui-palette-shadow)',
  '0px 9px 46px var(--mui-palette-shadow)',
] satisfies Shadows;
