/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ContactImportUpdateNotificationPayload,
  ContactStatusUpdateNotificationPayload,
  NotificationPayload,
  RawNotificationPayload,
  TranscriptUpdateNotificationPayload,
} from '@agent-assist/common';
import {GraphQLSubscription, API, graphqlOperation} from '@aws-amplify/api';
import {useCallback, useEffect} from 'react';

/**
 * Subscribe to notifications for a particular target identifier
 */
const useRawNotificationSubscription = (
  targetIdentifier: string,
  onNotification: (notification: RawNotificationPayload) => void,
) => {
  useEffect(() => {
    const client = API.graphql<
      GraphQLSubscription<{onNotification: {value: string}}>
    >(
      graphqlOperation(
        /* GraphQL */ `
          subscription OnNotification($targetIdentifier: String!) {
            onNotification(targetIdentifier: $targetIdentifier) {
              targetIdentifier
              value
            }
          }
        `,
        {targetIdentifier},
      ),
    );

    const subscription = client.subscribe({
      next: ({value}) => {
        if (value.data?.onNotification.value) {
          onNotification({
            targetIdentifier,
            value: JSON.parse(value.data.onNotification.value),
          });
        }
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [targetIdentifier, onNotification]);
};

interface NotificationSubscriptionCallbacks {
  readonly onContactStatusUpdate?: (
    payload: ContactStatusUpdateNotificationPayload,
  ) => void;
  readonly onTranscriptUpdate?: (
    payload: TranscriptUpdateNotificationPayload,
  ) => void;
  readonly onContactImportStatusUpdate?: (
    payload: ContactImportUpdateNotificationPayload,
  ) => void;
}

/**
 * Subscribe to notifications
 */
export const useNotificationSubscription = (
  targetIdentifier: string,
  callbacks: NotificationSubscriptionCallbacks,
) => {
  const onNotification = useCallback(
    (rawNotification: RawNotificationPayload) => {
      const notification = rawNotification.value as NotificationPayload;
      switch (notification.type) {
        case 'CONTACT_STATUS_UPDATE':
          callbacks.onContactStatusUpdate?.(notification);
          break;
        case 'TRANSCRIPT_UPDATE':
          callbacks.onTranscriptUpdate?.(notification);
          break;
        case 'CONTACT_IMPORT_STATUS_UPDATE':
          callbacks.onContactImportStatusUpdate?.(notification);
          break;
        default:
          console.warn('Unsupported notification type', notification);
      }
    },
    [
      callbacks.onContactImportStatusUpdate,
      callbacks.onContactStatusUpdate,
      callbacks.onTranscriptUpdate,
    ],
  );
  useRawNotificationSubscription(targetIdentifier, onNotification);
};
