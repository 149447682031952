/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

interface Option<T> {
  label: string;
  value: T;
}

interface GenericSelectProps<T> {
  label: string;
  options: Option<T>[];
  value: T;
  onChange: (fieldValue: T) => void;
  disabled?: boolean;
  width: number;
  'data-testid'?: string;
}

export function GenericSelect<T extends string | number>({
  label,
  options,
  value,
  width,
  onChange,
  disabled = false,
  'data-testid': dataTestId,
}: GenericSelectProps<T>) {
  const handleChange = (event: SelectChangeEvent<T>) => {
    onChange(event.target.value as T);
  };

  return (
    <FormControl
      data-testid={dataTestId}
      sx={{minWidth: width, marginTop: '1.2rem'}}
      size="small"
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        disabled={disabled}
        onChange={(event) => handleChange(event)}
        style={{width: `${width}rem`}}
      >
        {options.map((option, index) => (
          <MenuItem
            key={`${option.value.toString()}-${index}`}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
