/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  useGetPromptRevision,
  useUpdatePromptVisualisations,
} from '@agent-assist/api-typescript-react-query-hooks';
import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {AIInsightCreateForm} from '../../components/Analytics/AIInsightForm';
import {ModalMode, Visualisation} from '../../components/Analytics/types';
import {constructPromptOptions} from '../../components/Analytics/utils';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import {LegacyButton} from '../../components/ui/legacy-button';

interface CreateAIInsightVisualisationModalProps {
  readonly promptRevisionId: string;
  readonly modalMode: ModalMode;
  readonly promptVisualisations: Visualisation[];
  readonly insight: Visualisation;
  readonly refetch: () => Promise<void>;
}

interface CreateAIInsightVisualisationModal {
  readonly modal: ReactNode;
  readonly show: () => void;
  readonly hide: () => void;
}

export const useManageAIInsightVisualisationModal = ({
  promptRevisionId,
  insight,
  modalMode,
  promptVisualisations,
  refetch,
}: CreateAIInsightVisualisationModalProps): CreateAIInsightVisualisationModal => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const show = useCallback(
    () => setIsConfirmVisible(true),
    [setIsConfirmVisible],
  );
  const hide = useCallback(
    () => setIsConfirmVisible(false),
    [setIsConfirmVisible],
  );
  const {data: promptRevision} = useGetPromptRevision({promptRevisionId});
  const updatePromptVisualisationsMutation = useUpdatePromptVisualisations();

  const [localInsight, setLocalInsight] = useState<Visualisation>(insight);
  const action = modalMode === 'CREATE' ? 'Create' : 'Delete';

  const prompts = promptRevision?.promptGroups;

  useEffect(() => {
    setLocalInsight(insight);
  }, [insight]);

  const onChangeAIInsight = useCallback(
    (updatedValues: Partial<Visualisation>) => {
      setLocalInsight({
        ...localInsight,
        ...updatedValues,
      });
    },
    [localInsight],
  );
  const promptOptions = useMemo(() => {
    if (!prompts) return [];
    return constructPromptOptions(prompts, promptVisualisations);
  }, [prompts, promptVisualisations]);

  const handleChange = <K extends keyof Visualisation>(
    field: K,
    value: Visualisation[K],
  ) => {
    if (field === 'title') {
      const question = promptOptions!.filter((p) => p.label === value)[0];
      onChangeAIInsight({
        [field]: value,
        questionId: question.questionId,
        questionType: question.questionType,
        tooltip: question.question,
      });
    } else {
      onChangeAIInsight({[field]: value});
    }
  };
  const handleClear = useCallback(() => {
    hide();
    if (localInsight.questionId === '') {
      onChangeAIInsight({
        title: '',
        questionId: '',
        questionType: 'extraction_binary',
        tooltip: '',
        visualisationType: 'Aggregate',
      });
    } else {
      onChangeAIInsight(insight);
    }
  }, [localInsight]);

  const onConfirm = useCallback(
    async (questionId: string) => {
      const enabled = modalMode === 'DELETE' ? false : true;
      await updatePromptVisualisationsMutation.mutateAsync({
        promptRevisionId,
        updatePromptVisualisationsRequestContent: {
          questionId,
          visualisation: {
            enabled,
            visualisationType: localInsight.visualisationType,
          },
        },
      });
      await refetch();
    },
    [modalMode, localInsight, updatePromptVisualisationsMutation, refetch],
  );

  return {
    modal: (
      <Dialog
        open={isConfirmVisible}
        onOpenChange={(open) => !open && handleClear()}
      >
        <DialogContent description="confirmation">
          <DialogHeader>
            <DialogTitle>{action} AI Insight Visualisation</DialogTitle>
          </DialogHeader>
          {modalMode === 'DELETE' ? (
            `You are about to remove visualisation For "${localInsight.title}", Are You Sure?`
          ) : (
            <AIInsightCreateForm
              handleChange={handleChange}
              aiInsight={localInsight}
              promptOptions={promptOptions}
            />
          )}
          <DialogFooter>
            <LegacyButton
              data-testid={`cancel-${action}`}
              variant="ghost"
              onClick={handleClear}
            >
              Cancel
            </LegacyButton>
            <LegacyButton
              data-testid={`confirm-${action}`}
              onClick={async () => {
                setIsConfirming(true);
                await onConfirm(localInsight?.questionId!);
                setIsConfirming(false);
                handleClear();
              }}
              isLoading={isConfirming}
              disabled={localInsight.title === ''}
            >
              <span>{action}</span>
            </LegacyButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    ),
    show,
    hide,
  };
};
