/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {VisualisationType} from '@agent-assist/api-typescript-react-query-hooks/lib/models/VisualisationType';
import {useCallback, useState} from 'react';
import {GenericSelect} from './Select';
import {PromptOptions, Visualisation} from './types';
import {Input, InputItem} from '../ui/input';

interface Props {
  handleChange: <K extends keyof Visualisation>(
    field: K,
    value: Visualisation[K],
  ) => void;
  aiInsight?: Visualisation;
  promptOptions?: PromptOptions[];
}
const visualisationTypesOptions = [
  {label: 'Aggregate', value: 'Aggregate'},
  {label: 'Trend', value: 'Trend'},
];

export const AIInsightCreateForm = ({
  handleChange,
  aiInsight,
  promptOptions,
}: Props) => {
  const [options, setOptions] = useState(visualisationTypesOptions);

  const handlePromptTypeChange = useCallback(
    (value: string) => {
      handleChange('title', value);
      const prompt = promptOptions?.find((p) => p.value === value);
      if (!prompt) {
        setOptions(visualisationTypesOptions);
        return;
      }
      if (prompt.hasTrend && !prompt.hasAggregate) {
        setOptions([{label: 'Aggregate', value: 'Aggregate'}]);
      } else if (prompt.hasAggregate && !prompt.hasTrend) {
        setOptions([{label: 'Trend', value: 'Trend'}]);
      } else {
        setOptions(visualisationTypesOptions);
      }
    },
    [handleChange, promptOptions, setOptions, visualisationTypesOptions],
  );
  return (
    <>
      <div className="flex flex-row gap-4 justify-between mb-4">
        <div className="flex flex-col gap-y-3">
          <GenericSelect
            width={13}
            value={aiInsight?.title || ''}
            label="Prompt*"
            options={promptOptions || []}
            onChange={handlePromptTypeChange}
            data-testid="prompt"
          />
        </div>
        <div className="flex flex-col gap-y-3">
          <GenericSelect
            width={13}
            value={aiInsight?.visualisationType || ''}
            label="Visualisation Type*"
            options={options || []}
            onChange={(value) =>
              handleChange('visualisationType', value as VisualisationType)
            }
            data-testid="visualisation-type"
          />
        </div>
      </div>

      <InputItem>
        <p className="text-sm text-muted-foreground">ToolTip</p>
        <Input
          data-testid="tooltip-input"
          value={aiInsight?.tooltip || ''}
          disabled
        />
      </InputItem>
    </>
  );
};
