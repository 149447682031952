/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk';
import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {RuntimeConfigContext} from './RuntimeContextProvider';
import {featureFlagDefaults} from '../config';

interface LaunchDarklyProviderProps {
  children: ReactNode;
}
export const LaunchDarklyProvider: React.FC<LaunchDarklyProviderProps> = ({
  children,
}) => {
  const [LDProviderComponent, setLDProviderComponent] = useState<React.FC<{
    children: ReactNode;
  }> | null>(null);
  const runtimeContext = useContext(RuntimeConfigContext);

  useEffect(() => {
    if (!runtimeContext) return;

    const initializeLDProvider = async () => {
      const provider = await asyncWithLDProvider({
        clientSideID: runtimeContext.ldClientSideId,
        context: {
          kind: 'customer',
          key: runtimeContext.customerId,
        },
        options: {
          bootstrap: featureFlagDefaults,
        },
      });
      setLDProviderComponent(() => provider);
    };

    initializeLDProvider().catch((error) => {
      console.error('Error initializing LaunchDarkly provider:', error);
    });
  }, [runtimeContext]);

  if (!LDProviderComponent) {
    return null;
  }

  return <LDProviderComponent>{children}</LDProviderComponent>;
};
