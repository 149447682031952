/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import {GridColDef, GridPaginationModel} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import {ActionsMenu} from './ActionMenu';
import {TrusstGPTButton} from '../../MaterialUI/Button';
import {MuiDataGrid} from '../../MaterialUI/DataGrid';
import {Agent} from '../Types/types';
interface AgentsGridTableProps {
  agents: Agent[];
  listAgentsLoading: boolean;
  setPaginationModel: (newPaginationModel: GridPaginationModel) => void;
  paginationModel: {
    pageSize: number;
    page: number;
  };
  rowCount: number;
  handlePublishAgent: (agentId: string) => void;
  handleDeleteAgent: (agentId: string) => void;
  handleCloneAgent: (agentId: string) => void;
}

export const AgentsGridTable = ({
  agents,
  listAgentsLoading,
  setPaginationModel,
  paginationModel,
  rowCount,
  handleDeleteAgent,
  handlePublishAgent,
  handleCloneAgent,
}: AgentsGridTableProps) => {
  const navigate = useNavigate();

  const handleConfigureAgent = (agentId: string) => {
    navigate(`/trussted-agents/edit/${agentId}`);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params) => (
        <div
          style={{display: 'flex', alignItems: 'center', gap: '8px'}}
          onClick={() =>
            navigate(`/trussted-agents/edit/${params.row.agentId}`)
          }
        >
          {params.row.published ? (
            <Tooltip
              placement="right-end"
              title={params.row.published ? 'Published Agent' : ''}
            >
              <div style={{display: 'flex', alignItems: 'center'}}>
                {params.value}
                <HelpOutlineIcon sx={{ml: 1, fontSize: 18}} />
              </div>
            </Tooltip>
          ) : (
            params.value
          )}
        </div>
      ),
    },
    {field: 'description', headerName: 'Description', width: 750},
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      width: 180,
      valueGetter: (updatedAt: number) => {
        return new Date(updatedAt).toLocaleDateString();
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      type: 'actions',
      disableColumnMenu: true,
      getActions: (params) => [
        <TrusstGPTButton
          key={params.row.agentId}
          variant="contained"
          size="small"
          color={'primary'}
          buttonText="Configure Agent"
          onClick={() => handleConfigureAgent(params.row.agentId)}
        />,
      ],
    },
    {
      field: 'menu',
      headerName: '',
      width: 80,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <ActionsMenu
            agent={params.row}
            handlePublishAgent={handlePublishAgent}
            handleDeleteAgent={handleDeleteAgent}
            handleCloneAgent={handleCloneAgent}
          />
        </div>
      ),
    },
  ];

  return (
    <MuiDataGrid
      listLoading={listAgentsLoading}
      columns={columns}
      rows={agents}
      rowCount={rowCount}
      paginationModel={paginationModel}
      handlePaginationModelChange={setPaginationModel}
    />
  );
};
