/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/
import DeleteIcon from '@mui/icons-material/Delete';
import {Card, IconButton, CardContent} from '@mui/material';
import Box from '@mui/material/Box/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography/Typography';
import {isEqual} from 'lodash';
import {useState} from 'react';
import {FAQModal} from './FAQModal';
import {
  updateAgentFAQ,
  useTrusstedAgentDispatch,
  useTrusstedAgentState,
} from '../../../providers/TrusstedAgentProvider';
import {EmptyResult} from '../../EmptyResult';
import {TrusstGPTButton} from '../../MaterialUI/Button';
import {StyledLoader} from '../../MaterialUI/StyledLoader';

import {CommonActionBar} from '../ActionBars/CommonActions';
import {FAQ} from '../Types/types';

const DUMMY_FAQ = [
  {
    questionId: '1',
    question: 'What is the meaning of life?',
    answer: '42',
  },
  {
    questionId: '2',
    question: 'What is the meaning of life?',
    answer: '42',
  },
  {
    questionId: '3',
    question: 'What is the meaning of life?',
    answer: '42',
  },
  {
    questionId: '4',
    question: 'What is the meaning of life?',
    answer: '42',
  },
];

interface Props {
  updateAgent: () => Promise<void>;
  updatingAgent: boolean;
  discardChanges: () => void;
}

const FAQ_ITEM = ({
  faq,
  onDelete,
}: {
  faq: FAQ;
  onDelete: (faqId: string) => void;
}) => {
  return (
    <Card sx={{mb: 2}}>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          gap: 2,
        }}
      >
        <Box sx={{flex: 1}}>
          <Typography variant="body1" sx={{mb: 1}}>
            {faq.question}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {faq.answer}
          </Typography>
        </Box>
        <IconButton onClick={() => onDelete(faq.questionId)} size="small">
          <DeleteIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};

const FAQ_LIST = ({
  faqs,
  onDelete,
}: {
  faqs: FAQ[];
  onDelete: (questionId: string) => void;
}) => {
  return (
    <Grid
      container
      spacing={2}
      display={'flex'}
      flexDirection={'column'}
      padding={2}
    >
      {faqs.map((faq) => (
        <FAQ_ITEM
          faq={faq}
          onDelete={() => onDelete(faq.questionId)}
          key={faq.questionId}
        />
      ))}
    </Grid>
  );
};

export const AgentFAQForm = ({
  discardChanges,
  updateAgent,
  updatingAgent,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const {modifiedAgent, originalAgent} = useTrusstedAgentState();
  const dispatch = useTrusstedAgentDispatch();

  const onSaveFAQ = (newFAQ: FAQ) => {
    const updatedAgentFAQ = [...(modifiedAgent?.faq || []), newFAQ];
    updateAgentFAQ(dispatch, updatedAgentFAQ);
  };

  const onDeleteFAQ = (faqId: string) => {
    const updatedAgentFAQ =
      modifiedAgent?.faq?.filter((faq) => faq.questionId !== faqId) || [];
    updateAgentFAQ(dispatch, updatedAgentFAQ);
  };

  const nochangesIdentified = isEqual(modifiedAgent, originalAgent);
  const disableSave =
    modifiedAgent?.name === '' ||
    modifiedAgent?.description === '' ||
    updatingAgent ||
    nochangesIdentified;
  const showLoader = updatingAgent;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '500px',
      }}
    >
      {showLoader && <StyledLoader />}
      <FAQModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onSave={onSaveFAQ}
      />
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container size={12} sx={{marginBottom: 2}}>
          <Grid size={6}>
            <Typography variant="body1">Configure FAQ questions</Typography>
            <Typography variant="caption">
              Configure frequent asked questions for agent
            </Typography>
          </Grid>
          <Grid size={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <TrusstGPTButton
              variant="contained"
              onClick={() => setShowModal(true)}
              buttonText="Add FAQ"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid
          size={12}
          sx={{
            flex: 1,
            overflowY: 'auto',
            flexDirection: 'column',
            maxHeight: '400px',
          }}
        >
          {[...(modifiedAgent?.faq || []), ...DUMMY_FAQ].length > 0 ? (
            <FAQ_LIST
              faqs={modifiedAgent?.faq || DUMMY_FAQ}
              onDelete={onDeleteFAQ}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                height: '50%',
                maxHeight: '400px',
                transform: 'scale(0.8)',
                padding: 2,
              }}
            >
              <EmptyResult text="No FAQ configured" />
            </Box>
          )}
        </Grid>
      </Box>

      <CommonActionBar
        onSave={() => void updateAgent()}
        onDiscard={discardChanges}
        saveDisabled={disableSave}
        discardDisabled={nochangesIdentified}
      ></CommonActionBar>
    </Box>
  );
};
