/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  CreatePromptRevisionRequestContent,
  GetPublishedPromptRevisionResponseContent,
  PromptGroup,
  PromptQuestion,
  Properties,
  Report,
} from '@agent-assist/api-typescript-react-query-hooks';
import {DEFAULT_PROMPT_GROUPS} from '@agent-assist/common/lib/inference/default-prompts';
import copy from 'fast-copy';
import React from 'react';
import {v4 as uuid} from 'uuid';

type PromptRevision = CreatePromptRevisionRequestContent & {
  promptGroups: {[key: string]: PromptGroup & {overallScore?: number}};
};

type PublishedPromptRevision = GetPublishedPromptRevisionResponseContent & {
  promptGroups: {[key: string]: PromptGroup & {overallScore?: number}};
};

export type UpdatedPromptQuestion = Partial<
  Omit<PromptQuestion, 'properties'> & {
    properties: Partial<Properties>;
  }
>;

type RevisionGroupsValid = {[key: string]: boolean};

function validateGroups(
  state: State,
  promptGroupName: string,
  draft: PromptRevision,
): RevisionGroupsValid {
  return {
    ...state.isUpdatedPromptRevisionGroupsValid,
    [promptGroupName]: draft.promptGroups[promptGroupName].questions.every(
      (q) => {
        return q.key && q.question;
      },
    ),
  };
}

const addIds = (
  promptRevision: CreatePromptRevisionRequestContent,
): PromptRevision => {
  const promptGroups = Object.entries(promptRevision.promptGroups);
  const newPromptGroups = Object.fromEntries(
    promptGroups.map(([promptGroupName, promptGroup]) => [
      promptGroupName,
      {
        ...promptGroup,
        questions: promptGroup.questions.map((question) => ({
          ...question,
          id: uuid(),
        })),
      },
    ]),
  );
  return {...promptRevision, promptGroups: newPromptGroups};
};

//===============================
// Constants
//===============================
export type PromptQuestionUnique = PromptQuestion & {
  id: string;
};

type State = {
  promptRevision: PromptRevision;
  updatedPromptRevision: PromptRevision;
  isPromptEditMode: boolean;
  publishedPromptRevision?: PublishedPromptRevision;
  promptReports?: Report[];
  showPromptReportModal: boolean;
  audioURL?: string;
  isUpdatedPromptRevisionGroupsValid: RevisionGroupsValid;
};

const initialPromptRevision = addIds({
  promptGroups: DEFAULT_PROMPT_GROUPS,
  name: 'Default Prompt',
});

export const initialState: State = {
  promptRevision: initialPromptRevision,
  updatedPromptRevision: initialPromptRevision,
  isPromptEditMode: false,
  publishedPromptRevision: undefined,
  promptReports: undefined,
  showPromptReportModal: false,
  isUpdatedPromptRevisionGroupsValid: Object.fromEntries(
    Object.entries(initialPromptRevision.promptGroups).map(
      ([promptGroupName, promptGroup]) => {
        return [
          promptGroupName,
          promptGroup.questions.every((q) => {
            return q.key && q.question;
          }),
        ];
      },
    ),
  ),
};

//===============================
// Actions
//===============================

export type Action =
  | {type: 'setPromptEditMode'; payload: {isEditMode: boolean}}
  | {
      type: 'setPromptRevision';
      payload: {promptRevision: CreatePromptRevisionRequestContent};
    }
  | {
      type: 'setUpdatedPromptRevision';
      payload: {promptRevision: CreatePromptRevisionRequestContent};
    }
  | {
      type: 'updatePromptGroupQuestion';
      payload: {
        index: number;
        promptQuestion: UpdatedPromptQuestion;
        promptGroupName: string;
      };
    }
  | {
      type: 'addPromptGroupQuestion';
      payload: {promptQuestion: PromptQuestion; promptGroupName: string};
    }
  | {
      type: 'deletePromptGroupQuestion';
      payload: {index: number; promptGroupName: string};
    }
  | {type: 'resetPromptRevision'}
  | {
      type: 'setPublishedPromptRevision';
      payload: {promptRevision?: GetPublishedPromptRevisionResponseContent};
    }
  | {
      type: 'updatePromptGroupQuestions';
      payload: {promptQuestions: PromptQuestion[]; promptGroupName: string};
    }
  | {
      type: 'setPromptGroupOverallScore';
      payload: {overallScore: number; promptGroupName: string};
    }
  | {type: 'setPromptReports'; payload: {promptReports?: Report[]}}
  | {
      type: 'setShowModalPromptReport';
      payload: {showPromptReportModal: boolean};
    }
  | {type: 'setAudioUrl'; payload: {audioUrl?: string}}
  | {
      type: 'addPromptGroupChildQuestion';
      payload: {
        promptGroupName: string;
        index: number;
        promptQuestion: PromptQuestionUnique;
      };
    };

//===============================
// Reducer Functions
//===============================

const PromptStateContext = React.createContext<State | undefined>(undefined);
const PromptDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);

export function PromptReducer(state: State, action: Action): State {
  const actionType = action.type;
  switch (actionType) {
    case 'setPromptEditMode': {
      const {isEditMode} = action.payload;
      return {
        ...state,
        isPromptEditMode: isEditMode,
      };
    }
    case 'setPromptRevision': {
      const promptRevision = addIds(action.payload.promptRevision);
      return {
        ...state,
        promptRevision: promptRevision,
      };
    }
    case 'setUpdatedPromptRevision': {
      const {promptRevision} = action.payload;
      return {
        ...state,
        updatedPromptRevision: promptRevision,
      };
    }
    case 'updatePromptGroupQuestion': {
      const {index, promptQuestion, promptGroupName} = action.payload;
      const draft = copy(state.updatedPromptRevision);
      // find the question with index x and replace it with new prompt question
      draft.promptGroups[promptGroupName].questions[index] = {
        ...draft.promptGroups[promptGroupName].questions[index],
        ...promptQuestion,
        properties: {
          ...draft.promptGroups[promptGroupName].questions[index].properties,
          ...promptQuestion.properties,
        },
      };
      draft.promptGroups[promptGroupName].totalWeight = draft.promptGroups[
        promptGroupName
      ].questions.reduce((acc, prompt) => acc + prompt.properties.weight, 0);
      const isUpdatedPromptRevisionGroupsValid = validateGroups(
        state,
        promptGroupName,
        draft,
      );

      // update the state with draft
      return {
        ...state,
        isUpdatedPromptRevisionGroupsValid,
        updatedPromptRevision: draft,
      };
    }
    case 'addPromptGroupQuestion': {
      const {promptQuestion, promptGroupName} = action.payload;
      const draft = copy(state.updatedPromptRevision);
      draft.promptGroups[promptGroupName].questions.push(promptQuestion);
      return {
        ...state,
        updatedPromptRevision: draft,
      };
    }
    case 'deletePromptGroupQuestion': {
      const {index, promptGroupName} = action.payload;
      const draft = copy(state.updatedPromptRevision);
      const indexesToDelete = [index];
      if (
        draft.promptGroups[promptGroupName].questions[index].properties.isParent
      ) {
        // if deleting a parent question delete the child too:
        indexesToDelete.push(index + 1);
      } else if (
        draft.promptGroups[promptGroupName].questions[index].properties.isChild
      ) {
        // if deleting a child question set the isParent and CQA for parent to false:
        draft.promptGroups[promptGroupName].questions[
          index - 1
        ].properties.isParent = false;
        draft.promptGroups[promptGroupName].questions[
          index - 1
        ].properties.conditionalQA = false;
      }
      draft.promptGroups[promptGroupName].questions = draft.promptGroups[
        promptGroupName
      ].questions.filter((_, i) => !indexesToDelete.includes(i));
      draft.promptGroups[promptGroupName].totalWeight = draft.promptGroups[
        promptGroupName
      ].questions.reduce((acc, prompt) => acc + prompt.properties.weight, 0);
      const isUpdatedPromptRevisionGroupsValid = validateGroups(
        state,
        promptGroupName,
        draft,
      );
      return {
        ...state,
        updatedPromptRevision: draft,
        isUpdatedPromptRevisionGroupsValid,
      };
    }
    case 'resetPromptRevision': {
      return {
        ...state,
        promptRevision: initialPromptRevision,
        updatedPromptRevision: initialPromptRevision,
      };
    }
    case 'setPublishedPromptRevision': {
      const {promptRevision} = action.payload;
      return {
        ...state,
        publishedPromptRevision: promptRevision,
      };
    }
    case 'updatePromptGroupQuestions': {
      const {promptQuestions, promptGroupName} = action.payload;
      const draft = copy(state.updatedPromptRevision);
      draft.promptGroups[promptGroupName].questions = promptQuestions;
      return {...state, updatedPromptRevision: draft};
    }
    case 'setPromptGroupOverallScore': {
      const {overallScore, promptGroupName} = action.payload;
      const draft = copy(state.publishedPromptRevision);
      if (draft) {
        draft.promptGroups[promptGroupName].overallScore = overallScore;
        return {...state, publishedPromptRevision: draft};
      } else {
        return state;
      }
    }
    case 'setPromptReports': {
      const {promptReports} = action.payload;
      return {
        ...state,
        promptReports: promptReports,
      };
    }
    case 'setShowModalPromptReport': {
      const {showPromptReportModal} = action.payload;
      return {
        ...state,
        showPromptReportModal: showPromptReportModal,
      };
    }
    case 'setAudioUrl': {
      const {audioUrl} = action.payload;
      return {
        ...state,
        audioURL: audioUrl,
      };
    }
    case 'addPromptGroupChildQuestion': {
      const {promptGroupName, index, promptQuestion} = action.payload;
      const draft = copy(state.updatedPromptRevision);
      draft.promptGroups[promptGroupName].questions.splice(
        index,
        0,
        promptQuestion,
      );
      const isUpdatedPromptRevisionGroupsValid = validateGroups(
        state,
        promptGroupName,
        draft,
      );
      return {
        ...state,
        updatedPromptRevision: draft,
        isUpdatedPromptRevisionGroupsValid,
      };
    }
    default: {
      console.error('unhandled actionType', actionType);
      return state;
    }
  }
}

//===============================
// Provider
//===============================

type Dispatch = (action: Action) => void;
type Props = {children: React.ReactNode};
export function PromptProvider({children}: Props) {
  const [state, dispatch] = React.useReducer(PromptReducer, initialState);

  return (
    <PromptStateContext.Provider value={state}>
      <PromptDispatchContext.Provider value={dispatch}>
        {children}
      </PromptDispatchContext.Provider>
    </PromptStateContext.Provider>
  );
}

//===============================
// Hook Function
//===============================

export function usePromptState() {
  const context = React.useContext(PromptStateContext);
  if (context === undefined) {
    throw new Error('usePromptState must be used within a PromptProvider');
  }
  return context;
}

export function usePromptDispatch() {
  const context = React.useContext(PromptDispatchContext);
  if (context === undefined) {
    throw new Error('usePromptDispatch must be used within a PromptProvider');
  }
  return context;
}

export function setPromptEditMode(dispatch: Dispatch, isEditMode: boolean) {
  dispatch({type: 'setPromptEditMode', payload: {isEditMode}});
}
export function setPromptRevision(
  dispatch: Dispatch,
  promptRevision: CreatePromptRevisionRequestContent,
) {
  dispatch({type: 'setPromptRevision', payload: {promptRevision}});
}
export function setUpdatedPromptRevision(
  dispatch: Dispatch,
  promptRevision: CreatePromptRevisionRequestContent,
) {
  dispatch({type: 'setUpdatedPromptRevision', payload: {promptRevision}});
}
// update one question:
export function updatePromptGroupQuestion(
  dispatch: Dispatch,
  index: number,
  promptQuestion: UpdatedPromptQuestion,
  promptGroupName: string,
) {
  dispatch({
    type: 'updatePromptGroupQuestion',
    payload: {index, promptQuestion, promptGroupName},
  });
}
// update all questions (typically changing the order):
export function updatePromptGroupQuestions(
  dispatch: Dispatch,
  promptQuestions: PromptQuestion[],
  promptGroupName: string,
) {
  dispatch({
    type: 'updatePromptGroupQuestions',
    payload: {promptQuestions, promptGroupName},
  });
}
export function addPromptGroupQuestion(
  dispatch: Dispatch,
  promptGroupName: string,
  promptQuestion: PromptQuestion,
) {
  const promptQuestionWithId = {...promptQuestion, id: uuid()};
  dispatch({
    type: 'addPromptGroupQuestion',
    payload: {promptQuestion: promptQuestionWithId, promptGroupName},
  });
}
export function deletePromptGroupQuestion(
  dispatch: Dispatch,
  index: number,
  promptGroupName: string,
) {
  dispatch({
    type: 'deletePromptGroupQuestion',
    payload: {index, promptGroupName},
  });
}
export function resetPromptRevision(dispatch: Dispatch) {
  dispatch({type: 'resetPromptRevision'});
}
export function setPublishedPromptRevision(
  dispatch: Dispatch,
  promptRevision?: GetPublishedPromptRevisionResponseContent,
) {
  dispatch({type: 'setPublishedPromptRevision', payload: {promptRevision}});
}

// From QA Provider
export function setPromptGroupOverallScore(
  dispatch: Dispatch,
  promptGroupName: string,
  overallScore: number,
) {
  dispatch({
    type: 'setPromptGroupOverallScore',
    payload: {promptGroupName, overallScore},
  });
}
export function setPromptReports(dispatch: Dispatch, promptReports?: Report[]) {
  dispatch({type: 'setPromptReports', payload: {promptReports}});
}
export function setShowModalPromptReport(
  dispatch: Dispatch,
  showPromptReportModal: boolean,
) {
  dispatch({
    type: 'setShowModalPromptReport',
    payload: {showPromptReportModal},
  });
}
export function setAudioUrl(dispatch: Dispatch, audioUrl?: string) {
  dispatch({type: 'setAudioUrl', payload: {audioUrl}});
}

export function addPromptGroupChildQuestion(
  dispatch: Dispatch,
  promptGroupName: string,
  index: number,
  promptQuestion: PromptQuestion,
) {
  const promptQuestionWithId = {...promptQuestion, id: uuid()};
  dispatch({
    type: 'addPromptGroupChildQuestion',
    payload: {promptGroupName, index, promptQuestion: promptQuestionWithId},
  });
}
