/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import camelCase from 'lodash/camelCase';
import memoize from 'lodash/memoize';
import {ChevronRight} from 'lucide-react';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {type AnalyticValue} from '../../hooks/analytics/useListAnalytics2AndParse';
import {CHART_COLOURS, hexToHsl} from '../../lib/colour';
import {formatPercentage} from '../../lib/formatNumber';

const barHeight = 20; // the arrow svg is 24px. barHeight + 2 * 2px padding either side
const barRadius = barHeight / 4;

interface AnalyticValueWithId extends AnalyticValue {
  id: string;
  children: AnalyticValueWithId[]; // compared to AnalyticValue, this is required!
}

const sortAndAddIds = (
  dataId: string,
  data: AnalyticValue[],
  depth: number,
): AnalyticValueWithId[] => {
  // TODO establish max percentages for each layer once and for all here. not quite as easy as it sounds.
  return data
    .slice() // do not mutate original array
    .sort((a, b) => b.percentage - a.percentage)
    .map((category, categoryIndex) => {
      const children = sortAndAddIds(
        dataId,
        category.children ?? [],
        depth + 1,
      );
      // often times we might have different but similar data sets ready to view...
      // eg. in dev `Telco Large` & `Telco Small`, the smaller data set is very likely a subset of the large, so category names and depths are not unique (enough) to be used as React keys.
      const id = `${dataId}-${camelCase(category.name)}-${depth}-${categoryIndex}`;
      return {...category, children, id} satisfies AnalyticValueWithId;
    });
};

export const HierBar = ({
  dataId,
  data,
}: {
  dataId: string;
  data: AnalyticValue[];
}) => {
  const [maxPercentage, setMaxPercentage] = useState<number>(0);
  const [sortedAll, setSortedAll] = useState<AnalyticValueWithId[]>([]);
  useEffect(() => {
    setMaxPercentage(Math.max(...data.map((c) => c.percentage)));
    setSortedAll(sortAndAddIds(dataId, data, 0));
  }, [maxPercentage, JSON.stringify(data)]);

  const [active, setActive] = useState<string[]>([]);

  if (!maxPercentage) return null; // still calculating

  return (
    <ChartContainer>
      {sortedAll.map((category: AnalyticValueWithId, categoryIndex: number) => {
        return Category({
          active,
          category,
          categoryIndex,
          depth: 0,
          maxPercentage,
          numCategories: sortedAll.length,
          setActive,
          subCategoryIndex: 0,
        });
      })}
    </ChartContainer>
  );
};

interface CategoryParams {
  active: string[];
  category: AnalyticValueWithId;
  categoryIndex: number; // this category's position within this layer
  depth: number; // what layer this category is in
  maxPercentage: number; // the maximum percentage within this layer
  numCategories: number; // number of categories at this layer
  setActive: Function;
  subCategoryIndex: number; // if a category is expanded, this is the child's position within this layer
}

const ChartContainer = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
`;

const CategoryWrapper = styled.div<{$isActive: boolean}>`
  width: 100%;
  margin: 4px 0;
  opacity: ${({$isActive}) => ($isActive ? 1 : 0.8)};
  cursor: pointer;
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  line-height: ${barHeight}px;
`;

const BarLabelWrapper = styled.div`
  width: 200px;
  height: ${barHeight + 4}px;
  position: relative;
`;

const BarLabel = styled.label`
  color: hsl(var('--text-foreground'));
  width: 200px;
  height: ${barHeight + 4}px;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  position: absolute;
  cursor: pointer;
  border-radius: ${barRadius}px;
  &:hover {
    overflow: visible;
    z-index: 1;
    background-color: hsl(var(--card));
    width: auto;
    padding-right: 4px;
  }
`;

export const BarPercentage = styled.div`
  color: #999;
  font-size: 14px;
  display: inline-block;
  line-height: ${barHeight}px;
  padding-left: 4px;
`;

const SubCategory = styled.div`
  margin-left: 20px;
`;

export const BarBackground = styled.div`
  background: hsl(var(--background));
  height: ${barHeight}px;
  border-radius: ${barRadius}px;
  margin-top: 2px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const Category = (catParams: CategoryParams): JSX.Element => {
  const {active, setActive, category, categoryIndex, depth} = catParams;

  const isActive = active.includes(category.id);
  const maxPercentage = isActive
    ? Math.max(...category.children.map((c) => c.percentage))
    : 0;
  const hasChildren = category.children.length > 0;

  const onClick = () => {
    if (!hasChildren) return;
    const nextActive = [...active];
    if (isActive) {
      nextActive.splice(depth);
    } else {
      nextActive[depth] = category.id;
    }
    setActive(nextActive);
  };

  return (
    <CategoryWrapper key={category.id} $isActive={isActive}>
      <BarWrapper onClick={onClick}>
        <BarLabelWrapper>
          <BarLabel>{category.name || 'Other'}</BarLabel>
        </BarLabelWrapper>
        <ChevronRight
          style={{
            transform: `rotate(${isActive ? 90 : 0}deg)`,
            opacity: hasChildren ? 1 : 0,
            transition: `transform 0.2s ease-in-out`,
          }}
        />
        <BarBackground>
          <Bars catParams={catParams} />
          <BarPercentage>{formatPercentage(category.percentage)}</BarPercentage>
        </BarBackground>
      </BarWrapper>

      {isActive && (
        <SubCategory>
          {category.children.map(
            (subCategory: AnalyticValueWithId, subCategoryIndex: number) => {
              return Category({
                active,
                category: subCategory,
                categoryIndex,
                depth: depth + 1,
                maxPercentage,
                numCategories: category.children.length,
                setActive,
                subCategoryIndex,
              });
            },
          )}
        </SubCategory>
      )}
    </CategoryWrapper>
  );
};

const Bars = ({catParams}: {catParams: CategoryParams}): JSX.Element => {
  const {
    category,
    categoryIndex,
    numCategories,
    maxPercentage,
    subCategoryIndex,
  } = catParams;

  const mainColour = CHART_COLOURS[categoryIndex % CHART_COLOURS.length];

  if (category.children.length === 0) {
    // render a single bar:
    const lightAmount = subCategoryIndex / numCategories;
    const memoizedValue = calcGradient(mainColour, lightAmount);
    return (
      <BarGraph
        background={memoizedValue}
        width={category.percentage / maxPercentage}
        isFirst={true}
        isLast={true}
      />
    );
  }

  // else render all the sub category bars:
  return (
    <>
      {category.children.map(({percentage}, childIndex) => {
        const numChildren = category.children.length;
        const isFirst = childIndex === 0;
        const isLast = childIndex === numChildren - 1;
        const lightAmount = childIndex / numChildren;
        const memoizedValue = calcGradient(mainColour, lightAmount);
        return (
          <BarGraph
            key={`${category.id}-bar-${childIndex}`}
            background={memoizedValue}
            width={percentage / maxPercentage}
            isFirst={isFirst}
            isLast={isLast}
          />
        );
      })}
    </>
  );
};

const permuteColour = (
  hex: string,
  lightAmount: number,
  darkenAmount: number,
): string => {
  const [h, s, l] = hexToHsl(hex);
  return `hsl(${h}, ${Math.min(100, s * (1 + darkenAmount))}%, ${Math.max(0, l * (1 - darkenAmount - lightAmount))}%)`;
};

const calcGradient = memoize(
  (mainColour: string, lightAmount: number): string => {
    const fillLeft = permuteColour(mainColour, lightAmount * 0.4, 0);
    const fillRight = permuteColour(mainColour, lightAmount * 0.4, 0.4);
    return `linear-gradient(90deg, ${fillLeft}, ${fillRight})`;
  },
);

interface BarGraphParams {
  isFirst: boolean;
  isLast: boolean;
  width: number;
  background: 'temperature' | string;
}

const CIRCULAR = `${barRadius}px`;
const ROUND_LEFT = `${barRadius}px 0 0 ${barRadius}px`;
const ROUND_RIGHT = `0 ${barRadius}px ${barRadius}px 0`;
const RECTANGULAR = '0';

export const BarGraph = ({
  isFirst,
  isLast,
  width,
  background,
}: BarGraphParams): JSX.Element => {
  const borderRadius =
    isFirst && isLast
      ? CIRCULAR
      : isFirst
        ? ROUND_LEFT
        : isLast
          ? ROUND_RIGHT
          : RECTANGULAR;
  // Don't use styled-components for this kind of thing, it will generate a new class for every single width permutation!

  if (width < 0)
    throw new Error('BarChunk width cannot be less than zero: ' + width);
  if (width > 1)
    throw new Error('BarChunk width cannot be greater than 1: ' + width);

  const backgroundColour =
    background === 'temperature'
      ? `hsl(${width * 10 * 12} 45% 50%)` // infer colour from width. assumed 0 = bad, 1 = good -> which renders bad as red and good as green
      : background;

  if (width === 0) {
    // technically not correct, but show a hint of a bar graph:
    return (
      <div
        style={{
          display: 'inline-block',
          background: 'none',
          width: 10, // not important - this element only renders a border, on the left hand side
          height: barHeight,
          border: `2px solid ${backgroundColour}`,
          borderRadius,
          borderTop: '0',
          borderRight: '0',
          borderBottom: '0',
        }}
      />
    );
  }

  return (
    <div
      style={{
        display: 'inline-block',
        background: backgroundColour,
        width: `${width * 90}%`,
        height: barHeight,
        borderRadius,
      }}
    />
  );
};
