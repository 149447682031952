/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

//===============================
// Constants
//===============================

import React from 'react';
import {
  AgentInput,
  AgentToolInput,
  FAQ,
} from '../components/TrusstedAgent/Types/types';

type State = {
  originalAgent?: AgentInput;
  modifiedAgent?: AgentInput;
};
export const defaultTool = {
  toolId: 'draft',
  name: '',
  description: '',
  parameters: [],
};
const initialState: State = {
  originalAgent: undefined,
  modifiedAgent: undefined,
};

//===============================
// Actions
//===============================

export type Action =
  | {type: 'setAgent'; payload: {agent?: AgentInput}}
  | {type: 'updateAgentSettings'; payload: {updatedAgentSettings: AgentInput}}
  | {
      type: 'updateAgentTool';
      payload: {toolIndex: number; updatedTool: AgentToolInput};
    }
  | {type: 'updateAgentFAQ'; payload: {updatedAgentFAQ: FAQ[]}}
  | {type: 'discardAgentChanges'};

//===============================
// Reducer Functions
//===============================

const TrusstedAgentStateContext = React.createContext<State | undefined>(
  undefined,
);
const TrusstedAgentDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);
export function TrusstedAgentReducer(state: State, action: Action): State {
  const actionType = action.type;
  switch (actionType) {
    case 'setAgent': {
      const agent = action.payload.agent;
      return {
        ...state,
        originalAgent: agent,
        modifiedAgent: agent,
      };
    }
    case 'updateAgentSettings': {
      return {
        ...state,
        modifiedAgent: {
          ...state.modifiedAgent,
          ...action.payload.updatedAgentSettings,
        },
      };
    }
    case 'updateAgentTool': {
      const updatedTool = action.payload.updatedTool;
      const updatedToolIndex = action.payload.toolIndex;
      const tools = state.modifiedAgent?.tools || [];
      // get the tool with toolIndex & update it with updatedTool
      const updatedTools = [
        ...tools.slice(0, updatedToolIndex),
        updatedTool,
        ...tools.slice(updatedToolIndex + 1),
      ];
      // update the state with the updated tools
      return {
        ...state,
        modifiedAgent: {
          ...state.modifiedAgent,
          tools: updatedTools,
          name: state.modifiedAgent?.name || '',
          description: state.modifiedAgent?.description || '',
        },
      };
    }
    case 'updateAgentFAQ': {
      console.log('updateAgentFAQ', action.payload.updatedAgentFAQ);
      return {
        ...state,
        modifiedAgent: {
          ...state.modifiedAgent,
          name: state.modifiedAgent?.name || '',
          description: state.modifiedAgent?.description || '',
          faq: action.payload.updatedAgentFAQ,
        },
      };
    }
    case 'discardAgentChanges': {
      return {
        ...state,
        modifiedAgent: state.originalAgent,
      };
    }
  }
}

//===============================
// Provider
//===============================

type Dispatch = (action: Action) => void;
type Props = {children: React.ReactNode};

export function TrusstedAgentProvider({children}: Props) {
  const [state, dispatch] = React.useReducer(
    TrusstedAgentReducer,
    initialState,
  );
  return (
    <TrusstedAgentStateContext.Provider value={state}>
      <TrusstedAgentDispatchContext.Provider value={dispatch}>
        {children}
      </TrusstedAgentDispatchContext.Provider>
    </TrusstedAgentStateContext.Provider>
  );
}

//===============================
// Hook Function
//===============================

export function useTrusstedAgentState() {
  const context = React.useContext(TrusstedAgentStateContext);
  if (context === undefined) {
    throw new Error(
      'useTrusstedAgentState must be used within a TrusstedAgentProvider',
    );
  }
  return context;
}

export function useTrusstedAgentDispatch() {
  const context = React.useContext(TrusstedAgentDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useTrusstedAgentDispatch must be used within a TrusstedAgentProvider',
    );
  }
  return context;
}

export function setAgent(dispatch: Dispatch, agent?: AgentInput) {
  dispatch({type: 'setAgent', payload: {agent}});
}
export function updateAgentSettings(
  dispatch: Dispatch,
  updatedAgentSettings: AgentInput,
) {
  dispatch({type: 'updateAgentSettings', payload: {updatedAgentSettings}});
}
export function updateAgentTool(
  dispatch: Dispatch,
  toolIndex: number,
  updatedTool: AgentToolInput,
) {
  dispatch({type: 'updateAgentTool', payload: {toolIndex, updatedTool}});
}
export function discardAgentChanges(dispatch: Dispatch) {
  dispatch({type: 'discardAgentChanges'});
}
export function updateAgentFAQ(dispatch: Dispatch, updatedAgentFAQ: FAQ[]) {
  dispatch({type: 'updateAgentFAQ', payload: {updatedAgentFAQ}});
}
