/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {TrusstGPTButton} from '../../MaterialUI/Button';
import TrusstGPTTextField from '../../MaterialUI/TextField';
import {FAQ} from '../Types/types';

interface FAQModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (faq: FAQ) => void;
}

export const FAQModal = ({open, onClose, onSave}: FAQModalProps) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const handleClose = () => {
    setQuestion('');
    setAnswer('');
    onClose();
  };

  const handleSave = () => {
    const questionId = uuidv4();
    onSave({question, answer, questionId});
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Frequently Asked Question</DialogTitle>
      <DialogContent sx={{padding: '1rem'}}>
        <TrusstGPTTextField
          label="Question"
          value={question}
          variant="outlined"
          onChange={(event) => setQuestion(event.target.value)}
          placeholder="Frequently asked question"
          multiLine={true}
          rows={4}
        />
        <TrusstGPTTextField
          label="Response"
          value={answer}
          onChange={(event) => setAnswer(event.target.value)}
          placeholder="Response"
          multiLine={true}
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <TrusstGPTButton
          variant="outlined"
          onClick={handleClose}
          buttonText="Cancel"
        />
        <TrusstGPTButton
          variant="contained"
          onClick={handleSave}
          disabled={!question || !answer}
          buttonText="Add Question"
        />
      </DialogActions>
    </Dialog>
  );
};
