/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import React from 'react';
//===============================
// Constants
//===============================
export type User = {
  email: string;
  emailVerified: boolean;
  familyName: string;
  givenName: string;
  sub: string;
  username: string;
  signOut(): Promise<void>;
};

type State = {
  user?: User;
  isLightMode: boolean;
};

const initialState: State = {
  user: undefined,
  isLightMode: false,
};

//===============================
// Actions
//===============================

type Action =
  | {type: 'setUser'; payload: {user: User}}
  | {type: 'setLightMode'; payload: {isLightMode: boolean}};

//===============================
// Reducer Functions
//===============================

const TrusstGPTStateContext = React.createContext<State | undefined>(undefined);
const TrusstGPTDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);

function TrusstGPTReducer(state: State, action: Action): State {
  const actionType = action.type;
  switch (actionType) {
    case 'setUser': {
      const {user} = action.payload;
      return {...state, user};
    }
    case 'setLightMode': {
      const {isLightMode} = action.payload;
      return {...state, isLightMode};
    }
  }
}

//===============================
// Provider
//===============================

type Dispatch = (action: Action) => void;
type Props = {children: React.ReactNode};
export function TrusstGPTProvider({children}: Props) {
  const [state, dispatch] = React.useReducer(TrusstGPTReducer, initialState);

  return (
    <TrusstGPTStateContext.Provider value={state}>
      <TrusstGPTDispatchContext.Provider value={dispatch}>
        {children}
      </TrusstGPTDispatchContext.Provider>
    </TrusstGPTStateContext.Provider>
  );
}

//===============================
// Hook Function
//===============================

export function useTrusstGPTState() {
  const context = React.useContext(TrusstGPTStateContext);
  if (context === undefined) {
    throw new Error(
      'useTrusstGPTState must be used within a TrusstGPTProvider',
    );
  }
  return context;
}

export function useTrusstGPTDispatch() {
  const context = React.useContext(TrusstGPTDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useTrusstGPTDispatch must be used within a TrusstGPTProvider',
    );
  }
  return context;
}

export function setUser(dispatch: Dispatch, user: any) {
  dispatch({type: 'setUser', payload: {user}});
}

export function setLightMode(dispatch: Dispatch, isLightMode: boolean) {
  dispatch({type: 'setLightMode', payload: {isLightMode}});
}
