/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFns';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {DateTimeRangePicker} from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import {LocalizationProvider} from '@mui/x-date-pickers-pro/LocalizationProvider';
import {DateRange} from '@mui/x-date-pickers-pro/models';
import {
  startOfWeek,
  endOfWeek,
  subDays,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import {useEffect, useState} from 'react';

const getShortCutItems = (showShortcuts: boolean) => {
  if (!showShortcuts) return [];
  const today = new Date();
  const prevWeek = subDays(today, 7);
  return [
    {
      label: 'This Week',
      getValue: () => [startOfWeek(today), endOfWeek(today)],
    },
    {
      label: 'Last Week',
      getValue: () => [startOfWeek(prevWeek), endOfWeek(prevWeek)],
    },
    {label: 'Last 7 Days', getValue: () => [prevWeek, today]},
    {
      label: 'Current Month',
      getValue: () => [startOfMonth(today), endOfMonth(today)],
    },
    {label: 'Reset', getValue: () => [null, null]},
  ];
};

// Mui prefers a tuple defaulting to null:
type MuiDateRange = DateRange<Date>; // [Date | null, Date | null]

// most of our existing code uses {from,to} defaulting to undefined:
export type LegacyDateRange = {
  from?: Date | undefined;
  to?: Date | undefined;
};

interface MuiRangePicker {
  onAccept: ([start, end]: MuiDateRange, context: any) => void;
  showShortcuts?: boolean;
  defaultValue?: MuiDateRange;
}

export const MuiDateTimeRangePicker = ({
  defaultValue,
  onAccept,
  showShortcuts = true,
}: MuiRangePicker) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => setValue(defaultValue), [defaultValue]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimeRangePicker
        defaultValue={defaultValue}
        format="d MMM yy hh:m a"
        onAccept={onAccept}
        onChange={setValue}
        slotProps={{
          shortcuts: {items: getShortCutItems(showShortcuts)},
        }}
        value={value}
        slots={{
          fieldSeparator: () => null,
        }}
      />
    </LocalizationProvider>
  );
};

export const MuiDateRangePicker = ({
  defaultValue,
  onAccept,
  showShortcuts = true,
}: MuiRangePicker) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => setValue(defaultValue), [defaultValue]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        defaultValue={defaultValue}
        format="d MMM yy"
        onAccept={onAccept}
        onChange={setValue}
        slotProps={{
          shortcuts: {items: getShortCutItems(showShortcuts)},
        }}
        value={value}
        slots={{
          fieldSeparator: () => null,
        }}
      />
    </LocalizationProvider>
  );
};
