/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  PromptRevision,
  useListPromptRevisions,
  useStartBatchContactImport,
} from '@agent-assist/api-typescript-react-query-hooks';
import {parseS3Path, S3Location} from '@agent-assist/common/lib/storage/s3';
import {Checkbox} from '@mui/material';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {supportedLanguages} from './supportedLanguages';

import {FeatureFlags} from '../../config';
import PromptRevisionInfiniteSelect, {
  Option,
} from '../PromptRevisionInfiniteSelect';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import {Input, InputItem, InputSet} from '../ui/input';
import {Label} from '../ui/label';
import {LegacyButton} from '../ui/legacy-button';
import {SelectBasic} from '../ui/select';

interface CreateContactImportModalProps {
  isOpen: boolean;
  s3Url?: string;
  onClose(): void;
}

const PROMPT_REVISIONS_PAGE_SIZE = 10;

const promptToOption = (promptRevision: PromptRevision): Option => {
  return {
    value: promptRevision.promptRevisionId,
    label: promptRevision.name,
    description: `Created: ${new Date(promptRevision.createdAt).toLocaleString()}`,
  };
};

const CreateContactImportModal = ({
  isOpen,
  onClose,
  s3Url,
}: CreateContactImportModalProps) => {
  const navigate = useNavigate();

  const [creatingImport, setCreatingImport] = useState(false);
  const [importS3Path, setImportS3Path] = useState<string>(s3Url ?? 's3://');
  const [importName, setImportName] = useState<string>('');
  const [language, setLanguage] = useState<string | undefined>();
  const [maxSpeakers, setMaxSpeakers] = useState<number | undefined>();
  const [translateToEnglish, setTranslateToEnglish] = useState<boolean>(false);
  // TODO: consider a select box for the year as well, since we partition prompt revisions by year
  const [promptRevisionListYear] = useState<number>(new Date().getFullYear());
  const [importPromptRevisionId, setImportPromptRevisionId] = useState<
    string | undefined
  >();
  const {ingestImportJobLanguageSelector} = useFlags<FeatureFlags>();

  const promptRevisions = useListPromptRevisions({
    year: promptRevisionListYear,
    pageSize: PROMPT_REVISIONS_PAGE_SIZE,
  });
  const promptRevisionsById = useMemo(
    () =>
      Object.fromEntries(
        (promptRevisions.data?.pages.flatMap((p) => p.revisions) ?? []).map(
          (p) => [p.promptRevisionId, p],
        ),
      ),
    [promptRevisions],
  );

  const importS3Location = useMemo((): S3Location | undefined => {
    try {
      return parseS3Path(importS3Path);
    } catch {
      return undefined;
    }
  }, [importS3Path]);

  const defaultImportName = useMemo(() => {
    return `${
      importPromptRevisionId
        ? promptRevisionsById[importPromptRevisionId]?.name ??
          importPromptRevisionId
        : 'Published'
    } ${importS3Location?.bucket || 'Import'} ${new Date().toLocaleDateString()}`;
  }, [importS3Location?.bucket, importPromptRevisionId, promptRevisionsById]);

  const startJob = useStartBatchContactImport();

  const onSubmit = useCallback(async () => {
    if (!importS3Location) {
      return;
    }
    setCreatingImport(true);
    try {
      // TODO: instead of start job it has to send to the api that uses ED
      const result = await startJob.mutateAsync({
        startBatchContactImportRequestContent: {
          bucket: importS3Location.bucket,
          key: importS3Location.key,
          name: importName || defaultImportName,
          promptRevisionId: importPromptRevisionId,
          language,
          maxSpeakers,
          translateToEnglish,
        },
      });
      navigate(`/contacts/${result.contactImportId}`);
      onClose();
    } catch {
      setCreatingImport(false);
    }
  }, [
    startJob,
    importS3Location,
    importName,
    defaultImportName,
    importPromptRevisionId,
  ]);

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Import Job</DialogTitle>
        </DialogHeader>
        <InputSet>
          <InputItem>
            <Label>Contact S3 Path</Label>
            <p className={'text-sm text-muted-foreground'}>
              All exported contacts under the given S3 path will be imported.
            </p>
            <Input
              type="text"
              disabled={startJob.isLoading}
              value={importS3Path}
              onChange={(e) => setImportS3Path(e.target.value)}
              variant={'outline'}
              minLength={6}
              required
            />
          </InputItem>
          <InputItem>
            <Label>Prompt Revision</Label>
            <p className={'text-sm text-muted-foreground'}>
              Select the prompt revision you wish to import these contacts with.
              When no prompt is selected, the currently published prompt will be
              used.
            </p>
            <PromptRevisionInfiniteSelect
              placeholder={'Select a prompt...'}
              disabled={startJob.isLoading}
              query={promptRevisions}
              itemsKey="revisions"
              toOption={promptToOption}
              onChange={(option) => setImportPromptRevisionId(option.value)}
              emptyLabel={'No prompt revisions'}
            />
          </InputItem>
          <InputItem>
            <Label>Job Name</Label>
            <p className={'text-sm text-muted-foreground'}>
              Provide a name for the import job. When omitted, the job will be
              named <i>{defaultImportName}.</i>
            </p>
            <Input
              type="text"
              disabled={startJob.isLoading}
              value={importName}
              placeholder={defaultImportName}
              onChange={(e) => setImportName(e.target.value)}
              variant={'outline'}
            />
          </InputItem>
          {ingestImportJobLanguageSelector && (
            <>
              <div className="grid grid-cols-2 gap-4">
                <InputItem>
                  <Label>Language</Label>
                  <p className={'text-sm text-muted-foreground'}>
                    The language of the imported contacts.
                  </p>
                  <SelectBasic
                    onValueChange={(v) => setLanguage(v)}
                    options={supportedLanguages.map((lan) => ({
                      label: lan.language,
                      value: lan.code,
                    }))}
                    value={language}
                    placeholder="Select a language..."
                    disabled={startJob.isLoading}
                    width={64}
                  />
                </InputItem>
                <InputItem>
                  <Label>Max Speakers</Label>
                  <p className={'text-sm text-muted-foreground'}>
                    The maximum number of speakers.
                  </p>
                  <SelectBasic
                    onValueChange={(v) => setMaxSpeakers(Number(v))}
                    options={new Array(3).fill('').map((_, i) => ({
                      label: `${i + 1}`,
                      value: String(i + 1),
                    }))}
                    value={maxSpeakers?.toString()}
                    placeholder="Select max speakers..."
                    disabled={startJob.isLoading}
                    width={64}
                  />
                </InputItem>
              </div>
              <InputItem>
                <div className="flex items-center gap-2">
                  <Label>Translate to English</Label>
                  <Checkbox
                    checked={translateToEnglish}
                    onChange={(v) => setTranslateToEnglish(v.target.checked)}
                    disabled={startJob.isLoading}
                  />
                </div>
              </InputItem>
            </>
          )}
        </InputSet>
        <DialogFooter>
          <LegacyButton variant={'ghost'} onClick={onClose}>
            Cancel
          </LegacyButton>
          <LegacyButton isLoading={creatingImport} onClick={onSubmit}>
            Create
          </LegacyButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateContactImportModal;
