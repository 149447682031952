/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ResponseError} from '@agent-assist/api-typescript-react-query-hooks';
import {UseInfiniteQueryResult} from '@tanstack/react-query';
import InfiniteScroll from 'react-infinite-scroller';
import {LoadingSpinner} from '../LoadingSpinner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';

type InfiniteQueryResult<U> = UseInfiniteQueryResult<U, ResponseError>;

interface InfiniteSelectProps<T, U> {
  disabled?: boolean;
  emptyLabel?: string;
  initialValue?: string;
  itemsKey: string;
  options: any[];
  placeholder?: string;
  query: InfiniteQueryResult<U>;
  toOption: (item: T) => any;
  onChange(value: any): void;
}

const InfiniteSelect = <T, U>({
  query,
  placeholder,
  emptyLabel,
  onChange,
  options,
  initialValue,
}: InfiniteSelectProps<T, U>) => {
  return (
    <Select onValueChange={onChange} value={initialValue}>
      <SelectTrigger className="max-w-[400px] text-muted-foreground focus:ring-0 truncate">
        <SelectValue
          className={'placeholder:text-muted-foreground'}
          placeholder={placeholder ?? 'None Selected'}
        />
      </SelectTrigger>
      <SelectContent className="overlay-y max-h-[300px] overflow-y-auto">
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          initialLoad={true}
          threshold={100}
          loadMore={() => {
            if (!query.isFetchingNextPage) {
              void query.fetchNextPage();
            }
          }}
          hasMore={query.hasNextPage}
          loader={
            <div className={'flex w-full items-center justify-center my-2'}>
              <LoadingSpinner size={'sm'} />
            </div>
          }
        >
          {options.map((option, optionIndex) => (
            <SelectItem
              value={option.value}
              className={'cursor-pointer'}
              key={`option-${optionIndex}-${option.value}`}
            >
              <p className={'py-1.5 mr-4'}>{option.label}</p>
            </SelectItem>
          ))}
          {options.length === 0 ? (
            <p className={'py-2 text-muted-foreground text-sm px-2'}>
              {emptyLabel ?? 'No records'}
            </p>
          ) : null}
        </InfiniteScroll>
      </SelectContent>
    </Select>
  );
};

export default InfiniteSelect;
