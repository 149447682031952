// Import models
import type {
  ActionType,
  BadRequestErrorResponseContent,
  ChatRequestContent,
  ChatResponseContent,
  CreateDataDialogPromptRequestContent,
  CreateDataDialogPromptResponseContent,
  CreatePromptRevisionRequestContent,
  CreatePromptRevisionResponseContent,
  CreateUpdateAgentRequestContent,
  CreateUpdateAgentResponseContent,
  DeleteAgentResponseContent,
  DeleteAgentsRequestContent,
  DeleteAgentsResponseContent,
  ExportQAEvaluationToCSVRequestContent,
  ExportQAEvaluationToCSVResponseContent,
  GetAgentResponseContent,
  GetAnalyticsAggregationScoresRequestContent,
  GetAnalyticsAggregationScoresResponseContent,
  GetAssessEnabledResponseContent,
  GetAudioPresignedURLResponseContent,
  GetBatchContactImportResponseContent,
  GetBedrockRegionResponseContent,
  GetConnectAccessUrlResponseContent,
  GetContactMetadataResponseContent,
  GetContactResponseContent,
  GetDataDialogPromptResponseContent,
  GetOrganisationDetailsResponseContent,
  GetPromptAggregateAnalyticsWithFiltersRequestContent,
  GetPromptAggregateAnalyticsWithFiltersResponseContent,
  GetPromptRatingAggregationResponseContent,
  GetPromptReportsResponseContent,
  GetPromptRevisionResponseContent,
  GetPromptTrendAnalyticsWithFiltersRequestContent,
  GetPromptTrendAnalyticsWithFiltersResponseContent,
  GetPublishedPromptRevisionResponseContent,
  GetQAAnalyticsWithFiltersRequestContent,
  GetQAAnalyticsWithFiltersResponseContent,
  GetQaAggregationSectionScoreRequestContent,
  GetQaAggregationSectionScoreResponseContent,
  GetTranscriptResponseContent,
  InternalFailureErrorResponseContent,
  ListAgentsResponseContent,
  ListAnalyticsResponseContent,
  ListAnalyticsWithFiltersRequestContent,
  ListAnalyticsWithFiltersResponseContent,
  ListBatchContactImportResultsResponseContent,
  ListBatchContactImportsResponseContent,
  ListContactMetadataColumnsResponseContent,
  ListContactsByFiltersRequestContent,
  ListContactsByFiltersResponseContent,
  ListContactsMetadataResponseContent,
  ListContactsResponseContent,
  ListDataDialogPromptsResponseContent,
  ListOptionsForColumnResponseContent,
  ListPromptRevisionsResponseContent,
  ListPromptVisualisationsResponseContent,
  ListTranscriptsResponseContent,
  NotAuthorizedErrorResponseContent,
  NotFoundErrorResponseContent,
  PublishPromptRevisionResponseContent,
  QueryPromptRatingMetricsResponseContent,
  ReTryContactActionResponseContent,
  ReimportContactJobResponseContent,
  SetAssessEnabledResponseContent,
  SetBedrockRegionResponseContent,
  SetOrganisationDetailsRequestContent,
  SetOrganisationDetailsResponseContent,
  StartBatchContactImportRequestContent,
  StartBatchContactImportResponseContent,
  TestPromptForContactRequestContent,
  TestPromptForContactResponseContent,
  UpdateContactSummaryEvaluationRequestContent,
  UpdateContactSummaryEvaluationResponseContent,
  UpdateDataDialogPromptRequestContent,
  UpdateDataDialogPromptResponseContent,
  UpdatePromptFeedbackRequestContent,
  UpdatePromptFeedbackResponseContent,
  UpdatePromptRevisionRequestContent,
  UpdatePromptRevisionResponseContent,
  UpdatePromptVisualisationsRequestContent,
  UpdatePromptVisualisationsResponseContent,
  
} from '../models';

// Import request parameter interfaces
import {
    ChatRequest,
    CreateDataDialogPromptRequest,
    CreatePromptRevisionRequest,
    CreateUpdateAgentRequest,
    DeleteAgentRequest,
    DeleteAgentsRequest,
    ExportQAEvaluationToCSVRequest,
    GetAgentRequest,
    GetAnalyticsAggregationScoresRequest,
    
    GetAudioPresignedURLRequest,
    GetBatchContactImportRequest,
    
    
    GetContactRequest,
    GetContactMetadataRequest,
    GetDataDialogPromptRequest,
    
    GetPromptAggregateAnalyticsWithFiltersRequest,
    GetPromptRatingAggregationRequest,
    GetPromptReportsRequest,
    GetPromptRevisionRequest,
    GetPromptTrendAnalyticsWithFiltersRequest,
    
    GetQAAnalyticsWithFiltersRequest,
    GetQaAggregationSectionScoreRequest,
    GetTranscriptRequest,
    ListAgentsRequest,
    ListAnalyticsRequest,
    ListAnalyticsWithFiltersRequest,
    ListBatchContactImportResultsRequest,
    ListBatchContactImportsRequest,
    
    ListContactsRequest,
    ListContactsByFiltersRequest,
    ListContactsMetadataRequest,
    
    ListOptionsForColumnRequest,
    ListPromptRevisionsRequest,
    ListPromptVisualisationsRequest,
    ListTranscriptsRequest,
    PublishPromptRevisionRequest,
    QueryPromptRatingMetricsRequest,
    ReTryContactActionRequest,
    ReimportContactJobRequest,
    SetAssessEnabledRequest,
    SetBedrockRegionRequest,
    SetOrganisationDetailsRequest,
    StartBatchContactImportRequest,
    TestPromptForContactRequest,
    UpdateContactSummaryEvaluationRequest,
    UpdateDataDialogPromptRequest,
    UpdatePromptFeedbackRequest,
    UpdatePromptRevisionRequest,
    UpdatePromptVisualisationsRequest,
    
    
} from '..';

import { ResponseError } from '../runtime';
import { AgentAssistApi } from './AgentAssistApi';
import { AgentAssistApiDefaultContext } from "./AgentAssistApiClientProvider";

import {
    useQuery,
    UseQueryResult,
    UseQueryOptions,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    UseMutationResult
} from "@tanstack/react-query";
import { createContext, useContext } from "react";

/**
 * Context for the API client used by the hooks.
 */
export const AgentAssistApiClientContext = createContext<AgentAssistApi | undefined>(undefined);

const NO_API_ERROR = new Error(`AgentAssistApi client missing. Please ensure you have instantiated the AgentAssistApiClientProvider with a client instance.`);


/**
 * useMutation hook for the Chat operation
 */
export const useChat = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<ChatResponseContent, TError, ChatRequest>, 'mutationFn'>
): UseMutationResult<ChatResponseContent, TError, ChatRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: ChatRequest) => api.chat(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the CreateDataDialogPrompt operation
 */
export const useCreateDataDialogPrompt = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<CreateDataDialogPromptResponseContent, TError, CreateDataDialogPromptRequest>, 'mutationFn'>
): UseMutationResult<CreateDataDialogPromptResponseContent, TError, CreateDataDialogPromptRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: CreateDataDialogPromptRequest) => api.createDataDialogPrompt(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the CreatePromptRevision operation
 */
export const useCreatePromptRevision = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<CreatePromptRevisionResponseContent, TError, CreatePromptRevisionRequest>, 'mutationFn'>
): UseMutationResult<CreatePromptRevisionResponseContent, TError, CreatePromptRevisionRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: CreatePromptRevisionRequest) => api.createPromptRevision(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the CreateUpdateAgent operation
 */
export const useCreateUpdateAgent = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<CreateUpdateAgentResponseContent, TError, CreateUpdateAgentRequest>, 'mutationFn'>
): UseMutationResult<CreateUpdateAgentResponseContent, TError, CreateUpdateAgentRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: CreateUpdateAgentRequest) => api.createUpdateAgent(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteAgent operation
 */
export const useDeleteAgent = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<DeleteAgentResponseContent, TError, DeleteAgentRequest>, 'mutationFn'>
): UseMutationResult<DeleteAgentResponseContent, TError, DeleteAgentRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteAgentRequest) => api.deleteAgent(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteAgents operation
 */
export const useDeleteAgents = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<DeleteAgentsResponseContent, TError, DeleteAgentsRequest>, 'mutationFn'>
): UseMutationResult<DeleteAgentsResponseContent, TError, DeleteAgentsRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteAgentsRequest) => api.deleteAgents(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the ExportQAEvaluationToCSV operation
 */
export const useExportQAEvaluationToCSV = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<ExportQAEvaluationToCSVResponseContent, TError, ExportQAEvaluationToCSVRequest>, 'mutationFn'>
): UseMutationResult<ExportQAEvaluationToCSVResponseContent, TError, ExportQAEvaluationToCSVRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: ExportQAEvaluationToCSVRequest) => api.exportQAEvaluationToCSV(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetAgent operation
 */
export const useGetAgent = <TError = ResponseError>(
    params: GetAgentRequest,
    options?: Omit<UseQueryOptions<GetAgentResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetAgentResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getAgent", params], () => api.getAgent(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the GetAnalyticsAggregationScores operation
 */
export const useGetAnalyticsAggregationScores = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<GetAnalyticsAggregationScoresResponseContent, TError, GetAnalyticsAggregationScoresRequest>, 'mutationFn'>
): UseMutationResult<GetAnalyticsAggregationScoresResponseContent, TError, GetAnalyticsAggregationScoresRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: GetAnalyticsAggregationScoresRequest) => api.getAnalyticsAggregationScores(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetAssessEnabled operation
 */
export const useGetAssessEnabled = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<GetAssessEnabledResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetAssessEnabledResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getAssessEnabled"], () => api.getAssessEnabled(), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetAudioPresignedURL operation
 */
export const useGetAudioPresignedURL = <TError = ResponseError>(
    params: GetAudioPresignedURLRequest,
    options?: Omit<UseQueryOptions<GetAudioPresignedURLResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetAudioPresignedURLResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getAudioPresignedURL", params], () => api.getAudioPresignedURL(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetBatchContactImport operation
 */
export const useGetBatchContactImport = <TError = ResponseError>(
    params: GetBatchContactImportRequest,
    options?: Omit<UseQueryOptions<GetBatchContactImportResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetBatchContactImportResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getBatchContactImport", params], () => api.getBatchContactImport(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetBedrockRegion operation
 */
export const useGetBedrockRegion = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<GetBedrockRegionResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetBedrockRegionResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getBedrockRegion"], () => api.getBedrockRegion(), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetConnectAccessUrl operation
 */
export const useGetConnectAccessUrl = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<GetConnectAccessUrlResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetConnectAccessUrlResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getConnectAccessUrl"], () => api.getConnectAccessUrl(), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetContact operation
 */
export const useGetContact = <TError = ResponseError>(
    params: GetContactRequest,
    options?: Omit<UseQueryOptions<GetContactResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetContactResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getContact", params], () => api.getContact(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetContactMetadata operation
 */
export const useGetContactMetadata = <TError = ResponseError>(
    params: GetContactMetadataRequest,
    options?: Omit<UseQueryOptions<GetContactMetadataResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetContactMetadataResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getContactMetadata", params], () => api.getContactMetadata(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetDataDialogPrompt operation
 */
export const useGetDataDialogPrompt = <TError = ResponseError>(
    params: GetDataDialogPromptRequest,
    options?: Omit<UseQueryOptions<GetDataDialogPromptResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetDataDialogPromptResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getDataDialogPrompt", params], () => api.getDataDialogPrompt(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetOrganisationDetails operation
 */
export const useGetOrganisationDetails = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<GetOrganisationDetailsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetOrganisationDetailsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getOrganisationDetails"], () => api.getOrganisationDetails(), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the GetPromptAggregateAnalyticsWithFilters operation
 */
export const useGetPromptAggregateAnalyticsWithFilters = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<GetPromptAggregateAnalyticsWithFiltersResponseContent, TError, GetPromptAggregateAnalyticsWithFiltersRequest>, 'mutationFn'>
): UseMutationResult<GetPromptAggregateAnalyticsWithFiltersResponseContent, TError, GetPromptAggregateAnalyticsWithFiltersRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: GetPromptAggregateAnalyticsWithFiltersRequest) => api.getPromptAggregateAnalyticsWithFilters(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetPromptRatingAggregation operation
 */
export const useGetPromptRatingAggregation = <TError = ResponseError>(
    params: GetPromptRatingAggregationRequest,
    options?: Omit<UseQueryOptions<GetPromptRatingAggregationResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetPromptRatingAggregationResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getPromptRatingAggregation", params], () => api.getPromptRatingAggregation(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the GetPromptReports operation
 */
export const useGetPromptReports = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<GetPromptReportsResponseContent, TError, GetPromptReportsRequest>, 'mutationFn'>
): UseMutationResult<GetPromptReportsResponseContent, TError, GetPromptReportsRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: GetPromptReportsRequest) => api.getPromptReports(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetPromptRevision operation
 */
export const useGetPromptRevision = <TError = ResponseError>(
    params: GetPromptRevisionRequest,
    options?: Omit<UseQueryOptions<GetPromptRevisionResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetPromptRevisionResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getPromptRevision", params], () => api.getPromptRevision(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the GetPromptTrendAnalyticsWithFilters operation
 */
export const useGetPromptTrendAnalyticsWithFilters = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<GetPromptTrendAnalyticsWithFiltersResponseContent, TError, GetPromptTrendAnalyticsWithFiltersRequest>, 'mutationFn'>
): UseMutationResult<GetPromptTrendAnalyticsWithFiltersResponseContent, TError, GetPromptTrendAnalyticsWithFiltersRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: GetPromptTrendAnalyticsWithFiltersRequest) => api.getPromptTrendAnalyticsWithFilters(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetPublishedPromptRevision operation
 */
export const useGetPublishedPromptRevision = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<GetPublishedPromptRevisionResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetPublishedPromptRevisionResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getPublishedPromptRevision"], () => api.getPublishedPromptRevision(), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the GetQAAnalyticsWithFilters operation
 */
export const useGetQAAnalyticsWithFilters = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<GetQAAnalyticsWithFiltersResponseContent, TError, GetQAAnalyticsWithFiltersRequest>, 'mutationFn'>
): UseMutationResult<GetQAAnalyticsWithFiltersResponseContent, TError, GetQAAnalyticsWithFiltersRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: GetQAAnalyticsWithFiltersRequest) => api.getQAAnalyticsWithFilters(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the GetQaAggregationSectionScore operation
 */
export const useGetQaAggregationSectionScore = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<GetQaAggregationSectionScoreResponseContent, TError, GetQaAggregationSectionScoreRequest>, 'mutationFn'>
): UseMutationResult<GetQaAggregationSectionScoreResponseContent, TError, GetQaAggregationSectionScoreRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: GetQaAggregationSectionScoreRequest) => api.getQaAggregationSectionScore(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetTranscript operation
 */
export const useGetTranscript = <TError = ResponseError>(
    params: GetTranscriptRequest,
    options?: Omit<UseQueryOptions<GetTranscriptResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetTranscriptResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getTranscript", params], () => api.getTranscript(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useInfiniteQuery hook for the ListAgents operation
 */
export const useListAgents = <TError = ResponseError>(
    params: ListAgentsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListAgentsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListAgentsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listAgents", params], ({ pageParam }) => api.listAgents({ ...params, nextToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: AgentAssistApiDefaultContext,
        ...options as any,
    });
};

/**
 * useQuery hook for the ListAnalytics operation
 */
export const useListAnalytics = <TError = ResponseError>(
    params: ListAnalyticsRequest,
    options?: Omit<UseQueryOptions<ListAnalyticsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListAnalyticsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listAnalytics", params], () => api.listAnalytics(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the ListAnalyticsWithFilters operation
 */
export const useListAnalyticsWithFilters = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<ListAnalyticsWithFiltersResponseContent, TError, ListAnalyticsWithFiltersRequest>, 'mutationFn'>
): UseMutationResult<ListAnalyticsWithFiltersResponseContent, TError, ListAnalyticsWithFiltersRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: ListAnalyticsWithFiltersRequest) => api.listAnalyticsWithFilters(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useInfiniteQuery hook for the ListBatchContactImportResults operation
 */
export const useListBatchContactImportResults = <TError = ResponseError>(
    params: ListBatchContactImportResultsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListBatchContactImportResultsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListBatchContactImportResultsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listBatchContactImportResults", params], ({ pageParam }) => api.listBatchContactImportResults({ ...params, nextToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: AgentAssistApiDefaultContext,
        ...options as any,
    });
};

/**
 * useInfiniteQuery hook for the ListBatchContactImports operation
 */
export const useListBatchContactImports = <TError = ResponseError>(
    params: ListBatchContactImportsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListBatchContactImportsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListBatchContactImportsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listBatchContactImports", params], ({ pageParam }) => api.listBatchContactImports({ ...params, nextToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: AgentAssistApiDefaultContext,
        ...options as any,
    });
};

/**
 * useQuery hook for the ListContactMetadataColumns operation
 */
export const useListContactMetadataColumns = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<ListContactMetadataColumnsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListContactMetadataColumnsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listContactMetadataColumns"], () => api.listContactMetadataColumns(), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useInfiniteQuery hook for the ListContacts operation
 */
export const useListContacts = <TError = ResponseError>(
    params: ListContactsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListContactsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListContactsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listContacts", params], ({ pageParam }) => api.listContacts({ ...params, nextToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: AgentAssistApiDefaultContext,
        ...options as any,
    });
};

/**
 * useMutation hook for the ListContactsByFilters operation
 */
export const useListContactsByFilters = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<ListContactsByFiltersResponseContent, TError, ListContactsByFiltersRequest>, 'mutationFn'>
): UseMutationResult<ListContactsByFiltersResponseContent, TError, ListContactsByFiltersRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: ListContactsByFiltersRequest) => api.listContactsByFilters(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useInfiniteQuery hook for the ListContactsMetadata operation
 */
export const useListContactsMetadata = <TError = ResponseError>(
    params: ListContactsMetadataRequest,
    options?: Omit<UseInfiniteQueryOptions<ListContactsMetadataResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListContactsMetadataResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listContactsMetadata", params], ({ pageParam }) => api.listContactsMetadata({ ...params, nextToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: AgentAssistApiDefaultContext,
        ...options as any,
    });
};

/**
 * useQuery hook for the ListDataDialogPrompts operation
 */
export const useListDataDialogPrompts = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<ListDataDialogPromptsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListDataDialogPromptsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listDataDialogPrompts"], () => api.listDataDialogPrompts(), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the ListOptionsForColumn operation
 */
export const useListOptionsForColumn = <TError = ResponseError>(
    params: ListOptionsForColumnRequest,
    options?: Omit<UseQueryOptions<ListOptionsForColumnResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListOptionsForColumnResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listOptionsForColumn", params], () => api.listOptionsForColumn(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useInfiniteQuery hook for the ListPromptRevisions operation
 */
export const useListPromptRevisions = <TError = ResponseError>(
    params: ListPromptRevisionsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListPromptRevisionsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListPromptRevisionsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listPromptRevisions", params], ({ pageParam }) => api.listPromptRevisions({ ...params, nextToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: AgentAssistApiDefaultContext,
        ...options as any,
    });
};

/**
 * useQuery hook for the ListPromptVisualisations operation
 */
export const useListPromptVisualisations = <TError = ResponseError>(
    params: ListPromptVisualisationsRequest,
    options?: Omit<UseQueryOptions<ListPromptVisualisationsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListPromptVisualisationsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listPromptVisualisations", params], () => api.listPromptVisualisations(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useInfiniteQuery hook for the ListTranscripts operation
 */
export const useListTranscripts = <TError = ResponseError>(
    params: ListTranscriptsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListTranscriptsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListTranscriptsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listTranscripts", params], ({ pageParam }) => api.listTranscripts({ ...params, nextToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: AgentAssistApiDefaultContext,
        ...options as any,
    });
};

/**
 * useMutation hook for the PublishPromptRevision operation
 */
export const usePublishPromptRevision = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<PublishPromptRevisionResponseContent, TError, PublishPromptRevisionRequest>, 'mutationFn'>
): UseMutationResult<PublishPromptRevisionResponseContent, TError, PublishPromptRevisionRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: PublishPromptRevisionRequest) => api.publishPromptRevision(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the QueryPromptRatingMetrics operation
 */
export const useQueryPromptRatingMetrics = <TError = ResponseError>(
    params: QueryPromptRatingMetricsRequest,
    options?: Omit<UseQueryOptions<QueryPromptRatingMetricsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<QueryPromptRatingMetricsResponseContent, TError> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["queryPromptRatingMetrics", params], () => api.queryPromptRatingMetrics(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the ReTryContactAction operation
 */
export const useReTryContactAction = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<ReTryContactActionResponseContent, TError, ReTryContactActionRequest>, 'mutationFn'>
): UseMutationResult<ReTryContactActionResponseContent, TError, ReTryContactActionRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: ReTryContactActionRequest) => api.reTryContactAction(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the ReimportContactJob operation
 */
export const useReimportContactJob = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<ReimportContactJobResponseContent, TError, ReimportContactJobRequest>, 'mutationFn'>
): UseMutationResult<ReimportContactJobResponseContent, TError, ReimportContactJobRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: ReimportContactJobRequest) => api.reimportContactJob(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the SetAssessEnabled operation
 */
export const useSetAssessEnabled = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<SetAssessEnabledResponseContent, TError, SetAssessEnabledRequest>, 'mutationFn'>
): UseMutationResult<SetAssessEnabledResponseContent, TError, SetAssessEnabledRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: SetAssessEnabledRequest) => api.setAssessEnabled(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the SetBedrockRegion operation
 */
export const useSetBedrockRegion = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<SetBedrockRegionResponseContent, TError, SetBedrockRegionRequest>, 'mutationFn'>
): UseMutationResult<SetBedrockRegionResponseContent, TError, SetBedrockRegionRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: SetBedrockRegionRequest) => api.setBedrockRegion(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the SetOrganisationDetails operation
 */
export const useSetOrganisationDetails = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<SetOrganisationDetailsResponseContent, TError, SetOrganisationDetailsRequest>, 'mutationFn'>
): UseMutationResult<SetOrganisationDetailsResponseContent, TError, SetOrganisationDetailsRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: SetOrganisationDetailsRequest) => api.setOrganisationDetails(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the StartBatchContactImport operation
 */
export const useStartBatchContactImport = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<StartBatchContactImportResponseContent, TError, StartBatchContactImportRequest>, 'mutationFn'>
): UseMutationResult<StartBatchContactImportResponseContent, TError, StartBatchContactImportRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: StartBatchContactImportRequest) => api.startBatchContactImport(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the TestPromptForContact operation
 */
export const useTestPromptForContact = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<TestPromptForContactResponseContent, TError, TestPromptForContactRequest>, 'mutationFn'>
): UseMutationResult<TestPromptForContactResponseContent, TError, TestPromptForContactRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: TestPromptForContactRequest) => api.testPromptForContact(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the UpdateContactSummaryEvaluation operation
 */
export const useUpdateContactSummaryEvaluation = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<UpdateContactSummaryEvaluationResponseContent, TError, UpdateContactSummaryEvaluationRequest>, 'mutationFn'>
): UseMutationResult<UpdateContactSummaryEvaluationResponseContent, TError, UpdateContactSummaryEvaluationRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: UpdateContactSummaryEvaluationRequest) => api.updateContactSummaryEvaluation(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the UpdateDataDialogPrompt operation
 */
export const useUpdateDataDialogPrompt = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<UpdateDataDialogPromptResponseContent, TError, UpdateDataDialogPromptRequest>, 'mutationFn'>
): UseMutationResult<UpdateDataDialogPromptResponseContent, TError, UpdateDataDialogPromptRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: UpdateDataDialogPromptRequest) => api.updateDataDialogPrompt(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the UpdatePromptFeedback operation
 */
export const useUpdatePromptFeedback = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<UpdatePromptFeedbackResponseContent, TError, UpdatePromptFeedbackRequest>, 'mutationFn'>
): UseMutationResult<UpdatePromptFeedbackResponseContent, TError, UpdatePromptFeedbackRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: UpdatePromptFeedbackRequest) => api.updatePromptFeedback(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the UpdatePromptRevision operation
 */
export const useUpdatePromptRevision = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<UpdatePromptRevisionResponseContent, TError, UpdatePromptRevisionRequest>, 'mutationFn'>
): UseMutationResult<UpdatePromptRevisionResponseContent, TError, UpdatePromptRevisionRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: UpdatePromptRevisionRequest) => api.updatePromptRevision(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the UpdatePromptVisualisations operation
 */
export const useUpdatePromptVisualisations = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<UpdatePromptVisualisationsResponseContent, TError, UpdatePromptVisualisationsRequest>, 'mutationFn'>
): UseMutationResult<UpdatePromptVisualisationsResponseContent, TError, UpdatePromptVisualisationsRequest> => {
    const api = useContext(AgentAssistApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: UpdatePromptVisualisationsRequest) => api.updatePromptVisualisations(params), {
        context: AgentAssistApiDefaultContext,
        ...options,
    });
};

