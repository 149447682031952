/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Card, CardContent} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import {QuestionList} from './QuestionList';
import {wordToTitleCase} from '../../lib/formatString';
import {
  usePromptState,
  type PromptQuestionUnique,
} from '../../providers/PromptProvider';

export const SectionForm = (props: {section: string}) => {
  const {updatedPromptRevision, isUpdatedPromptRevisionGroupsValid} =
    usePromptState();

  const {section} = props;
  const {questions, totalWeight, promptGroupType} =
    updatedPromptRevision.promptGroups[section];
  const isValid = isUpdatedPromptRevisionGroupsValid[section];

  return (
    <Card>
      <CardHeader
        title={wordToTitleCase(section)}
        action={
          promptGroupType === 'qa' && <span>Total Weight: {totalWeight}</span>
        }
      />
      <CardContent>
        <QuestionList
          isValid={isValid}
          promptGroupName={section}
          promptGroupType={promptGroupType}
          prompts={
            // api provides these as `QAPromptQuestion[]` but at this stage we have added unique Ids in the QAProvider, so `QAPromptQuestionUnique` is needed:
            questions as PromptQuestionUnique[]
          }
        />
      </CardContent>
    </Card>
  );
};
