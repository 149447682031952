/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {useCreateDataDialogPrompt} from '@agent-assist/api-typescript-react-query-hooks';
import {Save} from 'lucide-react';
import {useEffect, useState} from 'react';

import {TimeStampedMessage, defaultQuestions} from './data-dialog';
import {ChatBubble} from '../../components/ChatBubble/ChatBubble';
import {LegacyButton} from '../../components/ui/legacy-button';
import {useActionConfirmation} from '../../hooks/modal/action-confirmation';

const ConfirmSave = ({
  promptText,
  onCancel,
  refetch,
}: {
  promptText: string;
  onCancel: () => void;
  refetch: () => Promise<void>;
}) => {
  // console.log("ConfirmSave", promptText);

  const create = useCreateDataDialogPrompt();
  const saveConfirmation = useActionConfirmation({
    prompt: `Are you sure you want to save this prompt: '${promptText}'`,
    confirmationButtonText: 'Save',
    onConfirm: async () => {
      await create.mutateAsync({
        createDataDialogPromptRequestContent: {promptText},
      });
      await refetch();
    },
    onCancel,
  });

  useEffect(() => {
    if (promptText) {
      saveConfirmation.show();
    } else {
      saveConfirmation.hide();
    }
  }, [promptText]);

  return <div>{saveConfirmation.modal}</div>;
};

interface FormattedTimeStampedMessage extends TimeStampedMessage {
  isCustomer: boolean;
  isNotDefault: boolean;
  transcriptId: string;
}

export const DialogTranscript = ({
  messages,
  refetch,
  showSavePrompt,
}: {
  messages: TimeStampedMessage[];
  refetch: () => Promise<void>;
  showSavePrompt?: boolean;
}) => {
  const [promptToSave, setPromptToSave] = useState('');
  const [messagesParsed, setMessagesParsed] = useState<
    FormattedTimeStampedMessage[]
  >([]);

  useEffect(() => {
    setMessagesParsed(
      messages.map((message, i) => {
        return {
          ...message,
          transcriptId: `transcript-${i}`,
          isCustomer: message.role === 'user',
          // do not suggest to the customer to re-save a default question
          isNotDefault:
            showSavePrompt ?? !defaultQuestions.includes(message.content),
          // ^ stretch goal, how to prevent re-saving existing questions... will need to pass in the whole list!
        };
      }),
    );
  }, [messages]);

  return (
    <>
      {promptToSave && (
        <ConfirmSave
          refetch={refetch}
          promptText={promptToSave}
          onCancel={() => setPromptToSave('')}
        />
      )}

      {messagesParsed.map((message) => {
        const {content, createdAt, isCustomer, isNotDefault, transcriptId} =
          message;

        const actionIcons =
          isCustomer && isNotDefault ? (
            <LegacyButton
              variant="outline"
              size="icon"
              onClick={() => setPromptToSave(content)}
            >
              <Save />
            </LegacyButton>
          ) : undefined;

        return (
          <div key={transcriptId} className="flex w-full">
            <ChatBubble
              timeStamp={createdAt}
              content={content}
              isLeft={isCustomer}
              isBeginning={true}
              isEnd={true}
              userTitle={isCustomer ? 'Customer' : 'TrusstGPT'}
              actionIcons={actionIcons}
            />
          </div>
        );
      })}
    </>
  );
};
