/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import AutoModeIcon from '@mui/icons-material/AutoMode';
import CodeIcon from '@mui/icons-material/Code';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import InsightsIcon from '@mui/icons-material/Insights';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import {getSiteURL} from './lib/get-site-url';
import type {PrimaryColor} from './styles/theme/types';
import type {NavItemConfig} from './types/nav';
/**
 * GENERAL CONFIG
 */
interface Config {
  site: {
    name: string;
    description: string;
    themeColor: string;
    primaryColor: PrimaryColor;
    url: string;
  };
  logLevel: 'ERROR';
}
export const config = {
  site: {
    name: 'TrusstAI',
    description: '',
    themeColor: '#090a0b',
    primaryColor: 'trusst',
    url: getSiteURL(),
  },
  logLevel: 'ERROR',
} satisfies Config;

/**
 * FEATURE FLAGS
 */
export type FeatureFlags = {
  devToolsEnabled: boolean;
  functionTrusstedAgent: boolean;
  ingestImportJobLanguageSelector: boolean;
};

export const featureFlagDefaults: FeatureFlags = {
  devToolsEnabled: false,
  functionTrusstedAgent: false,
  ingestImportJobLanguageSelector: false,
};

/**
 * LAYOUT CONFIG
 */
export type LayoutConfig = {
  navMainItems: NavItemConfig[];
  navSecondaryItems: NavItemConfig[];
};
export const layoutConfig = {
  navMainItems: [
    {
      key: 'dashboards',
      items: [
        {
          key: 'insights',
          title: 'Insights',
          href: '/analytics',
          icon: InsightsIcon,
          matcher: {type: 'startsWith', href: '/analytics'},
        },
        {
          key: 'contacts',
          title: 'Contacts',
          href: '/contacts',
          icon: FingerprintIcon,
          matcher: {type: 'startsWith', href: '/contacts'},
        },
        {
          key: 'evaluation',
          title: 'Evaluation',
          href: '/evaluation',
          icon: ThumbsUpDownIcon,
          matcher: {type: 'startsWith', href: '/evaluation'},
        },
        {
          key: 'prompts',
          title: 'Prompts',
          href: '/prompts',
          icon: AutoModeIcon,
          matcher: {type: 'startsWith', href: '/prompts'},
        },
        {
          key: 'agents',
          title: 'Agents',
          href: '/agents',
          icon: HeadsetMicIcon,
          matcher: {type: 'startsWith', href: '/agents'},
          featureFlag: 'functionTrusstedAgent',
        },
      ],
    },
  ],
  navSecondaryItems: [
    {
      key: 'extras',
      items: [
        {
          key: 'dev-tools',
          title: 'Dev Tools',
          href: '/dev',
          icon: CodeIcon,
          matcher: {type: 'startsWith', href: '/dev'},
          featureFlag: 'devToolsEnabled',
        },
        {
          key: 'user-guide',
          title: 'User Guide',
          external: true,
          href: 'https://docs.trusst.ai/product-guides/user-guide',
          icon: MenuBookIcon,
        },
      ],
    },
  ],
} satisfies LayoutConfig;
