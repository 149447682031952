/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ContactSynopsisElement} from '@agent-assist/api-typescript-react-query-hooks';
import {FC} from 'react';
import {SynopsisDiff} from './synopsis-diff';
import {SynopsisElements} from './synopsis-elements';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../ui/tabs';

interface SummaryChangesViewProps {
  readonly edited: ContactSynopsisElement[];
  readonly original: ContactSynopsisElement[];
}

export const SummaryChangesView: FC<SummaryChangesViewProps> = ({
  edited,
  original,
}) => {
  return (
    <Tabs defaultValue={'changes'}>
      <TabsList>
        <TabsTrigger value={'changes'}>Changes</TabsTrigger>
        <TabsTrigger value={'original'}>Original</TabsTrigger>
        <TabsTrigger value={'edited'}>Edited</TabsTrigger>
      </TabsList>
      <TabsContent
        value={'changes'}
        className={'pt-2 max-h-[450px] overflow-y-auto'}
      >
        <SynopsisDiff original={original} edited={edited} />
      </TabsContent>
      <TabsContent
        value={'original'}
        className={'pt-2 max-h-[450px] overflow-y-auto'}
      >
        <SynopsisElements synopsis={original} />
      </TabsContent>
      <TabsContent
        value={'edited'}
        className={'pt-2 max-h-[450px] overflow-y-auto'}
      >
        <SynopsisElements synopsis={edited} />
      </TabsContent>
    </Tabs>
  );
};
