/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import {ReactNode} from 'react';
import {TrusstGPTButton} from '../../MaterialUI/Button';
// TODO: think about when to disable the buttons
// combine two action bars on save and discard buttons

interface Props {
  onSave: () => void;
  saveDisabled: boolean;
  discardDisabled: boolean;
  onDiscard: () => void;
  children?: ReactNode;
}

export const CommonActionBar = ({
  onSave,
  saveDisabled,
  onDiscard,
  discardDisabled,
  children,
}: Props) => {
  return (
    <Box
      sx={{mt: 'auto', borderTop: 1, borderColor: 'divider', marginTop: '1rem'}}
    >
      <Grid container sx={{marginTop: '1rem'}}>
        <Grid size={6} sx={{display: 'flex', gap: 2}}>
          <TrusstGPTButton
            variant="contained"
            disabled={saveDisabled}
            onClick={() => onSave()}
            size="small"
            buttonText="Save"
          />
          <TrusstGPTButton
            variant="outlined"
            disabled={discardDisabled}
            onClick={() => onDiscard()}
            size="small"
            buttonText="Discard Changes"
          />
        </Grid>
        <Grid size={6} sx={{display: 'flex', justifyContent: 'end', gap: 2}}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
