/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ContactDetailsFilter,
  useGetBatchContactImport,
  useGetPromptRevision,
  useGetPublishedPromptRevision,
  useGetQAAnalyticsWithFilters,
} from '@agent-assist/api-typescript-react-query-hooks';

import {Box, Card, CardContent} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

// TODO fix these imports
import {QAAnalyticsResults} from '../../../../../api/generated/runtime/typescript/lib/models/QAAnalyticsResults';

import {LoadingSpinner} from '../../../components/LoadingSpinner';
import ExpandableRowTable from '../../../components/QAAnalytics/QAExpandableDataGrid-datatables';
import {
  ContactImportIds,
  SelectContactImport,
} from '../../../components/SelectContactImport/SelectContactImport';
import {PageContainer} from '../../../components/ui/page';
import {localTimeToDatabaseTime} from '../../../lib/formatDate';
import {
  setPublishedPromptRevision,
  usePromptDispatch,
} from '../../../providers/PromptProvider';
import {useContactImportsStore} from '../../../stores/contactImportsStore';
import {cleanseFilters} from '../../contact-import/cleanseFilters';
import {columns} from '../../contact-import/columns';
import {
  FilterOptionsComplete,
  type OnFilterParams,
} from '../../contact-import/FilterOptions';
import {parseSearchParams, updateFilters} from '../../contact-import/filters';

const availableFilters = columns.filter(({key}) =>
  [
    'categorypd',
    'sub-Categorypd',
    'sub-Sub-Categorypd',
    'queueIds',
    'initialDirection',
  ].includes(key),
);

export const QAAnalytics = () => {
  const navigate = useNavigate();
  const params = useParams();
  const paramContactImportId = params.contactImportId;
  const [contactImportId, setContactImportId] = useState<string>();
  const contactImports = useContactImportsStore(
    (state) => state.contactImports,
  );

  useEffect(() => {
    if (paramContactImportId) {
      setContactImportId(paramContactImportId);
    }
  }, [paramContactImportId]);

  useEffect(() => {
    if (!paramContactImportId && !contactImportId && contactImports.length) {
      setContactImportId(contactImports[0].contactImportId);
    }
  }, [paramContactImportId, contactImports, contactImportId]);

  const [search, setSearch] = useSearchParams();
  const [filters, setFilters] = useState<ContactDetailsFilter[]>(
    parseSearchParams(search).array,
  );

  const onFilter = (paramOrParams: OnFilterParams) => {
    const newFilters = updateFilters([search, setSearch], paramOrParams);
    setFilters(newFilters);
  };

  if (!contactImportId) {
    return (
      <Box
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  const handleContactImportIdChanged = (contactImport: ContactImportIds) => {
    navigate(`/evaluation/${contactImport.contactImportId}`);
  };

  const actions = (
    <SelectContactImport
      handleContactImportIdChanged={handleContactImportIdChanged}
      initialValue={contactImportId}
    />
  );

  return (
    <PageContainer title="Evaluation" actions={actions}>
      <Card>
        <CardContent>
          <FilterOptionsComplete
            availableFilters={availableFilters}
            contactImportId={contactImportId}
            onFilter={onFilter}
            activeFilters={filters}
          />
        </CardContent>
      </Card>
      <CustomExpandableRow
        contactImportId={contactImportId}
        filters={filters}
      />
    </PageContainer>
  );
};

const CustomExpandableRow = ({
  contactImportId,
  filters,
}: {
  contactImportId: string;
  filters: ContactDetailsFilter[];
}) => {
  const contactImportJob = useGetBatchContactImport(
    {contactImportId},
    {
      enabled: !!contactImportId,
    },
  );

  const [data, setData] = useState<QAAnalyticsResults>();
  const dispatch = usePromptDispatch();

  const promptRevision = contactImportJob.data?.promptRevisionId
    ? useGetPromptRevision({
        promptRevisionId: contactImportJob.data.promptRevisionId,
      })
    : useGetPublishedPromptRevision();

  const {mutateAsync, isLoading, isSuccess} = useGetQAAnalyticsWithFilters();

  useEffect(() => {
    if (contactImportId) {
      const {filters: cleansedFilters, from, to} = cleanseFilters(filters);
      const getData = async () => {
        const result = await mutateAsync({
          contactImportId,
          getQAAnalyticsWithFiltersRequestContent: {
            filters: cleansedFilters,
            from: localTimeToDatabaseTime(from),
            to: localTimeToDatabaseTime(to),
          },
        });

        setData(result.analytics);
      };
      void getData();
    }
  }, [contactImportId, filters]);

  useEffect(() => {
    if (promptRevision.data) {
      setPublishedPromptRevision(dispatch, promptRevision.data);
    }
  }, [promptRevision.data]);

  return (
    <ExpandableRowTable
      data={data}
      isLoading={isLoading}
      isSuccess={isSuccess}
      contactImportId={contactImportId}
    />
  );
};
