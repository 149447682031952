/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {PromptQuestion} from '@agent-assist/api-typescript-react-query-hooks';
import {defaultPromptQuestionProperties} from '@agent-assist/common/lib/inference/default-prompts';
import {Plus, X} from 'lucide-react';
import React, {FC, useCallback, useMemo} from 'react';

import {promptTypeOptions} from '../QualityAssurance/QuestionListItem';
import {Input} from '../ui/input';
import {Label} from '../ui/label';
import {LegacyButton} from '../ui/legacy-button';
import {SelectBasic} from '../ui/select';
import {Textarea} from '../ui/textarea';

export interface PromptFormData {
  readonly questions: PromptQuestion[];
}

interface PromptPlaygroundFormProps {
  readonly onChange: React.Dispatch<React.SetStateAction<PromptFormData>>;
  readonly data: PromptFormData;
  readonly disabled?: boolean;
}

const MAX_QUESTIONS = 40;

export const PromptPlaygroundForm: FC<PromptPlaygroundFormProps> = ({
  data,
  onChange,
  disabled,
}) => {
  const updateQuestion = useCallback(
    (i: number, property: keyof PromptQuestion, value: string) => {
      onChange((prev) => ({
        ...prev,
        questions: [
          ...prev.questions.slice(0, i),
          {
            ...prev.questions[i]!,
            [property]: value,
          },
          ...prev.questions.slice(i + 1),
        ],
      }));
    },
    [onChange],
  );

  const canAddQuestion = useMemo(() => {
    if (data.questions.length >= MAX_QUESTIONS) {
      return false;
    }
    if (
      data.questions.length > 0 &&
      (!data.questions[data.questions.length - 1]?.key ||
        !data.questions[data.questions.length - 1]?.question)
    ) {
      return false;
    }
    return true;
  }, [data]);

  return (
    <div className={'flex flex-1 flex-col'}>
      <div className={'grid grid-cols-12 gap-3 mr-1 mb-3 items-start'}>
        <div className={'flex flex-col gap-y-2 col-span-3'}>
          <Label>Title</Label>
          <p className={'text-muted-foreground text-xs'}>
            Title of the prompt section
          </p>
        </div>
        <div className={'flex flex-col gap-y-2 col-span-6'}>
          <Label>Insights</Label>
          <p className={'text-muted-foreground text-xs'}>
            Response to the prompt
          </p>
        </div>
        <div className={'flex flex-col gap-y-2 col-span-2'}>
          <Label>Prompt Type</Label>
          <p className={'text-muted-foreground text-xs'}></p>
        </div>
        <div className={'flex flex-col gap-y-2 col-span-1'} />
      </div>
      {data.questions.map((q, i) => (
        <div className={'grid grid-cols-12 gap-x-2 gap-y-2 mr-1'} key={i}>
          <div className={'flex flex-col gap-y-2 gap-x-3 mb-3 col-span-3'}>
            <Input
              disabled={disabled}
              onChange={(e) => updateQuestion(i, 'key', e.target.value)}
              value={q.key}
            />
          </div>
          <div className={'flex flex-col gap-y-2 gap-x-3 mb-3 col-span-6'}>
            <Textarea
              disabled={disabled}
              onChange={(e) => updateQuestion(i, 'question', e.target.value)}
              value={q.question}
            />
          </div>
          <div className={'flex flex-row gap-y-2 mb-3 col-span-2'}>
            <SelectBasic
              disabled={disabled}
              onValueChange={(value: string) =>
                updateQuestion(i, 'promptType', value)
              }
              options={promptTypeOptions}
              placeholder="Prompt Type"
              value={q.promptType}
            />
          </div>
          <div className={'flex flex-col gap-y-5 mb-3 col-span-1'}>
            <LegacyButton
              variant={'ghost'}
              size={'icon'}
              onClick={() =>
                onChange((prev) => ({
                  ...prev,
                  questions: [
                    ...prev.questions.slice(0, i),
                    ...prev.questions.slice(i + 1),
                  ],
                }))
              }
            >
              <X />
            </LegacyButton>
          </div>
        </div>
      ))}
      <LegacyButton
        disabled={disabled || !canAddQuestion}
        variant={'outline'}
        className={'w-36 gap-x-1 self-center my-5'}
        size={'sm'}
        onClick={() =>
          onChange((prev) => ({
            ...prev,
            questions: [
              ...prev.questions,
              {
                key: '',
                id: '1',
                promptType: 'synthesis',
                question: '',
                properties: defaultPromptQuestionProperties,
              },
            ],
          }))
        }
      >
        <Plus size={18} />
        <p>Add Question</p>
      </LegacyButton>
    </div>
  );
};
