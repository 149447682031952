/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  Contact,
  ContactSynopsisElement,
} from '@agent-assist/api-typescript-react-query-hooks';

export const getSynopsesKeys = (
  contact: Contact,
  columnName: string,
): ContactSynopsisElement | undefined => {
  const contactSynopses = contact.summary?.production?.synopsis;
  return (
    contactSynopses &&
    contactSynopses.find(
      (x: ContactSynopsisElement | undefined) => x && x.key === columnName,
    )
  );
};

export const getSynopsesKeyResponse = (
  contact: Contact,
  columnName: string,
): string => {
  return getSynopsesKeys(contact, columnName)?.response ?? 'Other';
};
