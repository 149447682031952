/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

// TODO rename this file, and some of the types
import {format} from 'date-fns';
import humanizeDuration from 'humanize-duration';
import memoize from 'lodash/memoize';
import {PhoneIncoming, PhoneOutgoing} from 'lucide-react';
import {Link} from 'react-router-dom';

import StatusIndicator, {
  StatusTypeEnum,
} from '../../components/StatusIndicator';
import {shortenUuid} from '../../lib/formatString';

export type ColumnSettingType = 'ColumnSettingText' | 'ColumnSettingDate';

export type ColumnSetting = {
  isMeta: boolean;
  isVisible: boolean;
  key: string;
  label: string;
  shortKey: string; // for URL
  type: ColumnSettingType;
};

export const columns: ColumnSetting[] = [
  // locked in:
  {key: 'contactId', label: 'Contact ID'},

  {key: 'status', label: 'Status'},
  {key: 'intentpd', shortKey: 'intent', label: 'Intent'},
  {key: 'categorypd', shortKey: 'cat1', label: 'Category 1'},
  {key: 'sub-Categorypd', shortKey: 'cat2', label: 'Category 2'},
  {key: 'sub-Sub-Categorypd', shortKey: 'cat3', label: 'Category 3'},
  {
    key: 'startTime',
    label: 'Started at',
    isMeta: true,
    type: 'ColumnSettingDate',
  },
  {key: 'endTime', label: 'Ended at', isMeta: true, type: 'ColumnSettingDate'},

  // optional:
  {key: 'userIds', label: 'User ID', isMeta: true},
  {key: 'initialDirection', label: 'Direction', isMeta: true},

  // { key: "conversationEndTime", label: "End time" }, // (shown as dd/mmm/yyyy - e.g. 10/Jan/2024)
  {key: 'queueIds', label: 'Queue ID', isMeta: true},
  {key: 'durationMs', label: 'Duration', isMeta: true},
  {
    key: 'softSkillsTotalScore',
    shortKey: 'ssts',
    label: 'Soft Skills (%)',
    isMeta: true,
  },
  {
    key: 'complianceTotalScore',
    shortKey: 'cts',
    label: 'Compliance (%)',
    isMeta: true,
  },
].map((v) => ({
  ...v,
  isMeta: v.isMeta ?? false,
  isVisible: true,
  shortKey: v.shortKey ?? v.key,
  type: (v.type ?? 'ColumnSettingText') as ColumnSettingType,
}));

const EmptyCell = () => (
  <span className="flex w-full text-accent justify-center">-</span>
);

const standardDate = (date?: string) => {
  if (!date) return <EmptyCell />;
  try {
    return <>{format(new Date(date), 'd MMM yy, h:mm aaa')}</>;
  } catch (error) {
    console.log(`Error in converting date: ${date}`);
    return <EmptyCell />;
  }
};

const renderArray = (arr?: string) => {
  if (!arr) return <EmptyCell />;
  return JSON.parse(arr).map(shortenUuid);
};

export const defaultRenderer = (str?: string) => str ?? <EmptyCell />;

const DirectionIndicator = ({direction}: {direction: string}) => {
  switch (direction) {
    case 'inbound':
      return <PhoneIncoming className="text-primary inline-block" />;
    case 'outbound':
      return <PhoneOutgoing className="text-green-500 inline-block" />;
    default:
      return <EmptyCell />;
  }
};

export const cellRenderers = memoize((baseUrl) => {
  return new Map([
    [
      'contactId',
      (id: string) => (
        <Link to={`${baseUrl}/contact/${id}`}>{shortenUuid(id)}</Link>
      ),
    ],
    [
      'status',
      (status: string) => <StatusIndicator status={status as StatusTypeEnum} />,
    ],
    [
      'durationMs',
      (ms: string) => {
        if (typeof ms === 'undefined') return <EmptyCell />;
        return <>{humanizeDuration(Number(ms), {largest: 2, round: true})}</>;
      },
    ],
    [
      'initialDirection',
      (dir: string) => <DirectionIndicator direction={dir} />,
    ],
    ['startTime', standardDate],
    ['endTime', standardDate],
    ['queueIds', renderArray],
    ['userIds', renderArray],
  ]);
});
