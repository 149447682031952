/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

// based off https://github.com/hello-pangea/dnd/blob/main/stories/src/simple/simple-scrollable.tsx

import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableStyle,
  DropResult,
} from '@hello-pangea/dnd';
import {ReactElement, useEffect, useState} from 'react';

// provided from the above example:
const reorder = <TList extends unknown[]>(
  list: TList,
  startIndex: number,
  endIndex: number,
): TList => {
  const result = Array.from(list) as TList;
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggableStyle = {},
) => ({
  userSelect: 'none' as const,
  // change background colour if dragging
  background: isDragging ? 'hsl(var(--primary))' : 'none',
  padding: 4, // 0.5rem or our tailwind's p-2
  borderRadius: 2,
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'hsl(var(--muted))' : 'none',
});

interface Props {
  items: DraggableItem[];
  onOrderUpdated: (items: string[]) => void;
  disabled?: boolean;
}

interface DraggableItem {
  id: string;
  content: ReactElement;
  isParent?: boolean;
  childId?: string;
}

// the main ui component DraggableList doesn't manipulate your data, it returns a list of ids.
// do with that list what you will, perhaps use this:
export const sortItemsFromId = <T extends {id: string}>(
  sortedIds: string[],
  unsortedItems: T[],
): T[] => {
  const sortedItems = sortedIds.map((sortedId) => {
    const item = unsortedItems.find(({id}) => id === sortedId);
    if (!item) throw new Error('could not find item from list'); // cannot be possible?
    return item;
  });
  return sortedItems;
};

export const DraggableList = ({
  disabled,
  items: initialDraggableItems,
  onOrderUpdated,
}: Props) => {
  const [items, setItems] = useState<DraggableItem[]>(initialDraggableItems);
  // console.log("---items", items)
  useEffect(() => {
    setItems(initialDraggableItems);
  }, [initialDraggableItems]);

  /*
  useEffect(() => {
    onOrderUpdated(items.map(({ id }) => id));
  }, [items.map(({ id }) => id).join("/")]);
  */

  /*
 Expected Behaviour for DnD:
  - Draggable item is child of a CQA prompt:
    it should move the parent (CQA prompt) with it
  - Draggable item is a CQA prompt:
    it should move the child with CQA prompt
  - Dragging an item between CQA prompt and its child:
  Note: you can not drop in between parent and child
    if dragging an item from above CQA prompt, it would drop it after Child
    if dragging an item from after CQA prompt child, it would drop it before CQA prompt

*/

  // TODO: For readability put each scenario in a helper function
  // TODO: Once fully tested, then clean up the console logs
  const onDragEnd = (result: DropResult) => {
    console.log('onDragEnd');
    let newDraggableItems: DraggableItem[] = [];
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const draggedItem = items[sourceIndex];
    const movingUpwards = destinationIndex < sourceIndex;
    // droppable item is parent
    if (draggedItem.isParent) {
      console.log(
        '---draggedItem is parent',
        draggedItem.isParent,
        sourceIndex,
        destinationIndex,
      );
      newDraggableItems = reorder(items, sourceIndex, destinationIndex);
      if (movingUpwards) {
        console.log('---moving child upwards', movingUpwards);
        newDraggableItems = reorder(
          newDraggableItems,
          sourceIndex + 1,
          destinationIndex + 1,
        );
      } else {
        console.log(
          '---moving child downwards',
          sourceIndex,
          destinationIndex,
          movingUpwards,
        );
        newDraggableItems = reorder(
          newDraggableItems,
          sourceIndex,
          destinationIndex,
        );
      }
      setItems(newDraggableItems);
      setTimeout(
        () => onOrderUpdated(newDraggableItems.map(({id}) => id)),
        100,
      );
      return;
    }
    // droppable item is child
    if (!draggedItem.isParent && draggedItem.childId) {
      console.log(
        '---draggedItem is child',
        !draggedItem.isParent && draggedItem.childId,
      );
      // Move the draggable item
      newDraggableItems = reorder(items, sourceIndex, destinationIndex);
      if (movingUpwards) {
        console.log('---moving parent upwards', movingUpwards);
        newDraggableItems = reorder(
          newDraggableItems,
          sourceIndex,
          destinationIndex,
        );
      } else {
        console.log('---moving parent downwards', movingUpwards);
        newDraggableItems = reorder(
          newDraggableItems,
          sourceIndex - 1,
          destinationIndex - 1,
        );
      }
      setItems(newDraggableItems);
      setTimeout(
        () => onOrderUpdated(newDraggableItems.map(({id}) => id)),
        100,
      );
      return;
    }
    // dropping between child and parent
    console.log('-----source and index', sourceIndex, destinationIndex);
    const itemBeforeDestination = items[destinationIndex];
    console.log('-----itemBeforeDestination', itemBeforeDestination);
    if (
      itemBeforeDestination &&
      (itemBeforeDestination.isParent || itemBeforeDestination.childId)
    ) {
      console.log(
        '---dragging between parent-child',
        itemBeforeDestination &&
          (itemBeforeDestination.isParent || itemBeforeDestination.childId),
      );

      if (movingUpwards) {
        console.log('---moving moving above parent', movingUpwards);
        if (destinationIndex - 1 < 0) {
          console.log(
            '---parent is first in the list',
            destinationIndex - 1 < 0,
          );
          newDraggableItems = reorder(items, sourceIndex, 0);
        } else {
          console.log('--- moving above parent at index', destinationIndex - 1);
          newDraggableItems = reorder(items, sourceIndex, destinationIndex - 1);
        }
      } else {
        console.log(
          '--- moving below child at index',
          newDraggableItems,
          destinationIndex + 1,
        );
        newDraggableItems = reorder(items, sourceIndex, destinationIndex + 1);
        console.log('--- newDraggableItems', newDraggableItems);
      }
      setItems(newDraggableItems);
      setTimeout(
        () => onOrderUpdated(newDraggableItems.map(({id}) => id)),
        100,
      );
      return;
    }
    // Move the draggable item
    console.log('---moving default behaviour');
    newDraggableItems = reorder(items, sourceIndex, destinationIndex);
    setItems(newDraggableItems);
    setTimeout(() => onOrderUpdated(newDraggableItems.map(({id}) => id)), 100);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" isDropDisabled={disabled}>
        {(droppableProvided, droppableSnapshot) => (
          <div
            ref={droppableProvided.innerRef}
            style={getListStyle(droppableSnapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                isDragDisabled={disabled}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    id={item.id}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={getItemStyle(
                      draggableSnapshot.isDragging,
                      draggableProvided.draggableProps.style,
                    )}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
