/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  AnalyticsGraphCard,
  type AnalyticsGraphCardProps,
} from './AnalyticsGraphCard';
import {formatPercentage} from '../../lib/formatNumber';
interface SentimentGraphProps extends AnalyticsGraphCardProps {
  data: {
    name: string;
    numcontacts: number; // raw
    value: number; // percent
  }[];
}

const sentimentRange = Array(11)
  .fill(0)
  .map((_, i) => {
    return i - 5;
  });

const HEIGHT = 170;

const SentimentGraph: React.FC<SentimentGraphProps> = ({data, ...props}) => {
  return (
    <AnalyticsGraphCard {...props} minHeight="min-h-[200px]">
      <div
        className="grid grid-cols-11 w-full h-[140px] text-center relative"
        data-testid={`sentiment-graph-${props.title}`}
      >
        {sentimentRange.map((sentimentValue, si) => {
          const count = data.find(
            (result) => result.name === String(sentimentValue),
          );

          const displayCount = count?.value || 0;

          // render bar of bargaph:
          return (
            <div
              key={`bar-${si}`}
              style={{
                height: HEIGHT,
                position: 'relative',
                borderBottom: '1px solid hsl(var(--card-high-border))',
              }}
            >
              <div
                style={{
                  width: 'calc(100% - 4px)',
                  background: `hsl(${(sentimentValue + 5) * 12} 45% 50%)`,
                  borderRadius: '3px 3px 0 0',
                  height: displayCount,
                  top: HEIGHT - displayCount,
                  left: 2,
                  position: 'absolute',
                }}
              ></div>
              {displayCount ? (
                <div
                  style={{
                    color: 'hsla(var(--text-muted-foreground))',
                    width: '100%',
                    height: 20,
                    top: HEIGHT - 15 - displayCount,
                    position: 'absolute',
                    fontSize: 10,
                  }}
                >
                  {formatPercentage(displayCount, 0)}
                </div>
              ) : null}
            </div>
          );
        })}
        {
          // render labels:
          sentimentRange.map((sentimentValue, si) => (
            <div
              key={`label-${si}`}
              style={{
                color: 'hsla(var(--text-muted-foreground))',
                fontSize: 12,
              }}
            >
              {sentimentValue}
            </div>
          ))
        }
      </div>
    </AnalyticsGraphCard>
  );
};

export default SentimentGraph;
