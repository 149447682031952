/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ContactImport,
  useListBatchContactImports,
} from '@agent-assist/api-typescript-react-query-hooks';

import {OptionDefinition} from '@cloudscape-design/components/internal/components/option/interfaces';

import React from 'react';

import InfiniteSelect from '../InfiniteSelect/InfiniteSelect';

interface ContactImportOption extends OptionDefinition {
  promptRevisionId: string;
}

export interface ContactImportIds {
  contactImportId: string;
  promptRevisionId: string;
}

interface SelectContactImportProps {
  handleContactImportIdChanged: (
    contactImport: ContactImportIds,
    options: ContactImportOption[],
  ) => void;
  initialValue?: string;
}
export const contactToOption = (
  contactImport: ContactImport,
): ContactImportOption => {
  return {
    value: contactImport.contactImportId,
    label: contactImport.name,
    promptRevisionId: contactImport.promptRevisionId,
  };
};

export const SelectContactImport = ({
  handleContactImportIdChanged,
  initialValue,
}: SelectContactImportProps) => {
  const listContactImports = useListBatchContactImports({
    year: new Date().getFullYear(),
    pageSize: 100,
  });

  const options = React.useMemo(() => {
    return (
      listContactImports.data?.pages?.flatMap?.((p: any) => p.imports) ?? []
    )
      .filter(
        (option) =>
          option.status === 'SUCCESS' ||
          option.contactImportId === initialValue,
      )
      .map(contactToOption);
  }, [listContactImports]);

  return (
    <InfiniteSelect
      placeholder={'Contact Imports'}
      query={listContactImports}
      options={options}
      itemsKey="imports"
      toOption={contactToOption}
      onChange={(contactImportId: string) => {
        const promptRevisionId = options.find(
          (contact) => contact.value === contactImportId,
        )?.promptRevisionId;
        if (!promptRevisionId)
          throw new Error(
            `cannot find promptRevisionId for contactImportId:${contactImportId}`,
          );
        handleContactImportIdChanged(
          {contactImportId, promptRevisionId},
          options,
        );
      }}
      emptyLabel={'No Contact Imports'}
      initialValue={initialValue}
    />
  );
};
