/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import AddIcon from '@mui/icons-material/Add';
import {MenuItem, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import ToolParameterTable from './ToolParametersTable';
import {TrusstGPTButton} from '../../MaterialUI/Button';
import TrusstGPTTextField from '../../MaterialUI/TextField';
import {AgentToolInput, InputSchema} from '../Types/types';

interface Props {
  tool?: AgentToolInput;
  onUpdateTool: (updatedTool: AgentToolInput) => void;
}

const ManageToolParameters = ({tool, onUpdateTool}: Props) => {
  const typeOptions = [
    {label: 'Integer', value: 'Integer'},
    {label: 'Boolean', value: 'Boolean'},
    {label: 'String', value: 'String'},
  ];
  const defaultParameter = {
    name: '',
    description: '',
    type: '',
    required: false,
  };
  const [parameter, setParameter] = useState<InputSchema>(defaultParameter);
  const handleChangeParameter = (param: InputSchema) => {
    setParameter(param);
  };

  const disableAddParameter =
    parameter?.name === '' ||
    parameter?.description === '' ||
    parameter?.type === '';
  return (
    <Grid container>
      <Box sx={{margin: '2rem 0 2rem 0', height: '56px'}}>
        <Typography variant="body1">Add Parameters</Typography>
        <Typography variant="caption">
          Add custom parameters that the API will use
        </Typography>
      </Box>
      <Grid container spacing={1} alignItems="center" size={12}>
        <Grid size={3}>
          <TrusstGPTTextField
            value={parameter?.name || ''}
            variant="outlined"
            onChange={(event) =>
              handleChangeParameter({
                ...parameter,
                name: event.target.value,
              })
            }
            placeholder="Name"
          />
        </Grid>
        <Grid size={4}>
          <TrusstGPTTextField
            value={parameter?.description || ''}
            variant="outlined"
            onChange={(event) =>
              handleChangeParameter({
                ...parameter,
                description: event.target.value,
              })
            }
            placeholder="Description"
          />
        </Grid>
        <Grid size={3}>
          <TextField
            value={parameter?.type || typeOptions[0].value}
            onChange={(value) =>
              handleChangeParameter({
                ...parameter,
                type: value.target.value,
              })
            }
            select
            label="Type"
            sx={{width: '100%', marginBottom: '.5rem'}}
          >
            {typeOptions.map(({label, value}) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid size={1} sx={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="caption">Required</Typography>
          <Checkbox
            checked={parameter.required}
            onChange={(event) =>
              handleChangeParameter({
                ...parameter,
                required: event.target.checked,
              })
            }
          />
        </Grid>
        <Grid size={1}>
          <TrusstGPTButton
            disabled={disableAddParameter}
            onClick={() => {
              const updatedParams = tool?.parameters.concat(parameter);
              const updatedTool = {
                name: tool?.name || '',
                description: tool?.description || '',
                parameters: updatedParams || [],
              };
              onUpdateTool(updatedTool);
              setParameter(defaultParameter);
            }}
            variant="outlined"
            endIcon={<AddIcon />}
            buttonText="Add"
          />
        </Grid>
      </Grid>

      <ToolParameterTable tool={tool} onHandleDelete={onUpdateTool} />
    </Grid>
  );
};

export default ManageToolParameters;
