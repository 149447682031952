/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ReactNode, useCallback, useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import {Input, InputItem} from '../../components/ui/input';
import {Label} from '../../components/ui/label';
import {LegacyButton} from '../../components/ui/legacy-button';
import {
  setPromptEditMode,
  usePromptDispatch,
  usePromptState,
} from '../../providers/PromptProvider';

interface PromptCreateModalProps {
  readonly onConfirm: () => Promise<void>;
  readonly name: string;
  readonly onChangeName: (name: string) => void;
}

interface PromptCreateModal {
  readonly modal: ReactNode;
  readonly show: () => void;
  readonly hide: () => void;
}

export const usePromptCreateModal = ({
  onConfirm,
  name,
  onChangeName,
}: PromptCreateModalProps): PromptCreateModal => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const {isPromptEditMode} = usePromptState();
  const show = useCallback(
    () => setIsConfirmVisible(true),
    [setIsConfirmVisible],
  );
  const hide = useCallback(
    () => setIsConfirmVisible(false),
    [setIsConfirmVisible],
  );
  const dispatch = usePromptDispatch();
  const trimmedName = name?.trim();
  const isNameInvalid = !trimmedName || trimmedName.length === 0;

  return {
    modal: (
      <Dialog open={isConfirmVisible} onOpenChange={(open) => !open && hide()}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create Prompt Revision</DialogTitle>
          </DialogHeader>
          <InputItem>
            <Label>Name</Label>
            <p className={'text-sm text-muted-foreground'}>
              Enter a name for the prompt revision
            </p>
            <Input
              value={name}
              onChange={(e) => onChangeName(e.target.value)}
            />
            <p className={'text-xs text-muted-foreground mt-3'}>
              Please note any in-progress contact imports will use your original
              prompt.
            </p>
          </InputItem>
          <DialogFooter>
            <LegacyButton variant="ghost" onClick={hide}>
              Cancel
            </LegacyButton>
            <LegacyButton
              onClick={async () => {
                setIsConfirming(true);
                await onConfirm();
                setIsConfirming(false);
                setPromptEditMode(dispatch, false);
                hide();
              }}
              disabled={isNameInvalid}
              isLoading={isConfirming}
            >
              {isPromptEditMode ? 'Save' : 'Create'}
            </LegacyButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    ),
    show,
    hide,
  };
};
