/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

type MessageChunkFormat = 'bulletpoint' | 'json' | 'string';
export type MessageChunk = {
  fmt: MessageChunkFormat;
  value: string;
};

/*
attempts to extract any bullet points or json nested inside a string correctly so they can be rendered suitable by react
See required input formats in associated tests.
React rendering is not provided here!
*/

export const formatMessage = (st: string): MessageChunk[] => {
  return st.split('\n').map(formatLine).flat();
};

const formatLine = (st: string): MessageChunk | MessageChunk[] => {
  const rgxBulletPoint = /^\d+\./g;

  if (st.match(rgxBulletPoint)) {
    return {fmt: 'bulletpoint', value: st};
  }

  // primitive regex attempt to find json:
  const rgxJsonObject = /({"[\s\S]+?"})/g; // objects like `{"something":"ok"}`
  const rgxJsonArray = /(\[[\s\S]+?\])/g; // arrays like `[1,2,"anything"]`

  const getFormat = (value: string): MessageChunkFormat => {
    try {
      JSON.parse(value);
      return 'json';
    } catch (e) {
      // if JSON.parse errored it's obviously not json
      return 'string';
    }
  };

  const appendOrPush = (matches: MessageChunk[], value: string) => {
    // this fn mutates matches
    const lastChunk = matches.at(-1);
    if (lastChunk?.fmt === 'string') {
      // if the previous chunk was a string, we've got a false positive for json, so append it to the previous string...
      lastChunk.value += value;
    } else {
      matches.push({fmt: 'string', value});
    }
  };

  let i = 0;
  const extractMatches = (rgx: RegExp): MessageChunk[] => {
    const matches: MessageChunk[] = [];
    i = 0;
    let match;
    while ((match = rgx.exec(st)) !== null) {
      // json chunk has been found between `start` -> `end`:
      const value = match[0],
        start = match.index,
        end = rgx.lastIndex;

      if (start > i) {
        // string found, add it:
        const string = st.slice(i, start);
        appendOrPush(matches, string);
      }

      // confirm json:
      const fmt = getFormat(value);
      if (fmt === 'string') {
        // it is not actually json!
        appendOrPush(matches, value);
      } else {
        matches.push({fmt, value});
      }
      i = end;
    }
    return matches;
  };

  // this currently only handles one OR the other: a string with [] or {} like json...
  let matches = extractMatches(rgxJsonArray);
  if (!matches.length) matches = extractMatches(rgxJsonObject);

  if (matches.length) {
    // json has been found, return the matches...

    // but first, check trailing chrs...
    if (i < st.length) {
      // trailing string exists, add it:
      const value = st.slice(i);
      appendOrPush(matches, value);
    }

    return matches;
  }
  // else no json, return "plain" version:

  return {fmt: 'string', value: st};
};
