/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import type {PaletteRange} from '@mui/material/styles/createPalette';

export const california = {
  50: '#fffaea',
  100: '#fff3c6',
  200: '#ffe587',
  300: '#ffd049',
  400: '#ffbb1f',
  500: '#fb9c0c',
  600: '#de7101',
  700: '#b84d05',
  800: '#953b0b',
  900: '#7b310c',
  950: '#471701',
} satisfies PaletteRange;

export const kepple = {
  50: '#f0fdfa',
  100: '#ccfbef',
  200: '#9af5e1',
  300: '#5fe9ce',
  400: '#2ed3b8',
  500: '#15b79f',
  600: '#0e9382',
  700: '#107569',
  800: '#115e56',
  900: '#134e48',
  950: '#042f2c',
} satisfies PaletteRange;

export const trusst = {
  50: 'hsl(321, 42%, 95%)',
  100: 'hsl(321, 42%, 90%)',
  200: 'hsl(321, 42%, 80%)',
  300: 'hsl(321, 42%, 70%)',
  400: 'hsl(321, 42%, 60%)',
  500: 'hsl(321, 42%, 62%)',
  600: 'hsl(321, 42%, 55%)',
  700: 'hsl(321, 42%, 45%)',
  800: 'hsl(321, 42%, 35%)',
  900: 'hsl(321, 42%, 25%)',
  950: 'hsl(321, 42%, 15%)',
} satisfies PaletteRange;

export const trusstSecondary = {
  50: 'hsl(278, 26%, 95%)',
  100: 'hsl(278, 26%, 90%)',
  200: 'hsl(278, 26%, 80%)',
  300: 'hsl(278, 26%, 70%)',
  400: 'hsl(278, 26%, 60%)',
  500: 'hsl(278, 26%, 50%)',
  600: 'hsl(278, 26%, 45%)',
  700: 'hsl(278, 26%, 35%)',
  800: 'hsl(278, 26%, 25%)',
  900: 'hsl(278, 26%, 15%)',
  950: 'hsl(278, 26%, 5%)',
} satisfies PaletteRange;

export const nevada = {
  50: '#fbfcfe',
  100: '#f0f4f8',
  200: '#dde7ee',
  300: '#cdd7e1',
  400: '#9fa6ad',
  500: '#636b74',
  600: '#555e68',
  700: '#32383e',
  800: '#202427',
  900: '#121517',
  950: '#090a0b',
} satisfies PaletteRange;

export const redOrange = {
  50: '#fef3f2',
  100: '#fee4e2',
  200: '#ffcdc9',
  300: '#fdaaa4',
  400: '#f97970',
  500: '#f04438',
  600: '#de3024',
  700: '#bb241a',
  800: '#9a221a',
  900: '#80231c',
  950: '#460d09',
} satisfies PaletteRange;

export const shakespeare = {
  50: '#ecfdff',
  100: '#cff7fe',
  200: '#a4eefd',
  300: '#66e0fa',
  400: '#10bee8',
  500: '#04aad6',
  600: '#0787b3',
  700: '#0d6d91',
  800: '#145876',
  900: '#154964',
  950: '#082f44',
} satisfies PaletteRange;

export const stormGrey = {
  50: '#f9fafb',
  100: '#f1f1f4',
  200: '#dcdfe4',
  300: '#b3b9c6',
  400: '#8a94a6',
  500: '#667085',
  600: '#565e73',
  700: '#434a60',
  800: '#313749',
  900: '#212636',
  950: '#121621',
} satisfies PaletteRange;
