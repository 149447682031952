/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {DEFAULT_PROMPT_ID} from '../lib/constants';
import {shortenUuid, wordToTitleCase} from '../lib/formatString';

const titleCase = (sentence: string, delimiter = ' ') => {
  const shortUuid = shortenUuid(sentence);
  // Don't title case it if it has a uuid. This is to preserve IDs in the URL.
  return (
    (
      shortUuid === sentence // if it matches the original, it doesn't contain a uuid.
        ? sentence
            .split(delimiter)
            .map((w) => wordToTitleCase(w))
            .join(' ')
        : shortUuid
    )
      // replace specific slugs with more human readable titles:
      .replace(DEFAULT_PROMPT_ID, 'Default Prompt')
      .replace('Prompts', 'Prompt Revisions')
  );
};

export const useDocumentTitle = () => {
  const location = useLocation();
  const [rootPath] = useMemo(
    () => location.pathname.split('/').filter(Boolean),
    [location],
  );
  useEffect(() => {
    rootPath && (document.title = `${titleCase(rootPath)} - TrusstAI`);
  }, [rootPath]);
};
