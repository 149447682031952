/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Tab} from '@mui/material';
import {useState} from 'react';
import {GraphWithHook} from './GraphWithHook';
import {HierBarWithHook} from './HierBarWithHook';
import {AnalyticHookProps} from './types';

interface Props {
  commonProps: AnalyticHookProps;
}

export const AIInsightClassifications = ({commonProps}: Props) => {
  const [value, setValue] = useState('categories');

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} centered>
        <Tab label="Categories" value="categories" />
        <Tab label="Intent" value="intent" />
        <Tab label="Product" value="product" />
      </TabList>
      <TabPanel value="categories" sx={{px: 0}}>
        <HierBarWithHook {...commonProps} />
      </TabPanel>
      <TabPanel value="intent" sx={{px: 0}}>
        <GraphWithHook
          insight={{
            title: 'Intent',
            questionType: 'extraction_single_label',
            questionId: '882d4655e02be53ddac7ee71a4a38ef88640d609',
            visualisationType: 'Aggregate',
          }}
          {...commonProps}
        />
      </TabPanel>
      <TabPanel value="product" sx={{px: 0}}>
        <GraphWithHook
          insight={{
            title: 'Product',
            questionType: 'extraction_single_label',
            questionId: 'dc5f7f5edc66ae4b6692182ab2da6eeb12e38282',
            visualisationType: 'Aggregate',
          }}
          {...commonProps}
        />
      </TabPanel>
    </TabContext>
  );
};
