/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import React, {useEffect} from 'react';
import {LegacyButton, ButtonProps} from '../ui/legacy-button';
import {Popover, PopoverContent, PopoverTrigger} from '../ui/popover';

interface CopyButtonProps extends Omit<ButtonProps, 'onClick'> {
  value: string;
}

const CopyButton = ({value, children, ...delegated}: CopyButtonProps) => {
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  const [showCopiedPopover, setShowCopiedPopover] = React.useState(false);

  useEffect(() => {
    if (showCopiedPopover) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setShowCopiedPopover(false);
      }, 2000);
    }

    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [showCopiedPopover]);

  const onClick = async () => {
    await navigator.clipboard.writeText(value);
    setShowCopiedPopover(true);
  };

  return (
    <Popover open={showCopiedPopover}>
      <PopoverTrigger asChild>
        <LegacyButton {...delegated} onClick={onClick}>
          {children}
        </LegacyButton>
      </PopoverTrigger>
      <PopoverContent className={'text-center w-56'}>
        <p>Copied to clipboard</p>
      </PopoverContent>
    </Popover>
  );
};

export default CopyButton;
