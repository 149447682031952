// prettifies a number by showing up to n decimal points (if needed) and adds % symbol
export const formatPercentage = (
  numberOrString: number | string,
  decimalPlaces: number = 2,
): string => {
  const num =
    typeof numberOrString === 'string'
      ? Number(numberOrString)
      : numberOrString;
  if (decimalPlaces === 0) return `${Math.round(num)}%`;
  return `${num.toFixed(decimalPlaces).replace(/\.?0+$/, '')}%`;
};
