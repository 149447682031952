/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import {useCallback, useEffect} from 'react';
import * as React from 'react';
import {ActionType} from '../../../../api/generated/runtime/typescript/lib';
import {LegacyButton} from '../ui/legacy-button';

interface ActionOption {
  value: ActionType;
  label: string;
}

export const contactImportJobActions: ActionOption[] = [
  {value: 'RE_IMPORT_ALL', label: 'Retry Import'},
  {value: 'RE_IMPORT_FAILED', label: 'ReImport Failed'}, // disable if it's successful
  {value: 'RE_INFERENCE_ALL', label: 'Regenerate Insight'},
  {value: 'RE_INFERENCE_FAILED', label: 'Retry Failed Insights'}, // disable if it's successful
  {value: 'RE_TRANSCRIBE_ALL', label: 'Regenerate Transcription'}, // disable if it does not have audio
  {value: 'RE_TRANSCRIBE_FAILED', label: 'ReTranscribe Failed'}, // disable if it's successful
];

export const contactSuccessActions: ActionOption[] = [
  {value: 'RE_IMPORT_ALL', label: 'ReImport Contact'},
  {value: 'RE_INFERENCE_ALL', label: 'Regenerate Insight'},
  {value: 'RE_TRANSCRIBE_ALL', label: 'Regenerate Transcription'},
];

export const contactFailedActions: ActionOption[] = [
  {value: 'RE_IMPORT_FAILED', label: 'ReImport Failed'},
  {value: 'RE_INFERENCE_FAILED', label: 'Retry Failed Insights'},
  {value: 'RE_TRANSCRIBE_FAILED', label: 'ReTranscribe Failed'},
];

interface Props {
  disabled?: boolean;
  applyActionType: (actionType: ActionType) => Promise<void>;
  options: ActionOption[];
  disabledIndex: number[];
}

export const RetryImportAction = ({
  disabled,
  applyActionType,
  options,
  disabledIndex,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  useEffect(() => {
    const firstEnabledIndex = options.findIndex(
      (_option, index) => !disabledIndex.includes(index),
    );
    if (firstEnabledIndex !== -1) {
      setSelectedIndex(firstEnabledIndex);
    }
  }, [options, disabledIndex]);
  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  });
  const handleClick = useCallback(async () => {
    await applyActionType(options[selectedIndex].value);
  }, [applyActionType, selectedIndex]);

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <LegacyButton
          size="sm"
          onClick={handleClick}
          disabled={disabled}
          style={{
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          }}
        >
          {options[selectedIndex].label}
        </LegacyButton>
        <LegacyButton
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select retry option"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={disabled}
          size="sm"
          style={{
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
          }}
        >
          <ArrowDropDownIcon />
        </LegacyButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
              marginRight: '1.5rem',
            }}
          >
            <Paper style={{overflowY: 'auto'}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      selected={index === selectedIndex}
                      disabled={
                        disabledIndex.length > 0
                          ? disabledIndex.includes(index)
                          : false
                      }
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
