/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {AcceptedPromptTypes} from '@agent-assist/api-typescript-react-query-hooks/lib/models/AcceptedPromptTypes';
import {PromptGroups} from '@agent-assist/api-typescript-react-query-hooks/lib/models/PromptGroups';
import {acceptedPromptTypes, Visualisation} from './types';

export const formatVisualisationTitle = (title: string) => {
  return title
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const constructPromptOptions = (
  prompts: PromptGroups,
  promptVisualisations: Visualisation[],
) => {
  return Object.values(prompts)
    .flatMap((group) =>
      group.questions
        .filter((q) => acceptedPromptTypes.includes(q.promptType))
        .map((q) => {
          const existingVisualizations = promptVisualisations.filter(
            (v) => v.questionId === q.id,
          );
          const hasTrend = existingVisualizations.some(
            (v) => v.visualisationType === 'Trend',
          );
          const hasAggregate = existingVisualizations.some(
            (v) => v.visualisationType === 'Aggregate',
          );

          // Only include the question if at least one visualization type is missing
          if (!hasTrend || !hasAggregate) {
            if (!q.properties.conditionalQA) {
              return {
                label: q.key,
                value: q.key,
                question: q.question,
                questionId: q.id,
                questionType: q.promptType as AcceptedPromptTypes,
                hasTrend,
                hasAggregate,
              };
            }
          }
          return null;
        }),
    )
    .filter((option): option is NonNullable<typeof option> => option !== null);
};
