/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Amplify} from '@aws-amplify/core';
import {Box, Card, CardContent} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import React, {createContext, ReactNode, useEffect, useState} from 'react';

interface RuntimeContext {
  readonly connectInstanceIdToBaseUrlMapping?: {[key: string]: string};
  readonly connectInstanceUrl: string;
  readonly customerId: string;
  readonly identityPoolId: string;
  readonly ldClientSideId: string;
  readonly notificationsApiUrl: string;
  readonly region: string;
  readonly userPoolId: string;
  readonly userPoolWebClientId: string;
  readonly [additionalProps: string]: any;
}

export const RuntimeConfigContext = createContext<RuntimeContext | undefined>(
  undefined,
);

interface RuntimeContextProviderProps {
  children: ReactNode;
}

const RuntimeContextProvider: React.FC<RuntimeContextProviderProps> = ({
  children,
}) => {
  const [runtimeContext, setRuntimeContext] = useState<
    RuntimeContext | undefined
  >();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadRuntimeConfig = async () => {
      try {
        const response = await fetch('/runtime-config.json');
        const runtimeCtx: RuntimeContext = await response.json();

        const requiredFields = [
          'customerId',
          'identityPoolId',
          'ldClientSideId',
          'region',
          'userPoolId',
          'userPoolWebClientId',
        ];

        const missingFields = requiredFields.filter(
          (field) => !runtimeCtx[field],
        );

        if (missingFields.length === 0) {
          Amplify.configure({
            Auth: {
              region: runtimeCtx.region,
              userPoolId: runtimeCtx.userPoolId,
              userPoolWebClientId: runtimeCtx.userPoolWebClientId,
              identityPoolId: runtimeCtx.identityPoolId,
            },
            aws_appsync_graphqlEndpoint: runtimeCtx.notificationsApiUrl,
            aws_appsync_region: runtimeCtx.region,
            aws_appsync_authenticationType: 'AWS_IAM',
          });
          setRuntimeContext(runtimeCtx);
        } else {
          throw new Error(
            `Missing required fields: ${missingFields.join(', ')}`,
          );
        }
      } catch (err) {
        const errorMessage =
          err instanceof Error
            ? err.message
            : 'Failed to load runtime-config.json. Ensure it is present and correctly formatted.';
        setError(errorMessage);
        console.error(err);
      }
    };

    void loadRuntimeConfig();
  }, []);

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <RuntimeConfigContext.Provider value={runtimeContext}>
      {children}
    </RuntimeConfigContext.Provider>
  );
};

const ErrorMessage: React.FC<{children: React.ReactNode}> = ({children}) => (
  <Box sx={{p: 2}}>
    <Card>
      <CardHeader title={'Runtime Configuration Error'} />
      <CardContent>{children}</CardContent>
    </Card>
  </Box>
);

export default RuntimeContextProvider;
