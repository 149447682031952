/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/
import {Button} from '@mui/material';

import Grid from '@mui/material/Grid2';
import {
  Room,
  // RoomEvent,
  // RemoteTrack,
  // RemoteTrackPublication,
  // RemoteParticipant,
  // LocalTrackPublication,
  // LocalParticipant,
  // Participant,
} from 'livekit-client';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import {
  AgentContact,
  ListAgentContacts as ListContacts,
} from './ListAgentContacts';
import {
  APIParams,
  METHODS,
  useTrusstedAgent,
} from '../../../hooks/trusstedAgent/useTrusstedAgent';
import {TimeStampedMessage} from '../../../pages/data-dialog/data-dialog';
import {DialogTranscript} from '../../../pages/data-dialog/DialogTranscript';
import {PromptInput} from '../../../pages/data-dialog/PromptInput';
import {RuntimeConfigContext} from '../../../providers/RuntimeContextProvider';
import {LoadingSpinner} from '../../LoadingSpinner';
import {PageContainer} from '../../ui/page';

// import {
//   LiveKitRoom;
// } from '@livekit/components-react';

export enum EVENT_NAMES {
  ListAgentContacts = 'ListAgentContacts',
  DeleteContact = 'DeleteContact',
  CreateTestContact = 'CreateTestContact',
  ListContactMessages = 'ListContactMessages',
  SendMessage = 'SendMessage',
  CreateRoom = 'CreateRoom',
  ListAgents = 'ListAgents',
  GetAgent = 'GetAgent',
  GetAgentTool = 'GetAgentTool',
  ListAgentTools = 'ListAgentTools',
  ManageAgent = 'ManageAgent',
  DeleteAgent = 'DeleteAgent',
  CloneAgent = 'CloneAgent',
  UpdateAgentTools = 'UpdateAgentTools',
  DeleteAgentTool = 'DeleteAgentTool',
  PublishAgent = 'PublishAgent',
  GetPublishedAgent = 'GetPublishedAgent',
}

const ChatTrusstedAgent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const runtimeContext = useContext(RuntimeConfigContext);
  const apiKey = runtimeContext?.trusstedAgentApiKey;
  const apiEndpoint = runtimeContext?.trusstedAgentApiEndpoint;
  const trusstedAgentAPIHook = (params: APIParams) =>
    useTrusstedAgent({
      apiEndpoint: apiEndpoint,
      apiKey: apiKey,
      ...params,
    });

  const {agentId} = useParams<{
    agentId?: string;
  }>();
  const contactId = searchParams.get('contactId');
  const [activateChat, setActivateChat] = useState(false);
  const [activateRoom, setActivateRoom] = useState(false);
  const [contacts, setContacts] = useState<AgentContact[]>([]);
  const [listAgentContactsLoading, setListAgentContactsLoading] =
    useState(false);
  const [listContactMessagesLoading, setListContactMessagesLoading] =
    useState(false);
  const [sendingMessage, setIsSendingMessage] = useState(false);
  const [isDeleteContactLoading, setIsDeleteContactLoading] = useState(false);
  const [messages, setMessages] = useState<TimeStampedMessage[]>([]);
  const roomRef = useRef<Room | null>(null);
  // const [token, setToken] = useState<string>("missing_token");
  // const wsURL = "wss://trussttest-agcrfvip.livekit.cloud";

  // const getRoomToken = useCallback(async () => {
  //   console.log("Retrieving room token");
  //   try {
  //     const result = await trusstedAgentAPIHook({
  //       eventName: EVENT_NAMES.CreateRoom,
  //       methodName: METHODS.GET,
  //       params: {},
  //       queryParams: {
  //         agentId: currentAgentId,
  //         name: EVENT_NAMES.CreateRoom,
  //       },
  //     });
  //     console.log("Recieved room token: " + result.token);
  //     setToken(result.token);

  //     const room = new Room();
  //     roomRef.current = room;
  //     await room.prepareConnection(wsURL, result.token);
  //     room
  //       .on(RoomEvent.TrackSubscribed, handleTrackSubscribed)
  //       .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
  //       .on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakerChange)
  //       .on(RoomEvent.Disconnected, handleDisconnect)
  //       .on(RoomEvent.LocalTrackUnpublished, handleLocalTrackUnpublished);
  //   } catch (error) {
  //     console.error("Error getting room token:", error);
  //   } finally {
  //     //
  //   }
  // }, []);

  const listAgentContacts = useCallback(async () => {
    setListAgentContactsLoading(true);
    try {
      const result = await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.ListAgentContacts,
        methodName: METHODS.GET,
        params: {},
        queryParams: {
          agentId: agentId,
        },
      });
      setContacts(result.contacts);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setListAgentContactsLoading(false);
    }
  }, [agentId]);

  const getContactMessages = useCallback(async () => {
    if (!contactId) return;
    setListContactMessagesLoading(true);
    try {
      const result = await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.ListContactMessages,
        methodName: METHODS.GET,
        params: {},
        queryParams: {
          contactId: contactId,
        },
      });
      const formattedMessages = result.messages.map((msg: any) => ({
        content: msg.message,
        role: msg.participant.toLowerCase(),
        createdAt: msg.beginOffsetMillis,
      }));
      setMessages(formattedMessages);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setListContactMessagesLoading(false);
    }
  }, [contactId]);

  const createTestContact = async (message: string) => {
    try {
      await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.CreateTestContact,
        methodName: METHODS.POST,
        params: {
          agentId: agentId,
          contactId: contactId,
          message: message,
        },
        queryParams: {},
      });
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const setNewMessage = async (message: string, new_contactId: string) => {
    setIsSendingMessage(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      {content: message, role: 'user', createdAt: new Date().getTime()},
    ]);
    try {
      const respond = await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.SendMessage,
        methodName: METHODS.POST,
        params: {
          contactId: new_contactId,
          agentId: agentId,
          message: message,
        },
        queryParams: {},
      });
      const formattedMessages = respond.messages.map((msg: any) => ({
        content: msg.message,
        role: msg.participant.toLowerCase(),
        createdAt: msg.beginOffsetMillis,
      }));
      setMessages(formattedMessages);
    } catch (err) {
      const errorMessage: TimeStampedMessage = {
        content: 'Sorry something went wrong processing your request',
        createdAt: new Date().getTime(),
        role: 'assistant',
      };
      setMessages([...messages, errorMessage]);
    } finally {
      setIsSendingMessage(false);
    }
  };

  const deleteAgentContact = async (delete_contactId: string) => {
    setIsDeleteContactLoading(true);
    try {
      await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.DeleteContact,
        methodName: METHODS.POST,
        params: {
          contactId: delete_contactId,
          agentId: agentId,
        },
        queryParams: {},
      });
      void listAgentContacts();
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setIsDeleteContactLoading(false);
    }
  };

  useEffect(() => {
    void listAgentContacts();
  }, [listAgentContacts, activateChat, agentId]);

  useEffect(() => {
    void getContactMessages();
  }, [contactId]);

  const sendMessage = async (messageContent: string) => {
    if (contactId) {
      void setNewMessage(messageContent, contactId);
      if (messages.length === 0) {
        await createTestContact(messageContent);
        await listAgentContacts();
      }
    }
  };

  const onStartChat = () => {
    setMessages([]);
    const newId = uuid();
    setSearchParams({contactId: newId}, {replace: true});
    // navigate(`/chat-agent/edit/${agentId}/${newId}`);
    setActivateChat(true);
  };

  const onSelectContact = (id: string) => {
    setMessages([]);
    setActivateChat(true);
    setSearchParams({contactId: id}, {replace: true});
  };

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [messages]);

  // function handleTrackSubscribed(
  //   track: RemoteTrack,
  //   publication: RemoteTrackPublication,
  //   participant: RemoteParticipant,
  // ) {
  //   console.log("handling track subscribed");
  //   // // Attach track to a new HTMLVideoElement or HTMLAudioElement
  //   const element = track.attach();
  //   document.body.appendChild(element);
  //   console.log(track);
  //   console.log(publication);
  //   console.log(participant);
  // }

  // function handleTrackUnsubscribed(
  //   track: RemoteTrack,
  //   publication: RemoteTrackPublication,
  //   participant: RemoteParticipant,
  // ) {
  //   console.log("handling track unsubscribed");
  //   // remove tracks from all attached elements
  //   track.detach();
  //   console.log(publication);
  //   console.log(participant);
  // }

  // function handleLocalTrackUnpublished(publication: LocalTrackPublication, participant: LocalParticipant) {
  //   console.log("handle local track unpublished");
  //   // when local tracks are ended, update UI to remove them from rendering
  //   if (publication && publication.track) {
  //     publication.track.detach();
  //   }
  //   console.log(participant);
  // }

  // function handleActiveSpeakerChange(speakers: Participant[]) {
  //   // show UI indicators when participant is speaking
  //   console.log("handle active speakers change");
  //   console.log(speakers);
  // }

  // function handleDisconnect() {
  //   console.log("disconnected from room");
  // }

  const onStartAudio = async () => {
    if (!activateRoom) {
      if (roomRef && roomRef.current) {
        setMessages([]);
        // const newId = uuid();
        // navigate(`/chat-agent/${agentId}/${newId}`);
        setActivateChat(true);

        const room = roomRef.current;
        // await room.connect(wsURL, token);
        console.log('connected to room', room.name);

        console.log(room.canPlaybackAudio);

        await room.localParticipant.setMicrophoneEnabled(true);

        console.log('Have set microphone enabled');
        setActivateRoom(true);
        console.log(activateRoom);
      }
    } else {
      console.log('disconnecting audio');
      if (roomRef && roomRef.current) {
        await roomRef.current.disconnect();
      }
      setActivateRoom(false);
    }
  };

  // useEffect(() => {
  //   void getRoomToken();
  // }, []);

  return (
    <PageContainer title={agentId ? 'Test Agent' : 'Chat Agent'}>
      {/* <LiveKitRoom
          video={true}
          audio={true}
          token={"awefawefaew"}
          serverUrl={"awefawefawef"}
          // Use the default LiveKit theme for nice styles.
          data-lk-theme="default"
          style={{ height: '100vh' }}
        ></LiveKitRoom> */}
      <>
        <Grid
          container
          spacing={2}
          className="flex flex-row items-center justify-end w-full mb-4"
        >
          <Grid size={2}>
            <Button
              variant="contained"
              onClick={() => onStartChat()}
              className="w-full"
            >
              {activateChat ? 'New Chat' : 'Chat Agent'}
            </Button>
          </Grid>
          <Grid size={2}>
            <Button
              variant="contained"
              onClick={() => onStartAudio()}
              className="w-full"
            >
              {activateRoom ? 'Disconnect Audio' : 'Connect Audio'}
            </Button>
          </Grid>
        </Grid>
        <div
          className="grid w-full gap-4"
          style={{
            gridTemplateColumns: activateChat ? '1fr 200px' : '1fr 0px',
            gridTemplateRows: '1fr 40px',
            height: 'calc(100vh - 200px)', // -(header height + padding)
          }}
        >
          {activateChat && (
            <div
              className="flex flex-col h-full overflow-y-auto"
              ref={containerRef}
            >
              <div className="overflow-y-auto flex-grow">
                {listContactMessagesLoading ? (
                  <div className="flex w-full justify-centre p-6">
                    <LoadingSpinner size={'lg'} />
                  </div>
                ) : (
                  <>
                    <DialogTranscript
                      messages={messages}
                      refetch={getContactMessages}
                      showSavePrompt={false}
                    />
                    {sendingMessage && (
                      <div className="flex w-full justify-end p-6">
                        <LoadingSpinner size={'lg'} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          <div className="relative row-span-2">
            <ListContacts
              contacts={contacts}
              refetch={listAgentContacts}
              isFetching={listAgentContactsLoading || isDeleteContactLoading}
              onDeleteContact={deleteAgentContact}
              onSelectContact={(selectedId: string) =>
                onSelectContact(selectedId)
              }
            />
          </div>
          {activateChat && (
            <div className="w-full mt-2">
              <PromptInput sendMessage={sendMessage} dialogActive={true} />
            </div>
          )}
        </div>
      </>
    </PageContainer>
  );
};

export default ChatTrusstedAgent;
