/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {ContactCard} from './ContactCard';
import {AgentContactsListContainer} from './ContactsListContainer';

export enum AgentContactSource {
  TEST_AGENT = 'TEST_AGENT',
  BATCH_TEST_AGENT = 'BATCH_TEST_AGENT',
}

export interface AgentContact {
  agentId: string;
  tool_id: string;
  contactId: string;
  title: string;
  summary?: string;
  source: AgentContactSource;
  createdBy?: string;
  createdAt?: number;
  updatedBy?: string;
  updatedAt?: number;
}

export interface ContactSummaryMethods {
  onSelectContact(contactId: string): void;
  onDeleteContact(contactId: string): Promise<void>;
  refetch(): Promise<void>;
}

interface ListContactsProps extends ContactSummaryMethods {
  isFetching: boolean;
  contacts?: Partial<AgentContact>[];
}

export const ListAgentContacts = ({
  isFetching,
  contacts,
  onSelectContact,
  onDeleteContact,
  refetch,
}: ListContactsProps) => {
  const [isExpanded, setExpanded] = useState(false);
  const {contactId} = useParams<{agentId?: string; contactId?: string}>();
  if (!contacts)
    return (
      <AgentContactsListContainer
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        isFetching={true}
      />
    );
  return (
    <AgentContactsListContainer
      isExpanded={isExpanded}
      setExpanded={setExpanded}
      isFetching={isFetching}
    >
      {contacts.map((contact, index) => (
        <div
          key={index}
          className={`border rounded-lg p-2 ${contact.contactId === contactId ? 'bg-accent' : null}`}
        >
          <ContactCard
            isExpanded={isExpanded}
            onSelectContact={onSelectContact}
            onDeleteContact={onDeleteContact}
            contact={contact}
            refetch={refetch}
          />
        </div>
      ))}
    </AgentContactsListContainer>
  );
};
