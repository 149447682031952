/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  type ContactDetailsFilter,
  useListOptionsForColumn,
  type ValuesForColumn,
} from '@agent-assist/api-typescript-react-query-hooks';

import ClearIcon from '@mui/icons-material/Clear';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import {SelectChangeEvent} from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {useEffect, useMemo, useState} from 'react';

import {columns, type ColumnSetting, type ColumnSettingType} from './columns';
import {MuiDateTimeRangePicker} from '../../components/DateAndTimeRange/DateAndTimeRange';
import {LoadingSpinner} from '../../components/LoadingSpinner';
import {databaseTimeToLocalTime} from '../../lib/formatDate';

export type OnFilterParams = {
  type?: ColumnSettingType;
  key?: string;
  value?: string;
}[];
export type OnFilter = (paramOrParams: OnFilterParams) => void;
type OnFilterColumn = React.Dispatch<React.SetStateAction<string>>;

interface FilterProps {
  activeFilters: ContactDetailsFilter[];
  onFilter: OnFilter;
}

interface FilterOptionProps extends FilterProps {
  columnName: string;
  contactImportId: string;
  onFilterColumn: OnFilterColumn;
  availableFilters?: ColumnSetting[];
}

interface FilterOptionsCompleteProps extends FilterProps {
  contactImportId: string;
  availableFilters: ColumnSetting[];
}

// TODO audit the need for this (+ interface permutations):
export const FilterOptionsComplete = ({
  ...props
}: FilterOptionsCompleteProps) => {
  const defaultKey = props.availableFilters.at(0)?.key!;
  const [filterColumn, setFilterColumn] = useState<string>(defaultKey);
  return (
    <FilterOptions
      {...props}
      columnName={filterColumn}
      onFilterColumn={setFilterColumn}
    />
  );
};

export const FilterOptions = ({
  activeFilters,
  availableFilters,
  columnName,
  contactImportId,
  onFilter,
  onFilterColumn,
}: FilterOptionProps) => {
  const filterOptionsApi = useListOptionsForColumn({
    contactImportId,
    columnName,
    pageSize: 100,
  });

  const cleansedActiveFilters = useMemo(
    // these are handled with a custom date picker:
    () =>
      activeFilters.filter(({key}) => !['startTime', 'endTime'].includes(key)),
    [activeFilters],
  );

  const currentDateRange: [Date | null, Date | null] = useMemo(() => {
    const startTime = activeFilters
      ?.find((a) => a.key === 'startTime')
      ?.values.at(0);
    const endTime = activeFilters
      ?.find((a) => a.key === 'endTime')
      ?.values.at(0);
    return [
      startTime ? new Date(startTime) : null,
      endTime ? new Date(endTime) : null,
    ];
  }, [activeFilters]);

  if (filterOptionsApi.isError) {
    console.warn(filterOptionsApi.error);
    return <div>Error loading filter options!</div>;
  }

  const showDualFilters = !!(availableFilters && onFilterColumn);

  return (
    <Stack spacing={2}>
      <Grid container spacing={4} columns={2}>
        <Grid size={1}>
          <Typography color="text.secondary" sx={{mb: 1}}>
            Date Range:
          </Typography>
          <MuiDateTimeRangePicker
            defaultValue={currentDateRange}
            onAccept={([startTime, endTime]) => {
              const params: OnFilterParams = [
                {
                  type: 'ColumnSettingDate',
                  key: 'startTime',
                  value: databaseTimeToLocalTime(startTime ?? undefined),
                },
                {
                  type: 'ColumnSettingDate',
                  key: 'endTime',
                  value: databaseTimeToLocalTime(endTime ?? undefined),
                },
              ];
              onFilter(params);
            }}
          />
        </Grid>
        <Grid size={1}>
          <Stack>
            <Typography color="text.secondary" sx={{mb: 1}}>
              Add Filter:
            </Typography>
            <Grid container columns={2} spacing={2} sx={{alignItems: 'center'}}>
              {showDualFilters && (
                <Grid size={1}>
                  <Select
                    variant="outlined"
                    value={availableFilters[0].key}
                    onChange={(event: SelectChangeEvent) => {
                      onFilterColumn(event.target.value as string);
                    }}
                    sx={{width: '100%'}}
                  >
                    {availableFilters.map(({key, label}) => (
                      <MenuItem value={key} key={key}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              <Grid size={showDualFilters ? 1 : 2}>
                <NewFilters
                  showFilterBy={Boolean(!availableFilters)}
                  isLoading={filterOptionsApi.isLoading}
                  activeFilters={cleansedActiveFilters}
                  columnName={columnName}
                  onFilter={onFilter}
                  filterOptionsData={filterOptionsApi.data?.results}
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      {cleansedActiveFilters.length > 0 && (
        <Box>
          <ActiveFilters
            activeFilters={cleansedActiveFilters}
            onFilterColumn={onFilterColumn}
            onFilter={onFilter}
          />
        </Box>
      )}
    </Stack>
  );
};

interface ActiveFiltersProps extends FilterProps {
  onFilterColumn: OnFilterColumn;
}

const ActiveFilters = ({
  activeFilters,
  onFilterColumn,
  onFilter,
}: ActiveFiltersProps) => {
  const activeFiltersMeta = useMemo(() => {
    return activeFilters.map((item) => columns.find((f) => f.key === item.key));
  }, [activeFilters]);

  return (
    <Stack spacing={1}>
      <Divider />
      <Stack
        direction="row"
        spacing={2}
        sx={{justifyContent: 'space-between', alignItems: 'center'}}
      >
        <Typography color="text.secondary" sx={{mb: 1}}>
          Active Filters:
        </Typography>

        <Button
          variant="text"
          onClick={() => onFilter([])}
          startIcon={<DeleteSweepIcon />}
        >
          Clear All
        </Button>
      </Stack>
      {activeFilters.map((item, index) => {
        const display = activeFiltersMeta.at(index);
        if (!display) {
          // possibly an invalid url query param.
          console.error(
            `cannot find display for activeFiltersMeta for key: '${item.key}' @index: ${index}`,
          );
          return null;
        }
        const {label, type} = display;
        const key = `${item.key}-${index}`;
        return (
          <Stack key={key} direction="row" spacing={2}>
            <Button onClick={() => onFilterColumn(item.key)}>{label}:</Button>
            {item.values.map((subItem) => {
              return (
                <Button
                  variant="outlined"
                  color="secondary"
                  endIcon={<ClearIcon />}
                  key={`${item.key}-${index}-${subItem}`}
                  onClick={() =>
                    onFilter([{type, key: item.key, value: subItem}])
                  }
                >
                  {subItem}
                </Button>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};

interface NewFiltersProps extends FilterProps {
  isLoading: boolean;
  showFilterBy: boolean;
  columnName: string;
  filterOptionsData?: ValuesForColumn;
}

const CLEAR = 'CLEAR';

const NewFilters = ({
  activeFilters,
  isLoading,
  columnName,
  filterOptionsData,
  onFilter,
  showFilterBy,
}: NewFiltersProps) => {
  const isFilterActive = activeFilters.find((f) => f.key === columnName);
  const [selected, setSelected] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!isFilterActive) setSelected(CLEAR); // select component does not like going back to value undefined.
  }, [isFilterActive]);

  const currentLabel = useMemo(
    () => columns.find((f) => f.key === columnName)?.label,
    [columnName],
  );
  const options = useMemo(() => {
    if (!filterOptionsData?.values) return [];
    return [{label: 'No filter', value: CLEAR}].concat(
      filterOptionsData.values
        .sort((a, b) => (a < b ? -1 : 1))
        .map((value) => ({label: value, value})),
    );
  }, [filterOptionsData?.values]);

  return (
    <Grid container spacing={2} columns={2}>
      {showFilterBy && (
        <Grid size={1}>
          <TextField
            id="outlined-basic"
            value={currentLabel}
            variant="outlined"
            slotProps={{
              input: {
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterListIcon color="primary" />
                  </InputAdornment>
                ),
              },
            }}
            sx={{width: '100%'}}
          />
        </Grid>
      )}
      <Grid size={showFilterBy ? 1 : 2} alignItems="center" display="flex">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <FilterSelect
            columnName={columnName}
            onFilterChange={(type: ColumnSettingType, value: string) => {
              setSelected(value);
              onFilter([
                {
                  type,
                  key: columnName,
                  value: value === CLEAR ? undefined : value,
                },
              ]);
            }}
            options={options}
            value={selected}
          />
        )}
      </Grid>
    </Grid>
  );
};

interface FilterSelectProps {
  columnName: string;
  onFilterChange: (type: ColumnSettingType, v: string) => void;
  options: any[];
  value?: string;
}

const FilterSelect = ({
  options,
  columnName,
  onFilterChange,
  value,
}: FilterSelectProps) => {
  const column = useMemo(
    () => columns.find((f) => f.key === columnName),
    [columnName],
  );
  if (!column) throw new Error(`can not find column: '${columnName}'`);
  // console.log(column.type);
  const {type} = column;
  switch (type) {
    case 'ColumnSettingText':
      return (
        <Select
          variant="outlined"
          value={value || options[0].value}
          onChange={(event: SelectChangeEvent) => {
            onFilterChange(type, event.target.value as string);
          }}
          sx={{width: '100%'}}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
          {options.map(({value, label}) => (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      );
    default:
      return <div>FilterSelect. unhandled type: {type} </div>;
  }
};
