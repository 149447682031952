/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import apigClientFactory from 'aws-api-gateway-client';

const region = 'ap-southeast-2';
export enum METHODS {
  GET = 'GET',
  POST = 'POST',
}

export interface UseTrusstedAgentAPIProps extends APIParams {
  apiKey: string;
  apiEndpoint: string;
}

export interface APIParams {
  eventName: string;
  methodName: METHODS;
  params: Record<string, any>;
  queryParams?: Record<string, any>;
}

export const useTrusstedAgent = async ({
  eventName,
  methodName,
  params,
  queryParams,
  apiEndpoint,
  apiKey,
}: UseTrusstedAgentAPIProps): Promise<any> => {
  const invokeUrl = apiEndpoint.endsWith('/')
    ? apiEndpoint.slice(0, -1)
    : apiEndpoint;

  const apigClient = apigClientFactory.newClient({
    invokeUrl,
    region,
  });
  const pathTemplate = '/';
  const additionalParams = {
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/json',
    },
    queryParams: {
      name: eventName,
      ...queryParams,
    },
  };
  const body =
    methodName === METHODS.POST
      ? JSON.stringify({
          name: eventName,
          ...params,
        })
      : '';

  try {
    const response = await apigClient.invokeApi(
      params,
      pathTemplate,
      methodName,
      additionalParams,
      body,
    );
    return response.data;
  } catch (error) {
    console.error('Error Calling Trussted Agent API', (error as Error).message);
    throw error;
  }
};
