/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {FormEvent, useState} from 'react';
import {Input} from '../../components/ui/input';

export const PromptInput = ({
  dialogActive,
  sendMessage,
}: {
  dialogActive: boolean;
  sendMessage: (messageContent: string) => Promise<void>;
}) => {
  const [inputText, setInputText] = useState('');

  const handleMessageSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputText.trim() === '') return;
    void sendMessage(inputText); // error handled in `sendMessage`
    setInputText('');
  };

  return (
    <form onSubmit={handleMessageSubmit}>
      <Input
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder={
          dialogActive
            ? 'Type something...'
            : 'Pick a preset question above, one from a team mate on the right or type your own question...'
        }
        variant="outline"
      />
    </form>
  );
};
