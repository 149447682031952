/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import type {GetPromptRevisionResponseContent} from '@agent-assist/api-typescript-react-query-hooks';

import {Card, CardContent, Stack} from '@mui/material';
import Grid from '@mui/material/Grid2';
import LegacyHeading from '../../../components/Heading';
import StatusIndicator, {
  StatusTypeEnum,
} from '../../../components/StatusIndicator';

interface PromptRevisionHeaderProps {
  isPublished: boolean;
  promptRevision: GetPromptRevisionResponseContent;
}

export const PromptRevisionHeader = ({
  isPublished,
  promptRevision: {
    createdAt,
    createdBy: {username},
  },
}: PromptRevisionHeaderProps) => {
  return (
    <Grid container spacing={4} columns={2} sx={{display: 'flex'}}>
      <Grid size={1} sx={{display: 'flex'}}>
        <Card sx={{flex: 1}}>
          <CardContent>
            <Stack spacing={1} direction={'row'} sx={{alignItems: 'center'}}>
              <LegacyHeading h3>Status:</LegacyHeading>
              <StatusIndicator
                status={
                  isPublished
                    ? StatusTypeEnum.COMPLETED
                    : StatusTypeEnum.PENDING
                }
              >
                {isPublished ? 'Live' : 'Unpublished'}
              </StatusIndicator>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid size={1} sx={{display: 'flex'}}>
        <Card sx={{flex: 1}}>
          <CardContent>
            <Stack spacing={1} direction={'row'} sx={{alignItems: 'center'}}>
              <LegacyHeading h3>
                Created by{' '}
                <span style={{textTransform: 'capitalize'}}>{username}</span>
              </LegacyHeading>
              <p>{new Date(createdAt).toLocaleString()}</p>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
