/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ChevronLeft, ChevronRight} from 'lucide-react';
import {LegacyButton} from '../ui/legacy-button';

interface PaginationProps {
  currentPage: number;
  loading: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  totalPages?: number; // most infinite query results do not respond with a total page count.
  onNextPage(): Promise<void>;
  onPreviousPage(): void;
}

const Pagination = ({
  currentPage,
  loading,
  hasNext,
  hasPrevious,
  onNextPage,
  onPreviousPage,
  totalPages,
}: PaginationProps) => {
  const disablePrevious = loading || !hasPrevious;
  const disableNext = loading || !hasNext;

  let pagesDisplay = '';
  if (typeof totalPages === 'undefined') {
    pagesDisplay = String(currentPage); // infinite query
  } else if (totalPages === 0) {
    pagesDisplay = '0 / 0';
  } else {
    pagesDisplay = `${currentPage} / ${totalPages}`; // standard pagination
  }

  return (
    <div className={'flex flex-row items-center gap-x-4'}>
      <LegacyButton
        disabled={disablePrevious}
        size={'icon'}
        variant={'ghost'}
        onClick={onPreviousPage}
      >
        <ChevronLeft
          className={disablePrevious ? 'text-muted-foreground' : undefined}
        />
      </LegacyButton>
      {pagesDisplay}
      <LegacyButton
        disabled={disableNext}
        size={'icon'}
        variant={'ghost'}
        onClick={onNextPage}
      >
        <ChevronRight
          className={disableNext ? 'text-muted-foreground' : undefined}
        />
      </LegacyButton>
    </div>
  );
};

export default Pagination;
