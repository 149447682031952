/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {useGetPublishedPromptRevision} from '@agent-assist/api-typescript-react-query-hooks';
import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {LoadingSpinner} from '../../../components/LoadingSpinner';

interface PublishedPromptProps {}

export const QAPublishedPrompt: FC<PublishedPromptProps> = () => {
  const navigate = useNavigate();
  const publishedPrompt = useGetPublishedPromptRevision();

  useEffect(() => {
    if (publishedPrompt.data) {
      navigate(`/prompts/${publishedPrompt.data.promptRevisionId}`);
    }
  }, [publishedPrompt.data]);

  return (
    <div className={'flex flex-1 items-center justify-center'}>
      <LoadingSpinner size={'lg'} />
    </div>
  );
};

export default QAPublishedPrompt;
