/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ContactImport,
  ContactSource,
} from '@agent-assist/api-typescript-react-query-hooks';
import {Link} from '@mui/material';
import {FC} from 'react';
import {Link as RouterLink} from 'react-router-dom';

interface ContactSourceIndicatorProps {
  readonly source?: ContactSource;
  readonly contactImport?: ContactImport;
}

export const ContactSourceIndicator: FC<ContactSourceIndicatorProps> = ({
  source,
  contactImport,
}) => {
  switch (source) {
    case 'IMPORT':
      return contactImport ? (
        <Link
          to={`/contacts/${contactImport.contactImportId}`}
          component={RouterLink}
        >
          {contactImport.name}
        </Link>
      ) : (
        <>Import</>
      );
    default:
      return <>Real-Time</>;
  }
};
