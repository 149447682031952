/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Tooltip} from '@mui/material';
import {
  CheckCircle2,
  CircleEllipsis,
  Clock9,
  MinusCircle,
  RefreshCw,
  XCircle,
  CircleDotDashed,
  CircleOff,
  TriangleAlert,
} from 'lucide-react';
import React from 'react';
import {cn} from '../../lib/utils';

export enum StatusTypeEnum {
  UN_PROCESSED = 'UNPROCESSED',
  INITIALISING = 'INITIALISING',
  STARTED = 'STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
}

interface StatusIndicatorProps {
  readonly status: StatusTypeEnum;
  readonly children?: React.ReactNode;
}

const ICON_SIZE = 22;

const StatusIndicator = ({status, children}: StatusIndicatorProps) => {
  let icon;
  let text;
  let textColor;
  let toolTip;
  switch (status) {
    case StatusTypeEnum.UN_PROCESSED:
      icon = <CircleOff size={ICON_SIZE} />;
      text = 'Unprocessed';
      textColor = 'text-slate-500';
      break;
    case StatusTypeEnum.INITIALISING:
      icon = <CircleDotDashed size={ICON_SIZE} />;
      text = 'Initialising';
      textColor = 'text-slate-500';
      break;
    case StatusTypeEnum.STARTED:
      icon = <CircleEllipsis size={ICON_SIZE} />;
      text = 'Started';
      textColor = 'text-slate-500';
      break;
    case StatusTypeEnum.PENDING:
      icon = <Clock9 size={ICON_SIZE} />;
      text = 'Pending';
      textColor = 'text-slate-500';
      break;
    case StatusTypeEnum.IN_PROGRESS:
      icon = <RefreshCw className={'animate-spin'} size={ICON_SIZE} />;
      text = 'In Progress';
      textColor = 'text-primary';
      break;
    case StatusTypeEnum.COMPLETED:
    case StatusTypeEnum.SUCCESS:
      icon = <CheckCircle2 size={ICON_SIZE} />;
      text = 'Completed';
      textColor = 'text-green-500';
      break;
    case StatusTypeEnum.FAILED:
    case StatusTypeEnum.FAILURE:
      icon = <XCircle size={ICON_SIZE} />;
      text = 'Failed';
      textColor = 'text-red-500';
      break;
    case StatusTypeEnum.PARTIAL_SUCCESS:
      icon = <TriangleAlert size={ICON_SIZE} />;
      text = 'Post Import Processing Failed';
      textColor = 'text-yellow-500';
      toolTip = 'Analytics results may be affected by this error';
      break;
    default:
      icon = <MinusCircle size={ICON_SIZE} />;
      text = 'Unknown';
      textColor = 'text-slate-500';
  }
  const statusElements = (
    <div className={cn('flex flex-row w-fit gap-x-2 items-center', textColor)}>
      {icon}
      <span className={'block whitespace-nowrap text-sm'}>
        {children ?? text}
      </span>
    </div>
  );
  if (toolTip) {
    return (
      <Tooltip title={toolTip} placement="top" arrow>
        {statusElements}
      </Tooltip>
    );
  } else {
    return statusElements;
  }
};

export default StatusIndicator;
