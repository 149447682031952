/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {useState} from 'react';
import {
  AnalyticsGraphCard,
  AnalyticsGraphCardProps,
} from './AnalyticsGraphCard';
import {AnalyticValue} from '../../hooks/analytics/useListAnalytics2AndParse';
import {formatKeyLabel} from '../../lib/formatKeyLabel';
import {HierBar} from '../HierBar/HierBar';
import {LegacyButton} from '../ui/legacy-button';

interface BarGraphProps extends AnalyticsGraphCardProps {
  contactImportId: string;
  values: any[];
}

export const BarGraph = ({
  title,
  noDataFound,
  isLoading,
  values,
  contactImportId,
  isEditMode,
}: BarGraphProps) => {
  const [isExpanded, setExpanded] = useState(false);

  const data = values
    .map((p: any): AnalyticValue => {
      return {
        name: formatKeyLabel(p.name),
        percentage: p.value,
        numcontacts: p.numcontacts,
        isTruncated: false,
      };
    })
    .reduce((acc, item) => {
      // merge duplicates:
      const dupeIndex = acc.findIndex((a) => a.name === item.name);
      if (dupeIndex > -1) {
        // console.log("found dupe", acc.data[dupeIndex], item);
        acc[dupeIndex].numcontacts += item.numcontacts;
        acc[dupeIndex].percentage += item.percentage;
      } else {
        acc.push(item);
      }
      return acc;
    }, [] as AnalyticValue[])
    .sort((a, b) => (a.numcontacts > b.numcontacts ? -1 : 1));

  const MAX_ITEMS = 20;
  const numItems = data.length;

  return (
    <AnalyticsGraphCard
      title={title}
      noDataFound={noDataFound}
      isLoading={isLoading}
      isEditMode={isEditMode}
    >
      <div data-testid={`bar-graph-${title}`} className="w-full text-left">
        <HierBar
          dataId={contactImportId}
          data={isExpanded ? data : data.slice(0, MAX_ITEMS)}
        />
        {numItems > MAX_ITEMS ? (
          <div className="flex flex-row justify-center">
            <LegacyButton
              variant="ghost"
              onClick={() => setExpanded((expanded) => !expanded)}
            >
              {isExpanded
                ? `Only show top ${MAX_ITEMS}`
                : `Show all ${numItems} items`}
            </LegacyButton>
          </div>
        ) : null}
      </div>
    </AnalyticsGraphCard>
  );
};
