/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Card, CardContent} from '@mui/material';
import {XCircle} from 'lucide-react';
import {FC} from 'react';

import {Alert, AlertDescription, AlertTitle} from '../../components/ui/alert';
import {
  ApiError,
  errorTitle,
  isApiError,
} from '../../hooks/useDefaultApiClient';

interface ErrorPageProps {
  bg?: boolean;
  readonly errors: unknown[];
}

export const ErrorPage: FC<ErrorPageProps> = ({bg, errors}) => {
  if (bg)
    return (
      <Card>
        <CardContent>
          <ErrorAlert errors={errors} />
        </CardContent>
      </Card>
    );
  return <ErrorAlert errors={errors} />;
};

const ErrorAlert: FC<ErrorPageProps> = ({errors}) => {
  const apiErrors = errors.filter((error) => isApiError(error)) as ApiError[];

  return (
    <Alert variant="destructive" className={'w-full'}>
      <XCircle />
      <div className={'mx-4'}>
        <AlertTitle>
          {apiErrors.length > 0 ? errorTitle(apiErrors[0].status) : 'Error'}
        </AlertTitle>
        <AlertDescription>
          {apiErrors.length > 0
            ? apiErrors[0].details.message
            : `An unexpected error occurred.`}
        </AlertDescription>
      </div>
    </Alert>
  );
};
