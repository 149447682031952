/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  PromptGroupType,
  PromptQuestion,
  PromptRatingAggregation,
  useGetPromptReports,
} from '@agent-assist/api-typescript-react-query-hooks';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import {Button, Card, CardContent, Stack} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {CornerDownRight} from 'lucide-react';
import {FC, useCallback} from 'react';
import LegacyHeading from '../../../components/Heading';
import {formatPercentage} from '../../../lib/formatNumber';
import {wordToTitleCase} from '../../../lib/formatString';
import {cn} from '../../../lib/utils';
import {
  setPromptReports,
  setShowModalPromptReport,
  usePromptDispatch,
} from '../../../providers/PromptProvider';

interface ViewPromptProps {
  promptGroupName: string;
  questions: PromptQuestion[];
  totalWeight: number;
  promptRevisionId: string;
  promptRating?: PromptRatingAggregation[];
  promptGroupType: PromptGroupType;
}

interface RatingProps {
  promptQuestion: PromptQuestion;
  promptRevisionId: string;
  feedback?: PromptRatingAggregation[];
}

const QuestionHeader: FC<RatingProps> = ({
  feedback,
  promptQuestion,
  promptRevisionId,
}: RatingProps) => {
  const dispatch = usePromptDispatch();
  const getPromptReports = useGetPromptReports();
  const ratingPercentage = feedback?.find(
    (q) => q.questionId === promptQuestion.id,
  );
  const totalRating = feedback?.find((q) => q.questionId === promptQuestion.id);
  let isGreaterThan50 =
    ratingPercentage && ratingPercentage.goodPercentage > 50 ? true : false;
  let percentage = ratingPercentage
    ? Math.round(ratingPercentage.goodPercentage)
    : 0;
  const total = totalRating ? totalRating.total : 0;

  const onClickEvaluationReport = useCallback(async () => {
    setShowModalPromptReport(dispatch, true);
    const result = await getPromptReports.mutateAsync({
      promptRevisionId: promptRevisionId,
      questionKey: promptQuestion.id,
    });
    const promptReports = Object.values(result.reports)[0];
    promptReports === undefined
      ? setPromptReports(dispatch, [])
      : setPromptReports(dispatch, promptReports);
  }, [
    promptQuestion,
    promptRevisionId,
    setShowModalPromptReport,
    getPromptReports,
    dispatch,
  ]);

  const isLoaded = Boolean(feedback);

  return (
    <Stack
      direction={'row'}
      sx={{justifyContent: 'space-between', alignItems: 'center'}}
    >
      <Stack direction={'row'} sx={{alignItems: 'center'}} spacing={0.5}>
        <Button onClick={onClickEvaluationReport}>
          <AssessmentOutlinedIcon />
        </Button>
        <LegacyHeading h3 className={'flex capitalize'}>
          {promptQuestion.properties.isChild && (
            <CornerDownRight
              className={cn('cursor-pointer', 'text-muted-foreground')}
              size={20}
            />
          )}
          {promptQuestion.key}
        </LegacyHeading>
      </Stack>
      {!promptQuestion.properties.isParent && (
        <div className={'flex flex-row col-span-5 items-center gap-x-5'}>
          <span>Total Rated: {total}</span>
          <span>
            Prompt Rating:{' '}
            {isLoaded ? (
              <span
                className={cn(
                  isGreaterThan50 ? 'text-green-500' : 'text-red-500',
                )}
              >
                {formatPercentage(percentage)}
              </span>
            ) : (
              '...'
            )}{' '}
          </span>
        </div>
      )}
    </Stack>
  );
};

export const SectionPromptQuestions: FC<ViewPromptProps> = ({
  questions,
  promptGroupName,
  totalWeight,
  promptRevisionId,
  promptRating,
  promptGroupType,
}) => {
  return (
    <Card>
      <Stack
        direction={'row'}
        sx={{justifyContent: 'space-between', alignItems: 'center'}}
      >
        <CardHeader title={wordToTitleCase(promptGroupName)} />
        {promptGroupType === 'qa' && (
          <CardHeader sx={{mb: 0}} title={`Total Weight: ${totalWeight}`} />
        )}
      </Stack>
      <Divider sx={{mt: 1}} />
      <CardContent>
        <Stack spacing={promptGroupType === 'qa' ? 4 : 2}>
          {questions.map((promptQuestion) => (
            <Stack spacing={2} key={promptQuestion.id}>
              {promptGroupType === 'qa' && (
                <QuestionHeader
                  feedback={promptRating}
                  promptQuestion={promptQuestion}
                  promptRevisionId={promptRevisionId!}
                />
              )}
              <Card
                sx={{backgroundColor: 'var(--mui-palette-background-level1)'}}
              >
                {promptGroupType === 'qa' ? (
                  <>
                    <CardHeader title={promptQuestion.question} />
                    <CardContent>
                      {promptGroupType === 'qa' && (
                        <Stack direction={'row'} spacing={4}>
                          <Stack spacing={0.75}>
                            <LegacyHeading h3>Weight</LegacyHeading>
                            <p className={'text-muted-foreground'}>
                              {promptQuestion.properties.weight}
                            </p>
                          </Stack>
                          {promptQuestion.properties.isChild ? (
                            <Stack spacing={0.75}>
                              <LegacyHeading h3>Sub-question</LegacyHeading>
                              <p className={'text-muted-foreground'}>
                                {promptQuestion.properties.isChild
                                  ? 'Yes'
                                  : 'No'}
                              </p>
                            </Stack>
                          ) : (
                            <Stack spacing={0.75}>
                              <LegacyHeading h3>Conditional QA</LegacyHeading>
                              <p className={'text-muted-foreground'}>
                                {promptQuestion.properties.conditionalQA
                                  ? 'Yes'
                                  : 'No'}
                              </p>
                            </Stack>
                          )}
                          <Stack spacing={0.75}>
                            <LegacyHeading h3>Positive Result</LegacyHeading>
                            <p className={'text-muted-foreground'}>
                              {promptQuestion.properties.positiveResult ||
                              promptQuestion.properties.positiveResult ===
                                undefined
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </Stack>
                          <Stack spacing={0.75}>
                            <LegacyHeading h3>Auto Fail</LegacyHeading>
                            <p className={'text-muted-foreground'}>
                              {promptQuestion.properties.autoFail
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </Stack>
                        </Stack>
                      )}
                    </CardContent>
                  </>
                ) : (
                  <>
                    <CardHeader
                      title={promptQuestion.key}
                      sx={{textTransform: 'capitalize', pb: 0}}
                    />
                    <CardContent>
                      <Typography>{promptQuestion.question}</Typography>
                    </CardContent>
                  </>
                )}
              </Card>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};
