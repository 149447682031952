/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldVariants,
  Typography,
} from '@mui/material';
import {FC} from 'react';

// TODO: extend the textFieldProps
interface CustomTextFieldProps {
  label?: string;
  value?: string;
  variant?: TextFieldVariants;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  multiLine?: boolean;
  rows?: number;
  defaultValue?: string;
}

const TrusstGPTTextField: FC<CustomTextFieldProps> = ({
  defaultValue,
  variant,
  label,
  value,
  onChange,
  multiLine,
  rows,
  placeholder,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%',
        marginBottom: '1rem',
      }}
    >
      <Typography variant="body2">{label}</Typography>
      <TextField
        variant={variant}
        value={value}
        onChange={onChange}
        autoComplete="off"
        InputProps={{
          endAdornment: value ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear input"
                onClick={() =>
                  onChange({
                    target: {value: ''},
                  } as React.ChangeEvent<HTMLInputElement>)
                }
                edge="end"
                sx={{
                  color: 'hsl(var(--muted-foreground))',
                  marginRight: '0.3rem',
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        placeholder={placeholder}
        fullWidth
        multiline={multiLine}
        maxRows={rows}
        defaultValue={defaultValue}
        {...props}
      />
    </Box>
  );
};

export default TrusstGPTTextField;
