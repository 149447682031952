/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {App} from './app/App';
import {ThemeProvider} from './app/theme-provider';
import {TrusstGPTProvider} from './providers/AppProvider';
import AuthProvider from './providers/AuthProvider';
import DefaultApiClientProvider from './providers/DefaultApiClientProvider';
import {LaunchDarklyProvider} from './providers/LaunchDarklyProvider';
import {NotificationContextProvider} from './providers/NotificationContextProvider';
import RuntimeContextProvider from './providers/RuntimeContextProvider';
import './styles/global.css';
import './styles/legacy-global.css';

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider>
      <RuntimeContextProvider>
        <LaunchDarklyProvider>
          <AuthProvider>
            <BrowserRouter>
              <NotificationContextProvider>
                <DefaultApiClientProvider>
                  <TrusstGPTProvider>
                    <App />
                  </TrusstGPTProvider>
                </DefaultApiClientProvider>
              </NotificationContextProvider>
            </BrowserRouter>
          </AuthProvider>
        </LaunchDarklyProvider>
      </RuntimeContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
