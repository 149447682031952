/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ContactImportUpdateNotificationPayload} from '@agent-assist/common';

import {useCallback, useMemo} from 'react';

import {columns} from './columns';
import {
  type ViewBodyProps,
  IMPORTED_CONTACTS_PAGE_SIZE,
} from './ViewContactImport';
import {ViewList} from './ViewList';
import {useNotificationSubscription} from '../../hooks/notifications/subscription';
import {usePaginatedTableData} from '../../hooks/table/table-hooks';
import {getSynopsesKeyResponse} from '../contact/getSynopsesKeys';

export const ViewListIncomplete = (props: ViewBodyProps) => {
  const {contactImport, contactImportId, importResults} = props;

  const {
    items,
    loading: isLoading,
    pagination,
    tableIndex,
    refetch,
  } = usePaginatedTableData(importResults, 'contacts', {
    pageSize: IMPORTED_CONTACTS_PAGE_SIZE,
  });

  const onContactImportStatusUpdate = useCallback(
    (payload: ContactImportUpdateNotificationPayload) => {
      if (payload.status !== contactImport.data?.status) {
        // notification says status has changed, reloading all:
        void contactImport.refetch();
        void importResults.refetch();
      } else if (
        payload.status === 'IN_PROGRESS' &&
        !contactImport.isRefetching
      ) {
        // notification does not have progress data, reload job status to get processed counts:
        void contactImport.refetch();
      }
    },
    [contactImport, importResults, contactImport.data],
  );

  useNotificationSubscription(contactImportId, {onContactImportStatusUpdate});

  // convert dynanodb data format to expected:
  const itemsMapped = useMemo(
    () =>
      items.map((contact) => {
        const category1 = getSynopsesKeyResponse(contact, 'category'),
          category2 = getSynopsesKeyResponse(contact, 'sub-category'),
          category3 = getSynopsesKeyResponse(contact, 'sub-sub-category'),
          intent = getSynopsesKeyResponse(contact, 'intent');

        return {
          ...contact,
          categorypd: category1,
          'sub-Categorypd': category2,
          'sub-Sub-Categorypd': category3,
          intentpd: intent,
        };
      }),
    [items],
  );

  const listProps = {
    columns: columns.filter((c) => !c.isMeta),
    items: itemsMapped,
    pagination,
    tableIndex,
    refetch,
  };

  return <ViewList {...props} {...listProps} isLoading={Boolean(isLoading)} />;
};
