/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import MuiSwitch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const CustomSwitch = styled(MuiSwitch)({
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'hsl(var(--input))',
  },
});

interface SwitchProps {
  ariaLabel: string;
  checked?: boolean;
  defaultChecked?: boolean;
  label?: string;
  labelOff?: string;
  labelOn?: string;
  onCheckedChange: (v: boolean) => void;
}

export function Switch({
  ariaLabel,
  checked,
  defaultChecked,
  label,
  labelOff,
  labelOn,
  onCheckedChange,
}: SwitchProps) {
  return (
    <FormGroup>
      {label && (
        <Typography noWrap sx={{textAlign: 'center'}}>
          {label}
        </Typography>
      )}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '1.5rem',
        }}
      >
        {labelOff && <Typography noWrap>{labelOff}</Typography>}
        <CustomSwitch
          defaultChecked={defaultChecked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onCheckedChange(event.target.checked)
          }
          inputProps={{'aria-label': ariaLabel}}
          checked={checked}
        />
        {labelOn && <Typography noWrap>{labelOn}</Typography>}
      </Stack>
    </FormGroup>
  );
}
