import {format, parse} from 'date-fns';

export const localTimeToDatabaseTime = (input?: string): string | undefined => {
  if (!input) return undefined;
  const localTime = parse(input, 'yyyy-MM-dd HH:mm:ss', new Date());
  const databaseTime = localTime.toISOString(); // ie utc
  return databaseTime;
};

export const databaseTimeToLocalTime = (
  input?: Date | string,
): string | undefined => {
  if (!input) return undefined;
  const databaseTime = typeof input === 'string' ? new Date(input) : input;
  return format(databaseTime, 'yyyy-MM-dd HH:mm:ss');
};
